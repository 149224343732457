import { createRouter, createWebHistory } from 'vue-router'
import MainLayout from '../layouts/MainLayout.vue'
import LoginView from '../views/LoginView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: MainLayout,
    meta: {
      component: 'MainView'
    }
  },
  {
    path: '/login',
    name: 'LoginView',
    component: MainLayout,
    meta: {
      component: 'LoginView'
    }
  },
  {
    path: '/students',
    name: 'StudentsView',
    component: MainLayout,
    meta: {
      component: 'StudentsView'
    }
  },
  { 
    path: '/students/:id', 
    name: 'current-student',
    component: MainLayout,
    meta: { component: 'StudentsView' }
  },
  {
    path: '/groups',
    name: 'GroupsView',
    component: MainLayout,
    meta: {
      component: 'GroupsView'
    }
  },
  {
    path: '/trainings',
    name: 'TrainingsView',
    component: MainLayout,
    meta: {
      component: 'Calendar'
    }
  },
  {
    path: '/finance',
    name: 'FinanceView',
    component: MainLayout,
    meta: {
      component: 'FinanceView'
    }
  },
  {
    path: '/settings',
    name: 'SettingsView',
    component: MainLayout,
    meta: {
      component: 'SettingsView'
    }
  },
  {
    path: '/age-groups',
    name: 'AgeGroups',
    component: MainLayout,
    meta: { 
      component: 'AgeGroups' 
    }
  },
  {
    path: '/training-constructor',
    name: 'TrainingConstructor',
    component: MainLayout,
    meta: { 
      component: 'TrainingConstructor' 
    }
  },
  {
    path: '/training-programs',
    name: 'TrainingPrograms',
    component: MainLayout,
    meta: { 
      component: 'TrainingPrograms' 
    }
  },
  {
    path: '/training-schemes',
    name: 'TrainingSchemes',
    component: MainLayout,
    meta: { 
      component: 'TrainingSchemes' 
    }
  },
  {
    path: '/training-schemes/:element_id',
    name: 'AddingSchemaToElement',
    component: MainLayout,
    meta: { 
      component: 'TrainingSchemes' 
    }
  },
  {
    path: '/training-elements',
    name: 'TrainingElements',
    component: MainLayout,
    meta: { 
      component: 'TrainingElements' 
    }
  },
  {
    path: '/trainings-constructor',
    name: 'TrainingsConstructor',
    component: MainLayout,
    meta: { 
      component: 'Trainings' 
    }
  },
  {
    path: '/training-program',
    name: 'TrainingProgramView',
    component: MainLayout,
    meta: { 
      component: 'TrainingProgramView' 
    }
  },
  {
    path: '/new-training-element',
    name: 'ElementForm',
    component: MainLayout,
    meta: { 
      component: 'ElementForm' 
    }
  },
  {
    path: '/training-creation-form',
    name: 'TrainingCreationForm',
    component: MainLayout,
    meta: { 
      component: 'TrainingCreationForm' 
    }
  },  
  {
    path: '/training-element/:id',
    name: 'ElementUpdateForm',
    component: MainLayout,
    meta: { 
      component: 'ElementUpdateForm' 
    }
  },


  {
    path: '/training-summary/:id',
    name: 'TrainingUpdateForm',
    component: MainLayout,
    meta: { 
      component: 'TrainingUpdateForm' 
    }
  },

  {
    path: '/create-training-program',
    name: 'TrainingProgramCreationForm',
    component: MainLayout,
    meta: { 
      component: 'TrainingProgramCreationForm' 
    }
  },

  {
    path: '/training-program/:id',
    name: 'TrainingProgramUpdateForm',
    component: MainLayout,
    meta: { 
      component: 'TrainingProgramUpdateForm' 
    }
  },
  {
    path: '/training-summary-addons',
    name: 'TrainingSummaryAddons',
    component: MainLayout,
    meta: { 
      component: 'TrainingSummaryAddons' 
    }
  },

  {
    path: '/match-center/matches',
    name: 'Matches',
    component: MainLayout,
    meta: { 
      component: 'Matches' 
    }
  },

  {
    path: '/match-center/report/:id',
    name: 'MatchReportForm',
    component: MainLayout,
    meta: { 
      component: 'MatchReportForm' 
    }
  },

  {
    path: '/match-center/teams',
    name: 'Teams',
    component: MainLayout,
    meta: { 
      component: 'Teams' 
    }
  },  
  {
    path: '/match-player-marks/:match_id',
    name: 'PlayerMatchReportForm',
    component: MainLayout,
    meta: { 
      component: 'PlayerMatchReportForm' 
    }
  },
  {
    path: '/player-mark/:student_id/:match_id',
    name: 'PlayerMatchMarkView',
    component: MainLayout,
    meta: { 
      component: 'PlayerMatchMarkView' 
    }
  },
  {
    path: '/add-gps-report-for-match/:match_id',
    name: 'AddGpsReportForMatch',
    component: MainLayout,
    meta: { 
      component: 'AddGpsReportForMatch' 
    }
  },
  {
    path: '/schemas-table',
    name: 'SchemasTable',
    component: MainLayout,
    meta: { 
      component: 'SchemasTable' 
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
