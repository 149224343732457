<template>
    <div>
        <DeleteSchemaForm v-if="deletionFlag" @acceptDeleteschema="deleteSchema" @close="deletionFlag = false" :schema="schemaForDeletion" />
        <div class="flex justify-between px-[84px]">
            <InputComponent :value="searchQuery" @update:modelValue="e => searchQuery = e" placeholder="Пошук..." class="w-[250px]"/>

            <div class="w-max flex justify-end">
                <div class="ml-[20px] flex items-center w-[110px] justify-between mr-[140px] text-[#FFF]">
                    <img @click="prevPage" src="@/desktopApp/assets/icons/left_arrow.svg" class="cursor-pointer">
                    <div class="flex mx-[20px]">Сторінка: <input v-model="page" type="number" class="bg-transparent mx-2 w-[5ch] outline-none px-1"> / <span class="ml-[10px]">{{ maxPage }}</span></div>
                    <img @click="nextPage" src="@/desktopApp/assets/icons/right_arrow.svg" class="cursor-pointer">
                </div>
                <div class="w-[228px]">
                    <ButtonComponent @click="() => $router.go(-1)">Назад</ButtonComponent>
                </div>
            </div>
        </div>

        <div class=" w-[90%] mx-auto mt-3 mb-5 rounded-[10px] overflow-y-auto">
            <table class="w-full">
                <thead class="text-white select-none">
                    <tr class="border-b-[1px] border-[#FFF] border-opacity-10 text-white">
                        <th class="px-3 py-2 w-[5%] text-center font-bold text-sm"></th>
                        <th class="py-2 w-[20%] text-start font-bold text-sm">Назва схеми</th>

                        <th class="px-3 w-[25%] py-2 text-end font-bold text-sm"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="schema in schemas" class="border-b-[1px] border-[#FFF] border-opacity-10 text-white">
                        <td class="px-3 py-2 text-center font-medium text-sm w-[250px]">
                            <img :src="apiUrl + 'static/' + schema.filename" @error="schema.pictureError = true" class="absolute opacity-0 w-[1px] h-[1px]">
                            <div class="svg-container" :style="[schema.pictureError ? '' : `background-image: url('${apiUrl + 'static/' + schema.filename}');`]"></div>
                        </td>
                        <td class=" py-2 w-[20%] text-start font-medium text-sm">
                            <div>
                                <div class="text-[15px] font-bold">{{ schema.name }}</div>
                                <div class="text-[12px] text-gray-600">{{ schema.description ? schema.description : 'Без опису' }}</div>
                            </div>
                        </td>
                        <td class="px-3 w-[25%] py-2 text-end font-medium text-sm">
                            <button 
                                v-if="elementId"
                                class="py-1 rounded-[10px] text-sm font-medium mr-[20px]"
                                @click="addToElement(schema.id)"
                            >
                                <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="13" cy="13" r="13" fill="#339989"/>
                                    <line x1="13" y1="5" x2="13" y2="21" stroke="white" stroke-width="2"/>
                                    <line x1="5" y1="13" x2="21" y2="13" stroke="white" stroke-width="2"/>
                                </svg>
                            </button>
                            <button 
                                v-if="!elementId"
                                class="py-1 rounded-[10px] text-sm font-medium mr-[20px]"
                                @click="$router.push('/training-schemes?schema_id=' + schema.id)"
                            >
                                <img 
                                    src="@/desktopApp/assets/icons/pen_icon.svg" 
                                    class="w-6 h-6"
                                >
                            </button>
                            <button 
                                v-else
                                class="py-1 rounded-[10px] text-sm font-medium mr-[20px]"
                                @click="$router.push('/training-schemes?schema_id=' + schema.id + '&element_id=' + elementId)"
                            >
                                <img 
                                    src="@/desktopApp/assets/icons/pen_icon.svg" 
                                    class="w-6 h-6"
                                >
                            </button>
                            <button 
                                class="py-1 rounded-[10px] text-sm font-medium text-red-600"
                                @click="() => { schemaForDeletion = schema; deletionFlag = true; }"
                            >
                                <img 
                                    src="@/desktopApp/assets/icons/trash_icon.svg" 
                                    class="w-6 h-6"   
                                >
                            </button>
                        </td>
                    </tr>
                    <!-- <tr v-else>
                        <td class="px-3 py-2 w-[20%] text-center font-bold text-white" colspan="8">Немає елементів</td>
                    </tr> -->
                </tbody>
            </table>
        </div>
    </div>
</template>
<script>
import { useRoute, useRouter } from 'vue-router';
import { ref, onMounted, watch } from 'vue'
import { getAllTrainingSchemasRequest, getAllSchemasCount, deleteSchemaByIdRequest, saveNewSchema } from '@/desktopApp/services/apiRequests'

import DeleteSchemaForm from './DeleteSchemaForm.vue';

export default {
    name: 'SchemasTable',

    setup(props, { emit }) {
        const route = useRoute()
        const router = useRouter()

        const elementId = ref(route.query.element_id)

        const schemas = ref([])
        const page = ref(1)
        const maxPage = ref(1)

        const schemasCount = ref(0)

        const apiUrl = process.env.VUE_APP_API_URL

        const searchQuery = ref('')

        const schemaForDeletion = ref(null)
        const deletionFlag = ref(false)

        const deleteSchema = async () => {
            if (schemaForDeletion.value) {
                await deleteSchemaByIdRequest(schemaForDeletion.value.id)
                deletionFlag.value = false
                schemaForDeletion.value = null
                await updateSchemas()
            }
        }

        const nextPage = () => {
            if (page.value < maxPage.value)
                page.value++
            else
                page.value = 1
        }

        const prevPage = () => {
            if (page.value > 1) {
                page.value--
            } else {
                page.value = maxPage.value
            }
        }

        watch(page, async () => {
            schemas.value = await getAllTrainingSchemasRequest(20, (page.value - 1) * 20, searchQuery.value)
        })

        watch(searchQuery, async () => {
            schemas.value = await getAllTrainingSchemasRequest(20, (page.value - 1) * 20, searchQuery.value)
        })

        onMounted(async () => {
            schemas.value = await getAllTrainingSchemasRequest(20, (page.value - 1) * 20, searchQuery.value)
            console.log('schemas.value --------- ', schemas.value)

            schemasCount.value = await getAllSchemasCount()
            maxPage.value = Math.ceil(schemasCount.value / 20)
        })

        const updateSchemas = async () => {
            schemas.value = await getAllTrainingSchemasRequest(20, (page.value - 1) * 20, searchQuery.value)
            schemasCount.value = await getAllSchemasCount()
            maxPage.value = Math.ceil(schemasCount.value / 20)
        }

        const addToElement = async (schema_id) => {
            const schemaId = schema_id
            const elementId = route.query.element_id
            router.push({ path: '/training-element/' + elementId, query: { schema_id: schemaId } })
        }

        return {
            schemas,
            page,
            nextPage,
            prevPage,
            searchQuery,
            apiUrl,
            maxPage,
            schemasCount,
            schemaForDeletion,
            deletionFlag,
            deleteSchema,
            elementId,
            addToElement
        }
    },

    components: {
        DeleteSchemaForm
    }
}

</script>
<style lang="scss">
    .svg-container {
        width: 220px;
        height: 160px;
        background-size: cover;
        background-image: url('@/desktopApp/assets/no-photo.svg');
    }
</style>