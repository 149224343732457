<template>
    <TrainingsFilter v-if="filterFlag" :filter="filters" @dropFilters="dropFilters" @setFilters="e => setFilters(e)" @close="filterFlag = false" />
    <div class="overflow-y-auto mt-[10px]">
        <DeleteTrainingForm :element="elementForDelete" @close="showDeletePopup = false" @acceptDeleteElement="deleteElement(elementForDelete)" v-if="showDeletePopup" />

        <div class="flex justify-between mb-[15px] px-[84px]">
            <!-- <div class="search-bar flex items-center border-[#FFF] border-[1px] bg-[#FFF] bg-opacity-30 px-[10px] rounded-[6px]">
                <img src="@/desktopApp/assets/icons/magnify_icon.svg" class="w-3 h-3">
                <input v-model="searchQuery" class="text-[#FFF] text-[14px] bg-transparent rounded-md px-2 py-[3.5px] placeholder:text-[#FFF] outline-none w-[250px] " placeholder="Пошук..." type="text">
            </div> -->
            <InputComponent :value="searchQuery" @update:modelValue="e => searchQuery = e" placeholder="Пошук..." class="w-[250px]"/>
            <div class="w-full ml-[30px] flex items-center ">
                <div class="text-[#8C8C8C] w-[250px] text-start font-medium relative text-[14px]">
                    Відображення елементів
                    <span v-if="elementsViewTable" class="text-[#8FC408]">таблиця</span>
                    <span v-else class="text-[#FA1367]">групи</span>
                </div>
                <div class="ml-[20px] relative bottom-[4px]">
                    <IphoneSwitch :isOnValue="elementsViewTable" @change="elementsViewTable = !elementsViewTable"/>
                </div>
            </div>
            <div class="w-max flex justify-end">
                <div class="flex items-center cursor-pointer" @click="filterFlag = true">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M8.85746 12.5061C6.36901 10.6456 4.59564 8.59915 3.62734 7.44867C3.3276 7.09253 3.22938 6.8319 3.17033 6.3728C2.96811 4.8008 2.86701 4.0148 3.32795 3.5074C3.7889 3 4.60404 3 6.23433 3H17.7657C19.396 3 20.2111 3 20.672 3.5074C21.133 4.0148 21.0319 4.8008 20.8297 6.37281C20.7706 6.83191 20.6724 7.09254 20.3726 7.44867C19.403 8.60062 17.6261 10.6507 15.1326 12.5135C14.907 12.6821 14.7583 12.9567 14.7307 13.2614C14.4837 15.992 14.2559 17.4876 14.1141 18.2442C13.8853 19.4657 12.1532 20.2006 11.226 20.8563C10.6741 21.2466 10.0043 20.782 9.93278 20.1778C9.79643 19.0261 9.53961 16.6864 9.25927 13.2614C9.23409 12.9539 9.08486 12.6761 8.85746 12.5061Z" stroke="#FFF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </div>
                <div class="ml-[20px] flex items-center w-[110px] justify-between mr-[140px] text-[#FFF]">
                    <img @click="prevPage" src="@/desktopApp/assets/icons/left_arrow.svg" class="cursor-pointer">
                    <div class="flex mx-[20px]">Сторінка: <input v-model="page" type="number" class="bg-transparent mx-2 w-[5ch] outline-none px-1"> / <span class="ml-[10px]">{{ maxPage }}</span></div>
                    <img @click="nextPage" src="@/desktopApp/assets/icons/right_arrow.svg" class="cursor-pointer">
                </div>
                <!-- <div @click="$router.push('/training-creation-form')" class="bg-[#BD9135] flex justify-center items-center w-[228px] h-[38px] cursor-pointer text-white font-semibold py-[5px] rounded-lg">
                    <span>Додати тренування</span>
                </div> -->
                <div class="w-[228px]">
                    <ButtonComponent @click="$router.push('/training-creation-form')">Додати тренування</ButtonComponent>
                </div>
            </div>
        </div>

        <div class="border-b-[1px] border-white w-[90%] mx-auto flex justify-end mt-[16px] mb-[16px]">
            <div class="text-white ml-[20px]">
                <span class="text-[28px] font-semibold">{{ trainingsSummaryCount }}</span> тренування
            </div>
        </div>

        <keep-alive>
        <div v-if="elementsViewTable" class="w-[90%] mx-auto mt-3 mb-5 rounded-[10px] overflow-y-auto">
            <table class="w-full">
                <thead class="text-white select-none">
                    <tr class="border-b-[1px] border-[#FFF] border-opacity-10 text-white">
                        <th class="px-3 py-2 w-[5%] text-center font-bold text-sm"></th>
                        <th class="px-3 py-2 w-[20%] text-start font-bold text-sm">Назва тренування</th>
                        <th class="px-3 py-2 w-[20%] text-center font-bold text-sm">Програма</th>
                        <th class="px-3 py-2 w-[20%] text-center font-bold text-sm">Вікова категорія</th>
                        <th class="px-3 py-2 w-[10%] text-end font-bold text-sm">Час</th>
                        <th class="px-3 w-[35%] py-2 text-end font-bold text-sm"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="summary in summaries" v-if="summaries.length > 0" class="cursor-pointer border-b-[1px] border-[#FFF] border-opacity-10 text-white">
                        <td @click="$router.push(`/training-summary/${summary.summary_id}`)" class="px-3 py-2 w-[5%] text-center font-medium text-sm">
                            <div class="rounded-full border-[1px] bg-[#BD9135] border-white w-[50px] h-[50px] flex justify-center items-center text-center align-middle">
                                <span class="text-[18px]">{{ summary.name[0] ? summary.name[0].toUpperCase() : 'N' }}</span>
                            </div>
                        </td>
                        <td @click="$router.push(`/training-summary/${summary.summary_id}`)" class="px-3 py-2 w-[20%] text-start font-bold text-sm">{{ summary.name }}</td>
                        <td class="px-3 py-2 w-[20%] text-center font-bold text-sm">
                            {{ !summary.program_name ? '--' : summary.program_name}}
                        </td>
                        <td class="px-3 py-2 w-[20%] text-center font-bold text-sm">
                            {{ parseAgeGroup(summary.age_group) }}
                        </td>
                        <td class="px-3 py-2 w-[10%] text-end font-bold text-sm">
                            {{ parseTime(summary.time) }}
                        </td>
                        <td class="px-3 w-[35%] py-2 text-end font-medium text-sm">
                            <button 
                                class="py-1 rounded-[10px] text-sm font-medium mr-[20px]"
                                @click="$router.push(`/training-summary/${summary.summary_id}`)"
                            >
                                <img 
                                    src="@/desktopApp/assets/icons/pen_icon.svg" 
                                    class="w-6 h-6"
                                >
                            </button>
                            <button 
                                class="py-1 rounded-[10px] text-sm font-medium text-red-600"
                                @click="elementForDelete = summary; showDeletePopup = true"
                            >
                                <img 
                                    src="@/desktopApp/assets/icons/trash_icon.svg" 
                                    class="w-6 h-6"   
                                >
                            </button>
                        </td>
                    </tr>
                    <tr v-else>

                        <td class="px-3 py-2 w-[20%] text-center font-bold text-white" colspan="6">Немає тренувань</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div v-else class="my-[20px]">
            <div v-if="!selectedGroupAgeGroup">
                <div v-for="el in 18" @click="selectedGroupAgeGroup = `${el + 5}_years`" class="bg-[#BD9135] flex justify-center items-center w-[60%] mt-[20px] mx-auto h-[38px] cursor-pointer text-white font-semibold py-[5px] rounded-lg">
                    <span>Вікова категорія: <span class="text-black">{{el + 5}} років</span></span>
                </div>
                <div @click="selectedGroupAgeGroup = 'main_team'" class="bg-[#BD9135] flex justify-center items-center w-[60%] mt-[20px] mx-auto h-[38px] cursor-pointer text-white font-semibold py-[5px] rounded-lg">
                    <span><span class="text-black">Основна команда</span></span>
                </div>
            </div>
            <div v-else-if="!selectedElementDirection" class=" w-[90%] mx-auto mt-3 mb-5 rounded-[10px] overflow-y-auto">
                <div @click="() => {selectedGroupElementType = null; selectedGroupAgeGroup = null}" class="bg-[#BD9135] flex justify-center mb-[20px] items-center w-[228px] h-[38px] cursor-pointer text-white font-semibold py-[5px] rounded-lg">
                    <span>Назад</span>
                </div>

                <div @click="selectedElementDirection = 'physical'" class="bg-[#BD9135] flex justify-center items-center w-[60%] mt-[20px] mx-auto h-[38px] cursor-pointer text-white font-semibold py-[5px] rounded-lg">
                    <span><span class="text-black">Фізичне</span></span>
                </div>
                <div @click="selectedElementDirection = 'tactical'" class="bg-[#BD9135] flex justify-center items-center w-[60%] mt-[20px] mx-auto h-[38px] cursor-pointer text-white font-semibold py-[5px] rounded-lg">
                    <span><span class="text-black">Тактичне</span></span>
                </div>
                <div @click="selectedElementDirection = 'technical'" class="bg-[#BD9135] flex justify-center items-center w-[60%] mt-[20px] mx-auto h-[38px] cursor-pointer text-white font-semibold py-[5px] rounded-lg">
                    <span><span class="text-black">Технічне</span></span>
                </div>
                <div @click="selectedElementDirection = 'tech_tactical'" class="bg-[#BD9135] flex justify-center items-center w-[60%] mt-[20px] mx-auto h-[38px] cursor-pointer text-white font-semibold py-[5px] rounded-lg">
                    <span><span class="text-black">Техніко/тактичне</span></span>
                </div>
                <div @click="selectedElementDirection = 'all'" class="bg-[#BD9135] flex justify-center items-center w-[60%] mt-[20px] mx-auto h-[38px] cursor-pointer text-white font-semibold py-[5px] rounded-lg">
                    <span><span class="text-black">Всі</span></span>
                </div>
            </div>
            <div v-else class=" w-[90%] mx-auto mt-3 mb-5 rounded-[10px] overflow-y-auto">
                <div @click="() => {selectedGroupElementType = null; selectedElementDirection = null}" class="bg-[#BD9135] flex justify-center mb-[20px] items-center w-[228px] h-[38px] cursor-pointer text-white font-semibold py-[5px] rounded-lg">
                    <span>Назад</span>
                </div>
                <table class="w-full">
                    <thead class="text-white select-none">
                        <tr class="border-b-[1px] border-[#FFF] border-opacity-10 text-white">
                            <th class="px-3 py-2 w-[5%] text-center font-bold text-sm"></th>
                            <th class="px-3 py-2 w-[20%] text-start font-bold text-sm">Назва тренування</th>
                            <th class="px-3 py-2 w-[20%] text-center font-bold text-sm">Програма</th>
                            <th class="px-3 py-2 w-[20%] text-center font-bold text-sm">Вікова категорія</th>
                            <th class="px-3 py-2 w-[10%] text-end font-bold text-sm">Час</th>
                            <th class="px-3 w-[35%] py-2 text-end font-bold text-sm"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="summary in summaries" v-if="summaries.length > 0" class="cursor-pointer border-b-[1px] border-[#FFF] border-opacity-10 text-white">
                            <td @click="$router.push(`/training-summary/${summary.summary_id}`)" class="px-3 py-2 w-[5%] text-center font-medium text-sm">
                                <div class="rounded-full border-[1px] bg-[#BD9135] border-white w-[50px] h-[50px] flex justify-center items-center text-center align-middle">
                                    <span class="text-[18px]">{{ summary.name[0] ? summary.name[0].toUpperCase() : 'N' }}</span>
                                </div>
                            </td>
                            <td @click="$router.push(`/training-summary/${summary.summary_id}`)" class="px-3 py-2 w-[20%] text-start font-bold text-sm">{{ summary.name }}</td>
                            <td class="px-3 py-2 w-[20%] text-center font-bold text-sm">
                                {{ !summary.program_name ? '--' : summary.program_name}}
                            </td>
                            <td class="px-3 py-2 w-[20%] text-center font-bold text-sm">
                                {{ parseAgeGroup(summary.age_group) }}
                            </td>
                            <td class="px-3 py-2 w-[10%] text-end font-bold text-sm">
                                {{ parseTime(summary.time) }}
                            </td>
                            <td class="px-3 w-[35%] py-2 text-end font-medium text-sm">
                                <button 
                                    class="py-1 rounded-[10px] text-sm font-medium mr-[20px]"
                                    @click="$router.push(`/training-summary/${summary.summary_id}`)"
                                >
                                    <img 
                                        src="@/desktopApp/assets/icons/pen_icon.svg" 
                                        class="w-6 h-6"
                                    >
                                </button>
                                <button 
                                    class="py-1 rounded-[10px] text-sm font-medium text-red-600"
                                    @click="elementForDelete = summary; showDeletePopup = true"
                                >
                                    <img 
                                        src="@/desktopApp/assets/icons/trash_icon.svg" 
                                        class="w-6 h-6"   
                                    >
                                </button>
                            </td>
                        </tr>
                        <tr v-else>

                            <td class="px-3 py-2 w-[20%] text-center font-bold text-white" colspan="6">Немає тренувань</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        </keep-alive>
    </div>
</template>

<script>
    import { ref, onMounted, watch } from 'vue';
    import { getAllSummaries, deleteSummaryById, getTrainingsSummaryCount } from '@/desktopApp/services/apiRequests';
    import DeleteTrainingForm from './components/DeleteTrainingForm.vue';
    import TrainingsFilter from './components/TrainingsFilter.vue';
    import IphoneSwitch from './components/IphoneSwitch.vue';
import ButtonComponent from '@/desktopApp/components/uikit/Button/ButtonComponent.vue';

    export default {
        name: 'Trainings',

        setup() {
            const summaries = ref([]);
            const showDeletePopup = ref(false);
            const elementForDelete = ref({});

            const trainingsSummaryCount = ref(0);

            const page = ref(1);
            const maxPage = ref(1);

            const searchQuery = ref('');

            const filterFlag = ref(false);

            const elementsViewTable = ref(true);

            const selectedGroupAgeGroup = ref(null);
            const selectedElementDirection = ref(null);

            const filters = ref({
                ageCategory: 'all',
                direction: 'all',
                timeSort: 'none',
            });

            watch(selectedElementDirection, async () => {
                if (selectedElementDirection.value) {
                    filters.value.direction = selectedElementDirection.value ?? 'all';
                    filters.value.ageCategory = selectedGroupAgeGroup.value ?? 'all';
                    await updateSummaries();
                }
            });

            watch(elementsViewTable, async () => {
                if (elementsViewTable.value) {
                    filters.value = {
                        ageCategory: 'all',
                        direction: 'all',
                        timeSort: 'none',
                    }
                    await updateSummaries();
                }
            });

            const parseTime = (time) => {
                const hours = Math.floor(time / 60);
                const minutes = time % 60;
                if (hours)
                    return `${hours} год. ${minutes} хв.`;
                return `${minutes} хв.`;
            }

            const nextPage = () => {
                if (page.value < maxPage.value) {
                    page.value++;
                }
            }

            const prevPage = () => {
                if (page.value > 1) {
                    page.value--;
                }
            }

            watch(page, async () => {
                await updateSummaries();
            });

            watch(searchQuery, async () => {
                await updateSummaries();
            });

            const setFilters = async (newFilters) => {
                filters.value = newFilters
                filterFlag.value = false

                console.log(filters.value)

                await updateSummaries()
            }

            const dropFilters = async () => {
                filters.value = {
                    ageCategory: 'all',
                    direction: 'all',
                    timeSort: 'none',
                }
                filterFlag.value = false

                await updateElements()
            }

            const updateSummaries = async () => {
                summaries.value = await getAllSummaries(20 * page.value, 20 * (page.value - 1), filters.value, searchQuery.value);
                summaries.value = summaries.value.map(summary => {
                    const summaryTime = summary.elements.reduce((acc, element) => {
                        return acc + +element.time;
                    }, 0);
                    summary.time = summaryTime;
                    return summary;
                })
            }

            const deleteElement = async (element) => {
                await deleteSummaryById(element.summary_id);
                await updateSummaries();
                showDeletePopup.value = false;
            }

            onMounted(async () => {
                await updateSummaries();
                trainingsSummaryCount.value = await getTrainingsSummaryCount();

                maxPage.value = Math.ceil(trainingsSummaryCount.value / 20);
            });

            const parseAgeGroup = (ageGroup) => {
                switch (ageGroup) {
                    case '6_years':
                        return '6 років'
                    case '7_years':
                        return '7 років'
                    case '8_years':
                        return '8 років'
                    case '9_years':
                        return '9 років'
                    case '10_years':
                        return '10 років'
                    case '11_years':
                        return '11 років'
                    case '12_years':
                        return '12 років'
                    case '13_years':
                        return '13 років'
                    case '14_years':
                        return '14 років'
                    case '15_years':
                        return '15 років'
                    case '16_years':
                        return '16 років'
                    case '17_years':
                        return '17 років'
                    case '18_years':
                        return '18 років'
                    case '19_years':
                        return '19 років'
                    case '20_years':
                        return '20 років'
                    case '21_years':
                        return '21 рік'
                    case '22_years':
                        return '22 роки'
                    case '23_years':
                        return '23 роки'
                    case 'main_team':
                        return 'Основний склад'
                    default:
                        return 'Невідома вікова категорія'
                }
            }

            return {
                summaries,
                parseAgeGroup,
                showDeletePopup,
                elementForDelete,
                deleteElement,
                trainingsSummaryCount,
                page,
                maxPage,
                nextPage,
                prevPage,
                searchQuery,
                filterFlag,
                parseTime,
                filters,
                setFilters,
                dropFilters,
                elementsViewTable,
                selectedGroupAgeGroup,
                selectedElementDirection
            }
        },

        components: {
            DeleteTrainingForm,
            TrainingsFilter,
            IphoneSwitch
        }
    }
</script>

<style lang="scss" scoped>

</style>