<template>
    <div>
      <div v-if="updateSchemeFlag" class="absolute flex z-[9999] flex-col left-[50%] translate-x-[-100%] px-[20px] justify-evenly top-[50%] translate-y-[-50%] ml-[150px] rounded-[10px] w-[300px] h-[200px] bg-[#252526]">
        <div class="flex justify-center items-center">
          <div class="text-white text-[21px] font-semibold">
            Схема оновлена
          </div>
        </div>
        <div @click="updateSchemeFlag = false" class="bg-[#BD9135] text-center cursor-pointer text-white font-semibold py-[5px] rounded-lg mt-[15px]">
          OK
        </div>
      </div>
      <div v-if="saveSchemeFlag" class="absolute flex z-[9999] flex-col left-[50%] translate-x-[-50%] px-[20px] justify-evenly top-[50%] translate-y-[-50%] rounded-[10px] w-[300px] h-[200px] bg-[#252526]">
        <div class="flex justify-center items-center">
          <div class="text-white text-[21px] font-semibold">
            Схема збережена
          </div>
        </div>
        <div @click="saveSchemeFlag = false" class="bg-[#BD9135] text-center cursor-pointer text-white font-semibold py-[5px] rounded-lg mt-[15px]">
          OK
        </div>
      </div>
      <div v-if="nameErrorFlag" class="absolute flex z-[9999] flex-col left-[50%] translate-x-[-100%] px-[20px] justify-evenly top-[50%] translate-y-[-50%] ml-[150px] rounded-[10px] w-[300px] h-[200px] bg-[#252526]">
        <div class="flex justify-center items-center">
          <div class="text-white text-[21px] text-center font-semibold">
            Схема з такою назвою вже існує
          </div>
        </div>
        <div @click="nameErrorFlag = false" class="bg-[#BD9135] text-center cursor-pointer text-white font-semibold py-[5px] rounded-lg mt-[15px]">
          OK
        </div>
      </div>
      <div class="flex justify-center mb-[10px]">
        <input placeholder="Введіть назву нової схеми" v-model="name" class="text-center text-white bg-transparent outline-none font-semibold text-[21px]"/>
      </div>
      <div class="flex flex-col justify-center select-none mb-[20px]" :class="{'flex-col-reverse items-center' : screenWidth <= 880}">
        <div id="schema-container" class="relative flex justify-center border-[1px] w-max mx-auto">
          <div v-if="startInputTextFieldFlag" class="absolute" :style="{'left' : newTextFieldCoords.x + 'px', 'top' : newTextFieldCoords.y + 'px'}">
            <div class="flex justify-center items-center">
              <input v-model="newTextFieldValue" class="w-[200px] bg-white text-black outline-none z-[9999]"/>
              <div @click="addTextFieldToCanvas" class="bg-[#BD9135] text-center cursor-pointer text-white font-semibold w-[65px]">
                OK
              </div>
            </div>
          </div>
          <Field v-if="fieldMode == 'full'" :screenWidth="screenWidth" />
          <CleanField v-if="fieldMode == 'clean'" :screenWidth="screenWidth" />
          <HalfField v-if="fieldMode == 'half'" :screenWidth="screenWidth" />
          <FullLinesField v-if="fieldMode == 'full-lines'" :screenWidth="screenWidth" />
          <FullLinesField2 v-if="fieldMode == 'full-lines2'" :screenWidth="screenWidth" />
          <FullLinesField3 v-if="fieldMode == 'full-lines3'" :screenWidth="screenWidth" />
          <FullLinesField4 v-if="fieldMode == 'full-lines4'" :screenWidth="screenWidth" />
          <FullLinesField5 v-if="fieldMode == 'full-lines5'" :screenWidth="screenWidth" />
          <FullLinesField6 v-if="fieldMode == 'full-lines6'" :screenWidth="screenWidth" />
          <FullLinesField7 v-if="fieldMode == 'full-lines7'" :screenWidth="screenWidth" />
          <AnotherHalfField v-if="fieldMode == 'another-half'" :screenWidth="screenWidth" />
          <PenaltyZone v-if="fieldMode == 'penalty-zone'" :screenWidth="screenWidth" />
          <PenaltyZoneLines v-if="fieldMode == 'penalty-zone-lines'" :screenWidth="screenWidth" />
          <Court v-if="fieldMode == 'court'" :screenWidth="screenWidth" />
          <div class="scheme-placeholder"></div>
        </div>
        <!-- :class="{'mt-[20px] w-[80%]' : screenWidth <= 880, 'mr-[20px]' : screenWidth > 880}" -->
        <div class="flex flex-col justify-center select-none w-[1105px] mx-auto">
          <div v-if="lineSelectedFlag" class="bg-black rounded-md w-[100%] mx-auto py-[10px] mt-[15px]">
            <div class="w-full text-white text-center py-[10px]">Редагування прямої</div>
  
            <div class="px-[10px] flex">
  
              <div class="w-[20%]">
                <div @click="changeLineStrokeStyle('dotted')" :class="{'bg-[#BD9135]' : currentLineSettings.strokeStyle == 'dotted'}" class="p-[10px] w-max rounded-md cursor-pointer">
                  <svg width="123" height="4" viewBox="0 0 123 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 2H193" stroke="white" stroke-width="3" stroke-linejoin="round" stroke-dasharray="6 6"/>
                  </svg>
                </div>
                <div @click="changeLineStrokeStyle('solid')" :class="{'bg-[#BD9135]' : currentLineSettings.strokeStyle == 'solid'}" class="p-[10px] w-max rounded-md cursor-pointer">
                  <svg width="123" height="4" viewBox="0 0 123 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 2H193" stroke="white" stroke-width="3" stroke-linejoin="round"/>
                  </svg>
                </div>
              </div>
  
              <div class="w-[15%]">
  
                <div class="flex">
                  <div @click="changeLineStartStyle('arrow')" class="p-[5px] h-[20px] flex items-center justify-center mr-[10px] w-max rounded-md cursor-pointer" :class="{'bg-[#BD9135]' : currentLineSettings.startStyle == 'arrow'}">
                    <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7.5 0.5L1 6L7.5 11.5" stroke="white"/>
                    </svg>
                  </div>
                  <div @click="changeLineEndStyle('arrow')" class="p-[5px] h-[20px] flex items-center justify-center w-max rounded-md cursor-pointer" :class="{'bg-[#BD9135]' : currentLineSettings.endStyle == 'arrow'}">
                    <svg width="9" height="13" viewBox="0 0 9 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1 12L7.5 6.5L1 1" stroke="white"/>
                    </svg>
                  </div>
                </div>
              
                <div class="flex">
                  <div @click="changeLineStartStyle('none')" class="p-[5px] h-[20px] flex items-center justify-center mr-[10px] w-max rounded-md cursor-pointer" :class="{'bg-[#BD9135]' : currentLineSettings.startStyle == 'none'}">
                    <svg width="11" height="2" viewBox="0 0 11 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M1 1H10" stroke="white" stroke-width="2" stroke-linecap="round"/>
                    </svg>
  
                  </div>
                  <div @click="changeLineEndStyle('none')" class="p-[5px] h-[20px] flex items-center justify-center w-max rounded-md cursor-pointer" :class="{'bg-[#BD9135]' : currentLineSettings.endStyle == 'none'}">
                    <svg width="11" height="2" viewBox="0 0 11 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M1 1H10" stroke="white" stroke-width="2" stroke-linecap="round"/>
                    </svg>
                  </div>
                </div>
              
              </div>
  
              <div class="w-[35%]">
                <div class="text-white">
                  Колір
                </div>
                <div class="flex">
                  <div @click="changeLineColor('#474849')" class="bg-[#474849] border-[1px] h-[30px] w-[30px] rounded-md cursor-pointer" :class="{'border-2 border-[#BD9135]' : selectedComponentSettings.color == 'black'}"></div>
                  <div @click="changeLineColor('white')" class="bg-white h-[30px] w-[30px] rounded-md cursor-pointer" :class="{'border-2 border-[#BD9135]' : selectedComponentSettings.color == 'white'}"></div>
                  <div @click="changeLineColor('#C59014')" class="bg-[#C59014] h-[30px] w-[30px] rounded-md cursor-pointer" :class="{'border-2 border-[#BD9135]' : selectedComponentSettings.color == 'red'}"></div>
                  <div @click="changeLineColor('#8FC408')" class="bg-[#8FC408] h-[30px] w-[30px] rounded-md cursor-pointer" :class="{'border-2 border-[#BD9135]' : selectedComponentSettings.color == 'green'}"></div>
                  <div @click="changeLineColor('#0ABFC2')" class="bg-[#0ABFC2] h-[30px] w-[30px] rounded-md cursor-pointer" :class="{'border-2 border-[#BD9135]' : selectedComponentSettings.color == 'blue'}"></div>
                  <div @click="changeLineColor('#DA4167')" class="bg-[#DA4167] h-[30px] w-[30px] rounded-md cursor-pointer" :class="{'border-2 border-[#BD9135]' : selectedComponentSettings.color == 'blue'}"></div>
                  <div @click="changeLineColor('#1B4079')" class="bg-[#1B4079] h-[30px] w-[30px] rounded-md cursor-pointer" :class="{'border-2 border-[#BD9135]' : selectedComponentSettings.color == 'yellow'}"></div>
                </div>
              </div>

              <div class="w-[15%]">
                <div class="text-white">
                  Поворот
                </div>
                <div class="flex items-center">
                  <div class="cursor-pointer" @click="changeLineRotate(+selectedComponentSettings.rotate - 5)">
                    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M1.63523 10.117C0.937534 9.3773 0.437455 8.47039 0.185387 7.48018C-0.104303 6.34217 -0.0536836 5.14417 0.330971 4.03465C0.715626 2.92514 1.41741 1.95289 2.34933 1.2384C3.28126 0.523908 4.40237 0.0985928 5.5737 0.0151644C6.74504 -0.0682639 7.9151 0.193862 8.93889 0.769056C9.96267 1.34425 10.7952 2.20722 11.3332 3.25102C11.8712 4.29482 12.0911 5.47355 11.9656 6.64113C11.8402 7.80871 11.3748 8.9138 10.6273 9.81945L8.48791 8.05358C8.88983 7.56665 9.14002 6.97248 9.20748 6.34472C9.27495 5.71696 9.15671 5.0832 8.86744 4.52199C8.57816 3.96078 8.13056 3.4968 7.58011 3.18754C7.02966 2.87828 6.40056 2.73734 5.77078 2.7822C5.141 2.82706 4.53823 3.05573 4.03717 3.43988C3.53611 3.82404 3.15879 4.34678 2.95197 4.94332C2.74516 5.53986 2.71794 6.18398 2.8737 6.79585C2.99267 7.2632 3.21439 7.69604 3.52054 8.06385L4.4974 7.00003L4.67134 11.0822L0.589186 11.2561L1.63523 10.117Z" fill="white"/>
                    </svg>
                  </div>
                  <div class="text-white text-center font-bold mx-[10px] w-[50px]">{{+selectedComponentSettings.rotate}}°</div>
                  <div class="cursor-pointer" @click="changeLineRotate(+selectedComponentSettings.rotate + 5)">
                    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M10.3644 10.4967C11.062 9.75706 11.5621 8.85017 11.8141 7.85999C12.1038 6.72198 12.0532 5.52398 11.6685 4.41447C11.2839 3.30495 10.5821 2.3327 9.65018 1.61821C8.71825 0.903722 7.59714 0.478407 6.42581 0.394979C5.25448 0.31155 4.08441 0.573676 3.06062 1.14887C2.03684 1.72406 1.20434 2.58704 0.666318 3.63083C0.128293 4.67463 -0.0916091 5.85336 0.0338652 7.02094C0.159339 8.18852 0.624674 9.29362 1.3722 10.1993L3.5116 8.43339C3.10968 7.94646 2.85949 7.3523 2.79203 6.72454C2.72457 6.09677 2.8428 5.46302 3.13207 4.90181C3.42135 4.3406 3.86895 3.87661 4.4194 3.56735C4.96985 3.25809 5.59895 3.11716 6.22873 3.16201C6.85851 3.20687 7.46128 3.43555 7.96234 3.8197C8.4634 4.20385 8.84073 4.72659 9.04754 5.32314C9.25435 5.91968 9.28157 6.5638 9.12581 7.17566C9.00685 7.64298 8.78515 8.0758 8.47904 8.44358L7.50225 7.37984L7.3283 11.462L11.4105 11.6359L10.3644 10.4967Z" fill="white"/>
                    </svg>
                  </div>
                </div>
              </div>

              <div class="w-[15%]">
                <div class="text-white">
                  Ширина лінії
                </div>
                <div class="flex items-center">
                  <div class="cursor-pointer" @click="changeLineStrokeWeight(+currentLineSettings.strokeWeight + 1)">
                    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <circle cx="6" cy="6" r="6" fill="white"/>
                      <path d="M6 3V6M6 9V6M6 6H3H9" stroke="black"/>
                    </svg>
                  </div>
                  <div class="text-white text-center font-bold mx-[10px]">{{currentLineSettings.strokeWeight}}</div>
                  <div class="cursor-pointer" @click="changeLineStrokeWeight(+currentLineSettings.strokeWeight - 1)">
                    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="6" cy="6" r="6" fill="white"/>
                    <path d="M6 6H3H9" stroke="black"/>
                    </svg>
                  </div>
                </div>
              </div>
  
              <div class="w-[20%] flex justify-end">
                <div @click="deleteLine" class="bg-red-600 w-full text-center cursor-pointer text-white font-semibold py-[5px] rounded-lg mt-[15px]">
                  Видалити
                </div>
              </div>
  
            </div>
          </div>
          <div v-if="curveLineSelectedFlag" class="bg-black rounded-md w-[100%] h-[117px] mx-auto py-[10px] mt-[40px]">
            <div class="w-full text-white text-center py-[10px]">Редагування кривої</div>
  
            <div class="px-[10px] flex">
  
              <div class="w-[20%]">
                <div @click="changeCurveLineStrokeStyle('dotted')" :class="{'bg-[#BD9135]' : currentCurveLineSettings.strokeStyle == 'dotted'}" class="p-[10px] w-max rounded-md cursor-pointer">
                  <svg width="123" height="4" viewBox="0 0 123 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 2H193" stroke="white" stroke-width="3" stroke-linejoin="round" stroke-dasharray="6 6"/>
                  </svg>
                </div>
                <div @click="changeCurveLineStrokeStyle('solid')" :class="{'bg-[#BD9135]' : currentCurveLineSettings.strokeStyle == 'solid'}" class="p-[10px] w-max rounded-md cursor-pointer">
                  <svg width="123" height="4" viewBox="0 0 123 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 2H193" stroke="white" stroke-width="3" stroke-linejoin="round"/>
                  </svg>
                </div>
              </div>
              
  
              <div class="w-[15%]">
  
                <div class="flex">
                  <div @click="changeCurveLineStartStyle('arrow')" class="p-[5px] h-[20px] flex items-center justify-center mr-[10px] w-max rounded-md cursor-pointer" :class="{'bg-[#BD9135]' : currentCurveLineSettings.startStyle == 'arrow'}">
                    <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7.5 0.5L1 6L7.5 11.5" stroke="white"/>
                    </svg>
                  </div>
                  <div @click="changeCurveLineEndStyle('arrow')" class="p-[5px] h-[20px] flex items-center justify-center w-max rounded-md cursor-pointer" :class="{'bg-[#BD9135]' : currentCurveLineSettings.endStyle == 'arrow'}">
                    <svg width="9" height="13" viewBox="0 0 9 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1 12L7.5 6.5L1 1" stroke="white"/>
                    </svg>
                  </div>
                </div>
              
                <div class="flex">
                  <div @click="changeCurveLineStartStyle('none')" class="p-[5px] h-[20px] flex items-center justify-center mr-[10px] w-max rounded-md cursor-pointer" :class="{'bg-[#BD9135]' : currentCurveLineSettings.startStyle == 'none'}">
                    <svg width="11" height="2" viewBox="0 0 11 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M1 1H10" stroke="white" stroke-width="2" stroke-linecap="round"/>
                    </svg>
  
                  </div>
                  <div @click="changeCurveLineEndStyle('none')" class="p-[5px] h-[20px] flex items-center justify-center w-max rounded-md cursor-pointer" :class="{'bg-[#BD9135]' : currentCurveLineSettings.endStyle == 'none'}">
                    <svg width="11" height="2" viewBox="0 0 11 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M1 1H10" stroke="white" stroke-width="2" stroke-linecap="round"/>
                    </svg>
                  </div>
                </div>
              
              </div>

              <div class="w-[35%]">
                <div class="text-white">
                  Колір
                </div>
                <div class="flex">
                  <div @click="changeLineColor('#474849')" class="bg-[#474849] border-[1px] h-[30px] w-[30px] rounded-md cursor-pointer" :class="{'border-2 border-[#BD9135]' : selectedComponentSettings.color == 'black'}"></div>
                  <div @click="changeLineColor('white')" class="bg-white h-[30px] w-[30px] rounded-md cursor-pointer" :class="{'border-2 border-[#BD9135]' : selectedComponentSettings.color == 'white'}"></div>
                  <div @click="changeLineColor('#C59014')" class="bg-[#C59014] h-[30px] w-[30px] rounded-md cursor-pointer" :class="{'border-2 border-[#BD9135]' : selectedComponentSettings.color == 'red'}"></div>
                  <div @click="changeLineColor('#8FC408')" class="bg-[#8FC408] h-[30px] w-[30px] rounded-md cursor-pointer" :class="{'border-2 border-[#BD9135]' : selectedComponentSettings.color == 'green'}"></div>
                  <div @click="changeLineColor('#0ABFC2')" class="bg-[#0ABFC2] h-[30px] w-[30px] rounded-md cursor-pointer" :class="{'border-2 border-[#BD9135]' : selectedComponentSettings.color == 'blue'}"></div>
                  <div @click="changeLineColor('#DA4167')" class="bg-[#DA4167] h-[30px] w-[30px] rounded-md cursor-pointer" :class="{'border-2 border-[#BD9135]' : selectedComponentSettings.color == 'blue'}"></div>
                  <div @click="changeLineColor('#1B4079')" class="bg-[#1B4079] h-[30px] w-[30px] rounded-md cursor-pointer" :class="{'border-2 border-[#BD9135]' : selectedComponentSettings.color == 'yellow'}"></div>
                </div>
              </div>

              <div class="w-[15%]">
                <div class="text-white">
                  Поворот
                </div>
                <div class="flex items-center">
                  <div class="cursor-pointer" @click="changeLineRotate(+selectedComponentSettings.rotate - 5)">
                    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M1.63523 10.117C0.937534 9.3773 0.437455 8.47039 0.185387 7.48018C-0.104303 6.34217 -0.0536836 5.14417 0.330971 4.03465C0.715626 2.92514 1.41741 1.95289 2.34933 1.2384C3.28126 0.523908 4.40237 0.0985928 5.5737 0.0151644C6.74504 -0.0682639 7.9151 0.193862 8.93889 0.769056C9.96267 1.34425 10.7952 2.20722 11.3332 3.25102C11.8712 4.29482 12.0911 5.47355 11.9656 6.64113C11.8402 7.80871 11.3748 8.9138 10.6273 9.81945L8.48791 8.05358C8.88983 7.56665 9.14002 6.97248 9.20748 6.34472C9.27495 5.71696 9.15671 5.0832 8.86744 4.52199C8.57816 3.96078 8.13056 3.4968 7.58011 3.18754C7.02966 2.87828 6.40056 2.73734 5.77078 2.7822C5.141 2.82706 4.53823 3.05573 4.03717 3.43988C3.53611 3.82404 3.15879 4.34678 2.95197 4.94332C2.74516 5.53986 2.71794 6.18398 2.8737 6.79585C2.99267 7.2632 3.21439 7.69604 3.52054 8.06385L4.4974 7.00003L4.67134 11.0822L0.589186 11.2561L1.63523 10.117Z" fill="white"/>
                    </svg>
                  </div>
                  <div class="text-white text-center font-bold mx-[10px] w-[50px]">{{+selectedComponentSettings.rotate}}°</div>
                  <div class="cursor-pointer" @click="changeLineRotate(+selectedComponentSettings.rotate + 5)">
                    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M10.3644 10.4967C11.062 9.75706 11.5621 8.85017 11.8141 7.85999C12.1038 6.72198 12.0532 5.52398 11.6685 4.41447C11.2839 3.30495 10.5821 2.3327 9.65018 1.61821C8.71825 0.903722 7.59714 0.478407 6.42581 0.394979C5.25448 0.31155 4.08441 0.573676 3.06062 1.14887C2.03684 1.72406 1.20434 2.58704 0.666318 3.63083C0.128293 4.67463 -0.0916091 5.85336 0.0338652 7.02094C0.159339 8.18852 0.624674 9.29362 1.3722 10.1993L3.5116 8.43339C3.10968 7.94646 2.85949 7.3523 2.79203 6.72454C2.72457 6.09677 2.8428 5.46302 3.13207 4.90181C3.42135 4.3406 3.86895 3.87661 4.4194 3.56735C4.96985 3.25809 5.59895 3.11716 6.22873 3.16201C6.85851 3.20687 7.46128 3.43555 7.96234 3.8197C8.4634 4.20385 8.84073 4.72659 9.04754 5.32314C9.25435 5.91968 9.28157 6.5638 9.12581 7.17566C9.00685 7.64298 8.78515 8.0758 8.47904 8.44358L7.50225 7.37984L7.3283 11.462L11.4105 11.6359L10.3644 10.4967Z" fill="white"/>
                    </svg>
                  </div>
                </div>
              </div>
  
              <div class="w-[15%]">
                <div class="text-white">
                  Ширина лінії
                </div>
                <div class="flex items-center">
                  <div class="cursor-pointer" @click="changeCurveLineStrokeWeight(+currentCurveLineSettings.strokeWeight + 1)">
                    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <circle cx="6" cy="6" r="6" fill="white"/>
                      <path d="M6 3V6M6 9V6M6 6H3H9" stroke="black"/>
                    </svg>
                  </div>
                  <div class="text-white text-center font-bold mx-[10px]">{{currentCurveLineSettings.strokeWeight}}</div>
                  <div class="cursor-pointer" @click="changeCurveLineStrokeWeight(+currentCurveLineSettings.strokeWeight - 1)">
                    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="6" cy="6" r="6" fill="white"/>
                    <path d="M6 6H3H9" stroke="black"/>
                    </svg>
                  </div>
                </div>
              </div>
  
              <div class="w-[20%]">
                <div @click="deleteCurveLine" class="bg-red-600 text-center cursor-pointer text-white font-semibold py-[5px] rounded-lg mt-[15px]">
                  Видалити
                </div>
              </div>
  
            </div>
          </div>
          <div v-if="brokenLineSelectedFlag" class="bg-black rounded-md w-[100%] h-[117px] mx-auto py-[10px] mt-[40px]">
            <div class="w-full text-white text-center py-[10px]">Редагування ламаної</div>
  
            <div class="px-[10px] flex">
  
              <div class="w-[20%]">
                <div @click="changeBrokenLineStrokeStyle('dotted')" :class="{'bg-[#BD9135]' : currentBrokenLineSettings.strokeStyle == 'dotted'}" class="p-[10px] w-max rounded-md cursor-pointer">
                  <svg width="123" height="4" viewBox="0 0 123 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 2H193" stroke="white" stroke-width="3" stroke-linejoin="round" stroke-dasharray="6 6"/>
                  </svg>
                </div>
                <div @click="changeBrokenLineStrokeStyle('solid')" :class="{'bg-[#BD9135]' : currentBrokenLineSettings.strokeStyle == 'solid'}" class="p-[10px] w-max rounded-md cursor-pointer">
                  <svg width="123" height="4" viewBox="0 0 123 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 2H193" stroke="white" stroke-width="3" stroke-linejoin="round"/>
                  </svg>
                </div>
              </div>
  
              <div class="w-[15%]">
  
                <div class="flex">
                  <div @click="changeBrokenLineStartStyle('arrow')" class="p-[5px] h-[20px] flex items-center justify-center mr-[10px] w-max rounded-md cursor-pointer" :class="{'bg-[#BD9135]' : currentBrokenLineSettings.startStyle == 'arrow'}">
                    <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7.5 0.5L1 6L7.5 11.5" stroke="white"/>
                    </svg>
                  </div>
                  <div @click="changeBrokenLineEndStyle('arrow')" class="p-[5px] h-[20px] flex items-center justify-center w-max rounded-md cursor-pointer" :class="{'bg-[#BD9135]' : currentBrokenLineSettings.endStyle == 'arrow'}">
                    <svg width="9" height="13" viewBox="0 0 9 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1 12L7.5 6.5L1 1" stroke="white"/>
                    </svg>
                  </div>
                </div>
              
                <div class="flex">
                  <div @click="changeBrokenLineStartStyle('none')" class="p-[5px] h-[20px] flex items-center justify-center mr-[10px] w-max rounded-md cursor-pointer" :class="{'bg-[#BD9135]' : currentBrokenLineSettings.startStyle == 'none'}">
                    <svg width="11" height="2" viewBox="0 0 11 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M1 1H10" stroke="white" stroke-width="2" stroke-linecap="round"/>
                    </svg>
  
                  </div>
                  <div @click="changeBrokenLineEndStyle('none')" class="p-[5px] h-[20px] flex items-center justify-center w-max rounded-md cursor-pointer" :class="{'bg-[#BD9135]' : currentBrokenLineSettings.endStyle == 'none'}">
                    <svg width="11" height="2" viewBox="0 0 11 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M1 1H10" stroke="white" stroke-width="2" stroke-linecap="round"/>
                    </svg>
                  </div>
                </div>
              
              </div>

              <div class="w-[35%]">
                <div class="text-white">
                  Колір
                </div>
                <div class="flex">
                  <div @click="changeLineColor('#474849')" class="bg-[#474849] border-[1px] h-[30px] w-[30px] rounded-md cursor-pointer" :class="{'border-2 border-[#BD9135]' : selectedComponentSettings.color == 'black'}"></div>
                  <div @click="changeLineColor('white')" class="bg-white h-[30px] w-[30px] rounded-md cursor-pointer" :class="{'border-2 border-[#BD9135]' : selectedComponentSettings.color == 'white'}"></div>
                  <div @click="changeLineColor('#C59014')" class="bg-[#C59014] h-[30px] w-[30px] rounded-md cursor-pointer" :class="{'border-2 border-[#BD9135]' : selectedComponentSettings.color == 'red'}"></div>
                  <div @click="changeLineColor('#8FC408')" class="bg-[#8FC408] h-[30px] w-[30px] rounded-md cursor-pointer" :class="{'border-2 border-[#BD9135]' : selectedComponentSettings.color == 'green'}"></div>
                  <div @click="changeLineColor('#0ABFC2')" class="bg-[#0ABFC2] h-[30px] w-[30px] rounded-md cursor-pointer" :class="{'border-2 border-[#BD9135]' : selectedComponentSettings.color == 'blue'}"></div>
                  <div @click="changeLineColor('#DA4167')" class="bg-[#DA4167] h-[30px] w-[30px] rounded-md cursor-pointer" :class="{'border-2 border-[#BD9135]' : selectedComponentSettings.color == 'blue'}"></div>
                  <div @click="changeLineColor('#1B4079')" class="bg-[#1B4079] h-[30px] w-[30px] rounded-md cursor-pointer" :class="{'border-2 border-[#BD9135]' : selectedComponentSettings.color == 'yellow'}"></div>
                </div>
              </div>

              <div class="w-[15%]">
                <div class="text-white">
                  Поворот
                </div>
                <div class="flex items-center">
                  <div class="cursor-pointer" @click="changeLineRotate(+selectedComponentSettings.rotate - 5)">
                    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M1.63523 10.117C0.937534 9.3773 0.437455 8.47039 0.185387 7.48018C-0.104303 6.34217 -0.0536836 5.14417 0.330971 4.03465C0.715626 2.92514 1.41741 1.95289 2.34933 1.2384C3.28126 0.523908 4.40237 0.0985928 5.5737 0.0151644C6.74504 -0.0682639 7.9151 0.193862 8.93889 0.769056C9.96267 1.34425 10.7952 2.20722 11.3332 3.25102C11.8712 4.29482 12.0911 5.47355 11.9656 6.64113C11.8402 7.80871 11.3748 8.9138 10.6273 9.81945L8.48791 8.05358C8.88983 7.56665 9.14002 6.97248 9.20748 6.34472C9.27495 5.71696 9.15671 5.0832 8.86744 4.52199C8.57816 3.96078 8.13056 3.4968 7.58011 3.18754C7.02966 2.87828 6.40056 2.73734 5.77078 2.7822C5.141 2.82706 4.53823 3.05573 4.03717 3.43988C3.53611 3.82404 3.15879 4.34678 2.95197 4.94332C2.74516 5.53986 2.71794 6.18398 2.8737 6.79585C2.99267 7.2632 3.21439 7.69604 3.52054 8.06385L4.4974 7.00003L4.67134 11.0822L0.589186 11.2561L1.63523 10.117Z" fill="white"/>
                    </svg>
                  </div>
                  <div class="text-white text-center font-bold mx-[10px] w-[50px]">{{+selectedComponentSettings.rotate}}°</div>
                  <div class="cursor-pointer" @click="changeLineRotate(+selectedComponentSettings.rotate + 5)">
                    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M10.3644 10.4967C11.062 9.75706 11.5621 8.85017 11.8141 7.85999C12.1038 6.72198 12.0532 5.52398 11.6685 4.41447C11.2839 3.30495 10.5821 2.3327 9.65018 1.61821C8.71825 0.903722 7.59714 0.478407 6.42581 0.394979C5.25448 0.31155 4.08441 0.573676 3.06062 1.14887C2.03684 1.72406 1.20434 2.58704 0.666318 3.63083C0.128293 4.67463 -0.0916091 5.85336 0.0338652 7.02094C0.159339 8.18852 0.624674 9.29362 1.3722 10.1993L3.5116 8.43339C3.10968 7.94646 2.85949 7.3523 2.79203 6.72454C2.72457 6.09677 2.8428 5.46302 3.13207 4.90181C3.42135 4.3406 3.86895 3.87661 4.4194 3.56735C4.96985 3.25809 5.59895 3.11716 6.22873 3.16201C6.85851 3.20687 7.46128 3.43555 7.96234 3.8197C8.4634 4.20385 8.84073 4.72659 9.04754 5.32314C9.25435 5.91968 9.28157 6.5638 9.12581 7.17566C9.00685 7.64298 8.78515 8.0758 8.47904 8.44358L7.50225 7.37984L7.3283 11.462L11.4105 11.6359L10.3644 10.4967Z" fill="white"/>
                    </svg>
                  </div>
                </div>
              </div>
  
              <div class="w-[15%]">
                <div class="text-white">
                  Ширина лінії
                </div>
                <div class="flex items-center">
                  <div class="cursor-pointer" @click="changeBrokenLineStrokeWeight(+currentBrokenLineSettings.strokeWeight + 1)">
                    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <circle cx="6" cy="6" r="6" fill="white"/>
                      <path d="M6 3V6M6 9V6M6 6H3H9" stroke="black"/>
                    </svg>
                  </div>
                  <div class="text-white text-center font-bold mx-[10px]">{{currentBrokenLineSettings.strokeWeight}}</div>
                  <div class="cursor-pointer" @click="changeBrokenLineStrokeWeight(+currentBrokenLineSettings.strokeWeight - 1)">
                    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="6" cy="6" r="6" fill="white"/>
                    <path d="M6 6H3H9" stroke="black"/>
                    </svg>
                  </div>
                </div>
              </div>
  
              <div class="w-[20%]">
                <div @click="deleteBrokenLine" class="bg-red-600 text-center cursor-pointer text-white font-semibold py-[5px] rounded-lg mt-[15px]">
                  Видалити
                </div>
              </div>
  
            </div>
          </div>
          <div v-if="sinusoidLineSelectedFlag" class="bg-black rounded-md w-[100%] h-[117px] mx-auto py-[10px] mt-[40px]">
            <div class="w-full text-white text-center py-[10px]">Редагування сінусоїди</div>
  
            <div class="px-[10px] flex">
  
              <div class="w-[20%]">
                <div @click="changeSinusoidLineStrokeStyle('dotted')" :class="{'bg-[#BD9135]' : currentSinusoidLineSettings.strokeStyle == 'dotted'}" class="p-[10px] w-max rounded-md cursor-pointer">
                  <svg width="123" height="4" viewBox="0 0 123 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 2H193" stroke="white" stroke-width="3" stroke-linejoin="round" stroke-dasharray="6 6"/>
                  </svg>
                </div>
                <div @click="changeSinusoidLineStrokeStyle('solid')" :class="{'bg-[#BD9135]' : currentSinusoidLineSettings.strokeStyle == 'solid'}" class="p-[10px] w-max rounded-md cursor-pointer">
                  <svg width="123" height="4" viewBox="0 0 123 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 2H193" stroke="white" stroke-width="3" stroke-linejoin="round"/>
                  </svg>
                </div>
              </div>

              <div class="w-[15%]">
  
                <div class="flex">
                  <div @click="changeSinusoidLineStartStyle('arrow')" class="p-[5px] h-[20px] flex items-center justify-center mr-[10px] w-max rounded-md cursor-pointer" :class="{'bg-[#BD9135]' : currentSinusoidLineSettings.startStyle == 'arrow'}">
                    <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7.5 0.5L1 6L7.5 11.5" stroke="white"/>
                    </svg>
                  </div>
                  <div @click="changeSinusoidLineEndStyle('arrow')" class="p-[5px] h-[20px] flex items-center justify-center w-max rounded-md cursor-pointer" :class="{'bg-[#BD9135]' : currentSinusoidLineSettings.endStyle == 'arrow'}">
                    <svg width="9" height="13" viewBox="0 0 9 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1 12L7.5 6.5L1 1" stroke="white"/>
                    </svg>
                  </div>
                </div>

                <div class="flex">
                  <div @click="changeSinusoidLineStartStyle('none')" class="p-[5px] h-[20px] flex items-center justify-center mr-[10px] w-max rounded-md cursor-pointer" :class="{'bg-[#BD9135]' : currentSinusoidLineSettings.startStyle == 'none'}">
                    <svg width="11" height="2" viewBox="0 0 11 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M1 1H10" stroke="white" stroke-width="2" stroke-linecap="round"/>
                    </svg>

                  </div>
                  <div @click="changeSinusoidLineEndStyle('none')" class="p-[5px] h-[20px] flex items-center justify-center w-max rounded-md cursor-pointer" :class="{'bg-[#BD9135]' : currentSinusoidLineSettings.endStyle == 'none'}">
                    <svg width="11" height="2" viewBox="0 0 11 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M1 1H10" stroke="white" stroke-width="2" stroke-linecap="round"/>
                    </svg>
                  </div>
                </div>

              </div>
  
              <div class="w-[35%]">
                <div class="text-white">
                  Колір
                </div>
                <div class="flex">
                  <div @click="changeLineColor('#474849')" class="bg-[#474849] border-[1px] h-[30px] w-[30px] rounded-md cursor-pointer" :class="{'border-2 border-[#BD9135]' : selectedComponentSettings.color == 'black'}"></div>
                  <div @click="changeLineColor('white')" class="bg-white h-[30px] w-[30px] rounded-md cursor-pointer" :class="{'border-2 border-[#BD9135]' : selectedComponentSettings.color == 'white'}"></div>
                  <div @click="changeLineColor('#C59014')" class="bg-[#C59014] h-[30px] w-[30px] rounded-md cursor-pointer" :class="{'border-2 border-[#BD9135]' : selectedComponentSettings.color == 'red'}"></div>
                  <div @click="changeLineColor('#8FC408')" class="bg-[#8FC408] h-[30px] w-[30px] rounded-md cursor-pointer" :class="{'border-2 border-[#BD9135]' : selectedComponentSettings.color == 'green'}"></div>
                  <div @click="changeLineColor('#0ABFC2')" class="bg-[#0ABFC2] h-[30px] w-[30px] rounded-md cursor-pointer" :class="{'border-2 border-[#BD9135]' : selectedComponentSettings.color == 'blue'}"></div>
                  <div @click="changeLineColor('#DA4167')" class="bg-[#DA4167] h-[30px] w-[30px] rounded-md cursor-pointer" :class="{'border-2 border-[#BD9135]' : selectedComponentSettings.color == 'blue'}"></div>
                  <div @click="changeLineColor('#1B4079')" class="bg-[#1B4079] h-[30px] w-[30px] rounded-md cursor-pointer" :class="{'border-2 border-[#BD9135]' : selectedComponentSettings.color == 'yellow'}"></div>
                </div>
              </div>

              <div class="w-[15%]">
                <div class="text-white">
                  Поворот
                </div>
                <div class="flex items-center">
                  <div class="cursor-pointer" @click="changeLineRotate(+selectedComponentSettings.rotate - 5)">
                    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M1.63523 10.117C0.937534 9.3773 0.437455 8.47039 0.185387 7.48018C-0.104303 6.34217 -0.0536836 5.14417 0.330971 4.03465C0.715626 2.92514 1.41741 1.95289 2.34933 1.2384C3.28126 0.523908 4.40237 0.0985928 5.5737 0.0151644C6.74504 -0.0682639 7.9151 0.193862 8.93889 0.769056C9.96267 1.34425 10.7952 2.20722 11.3332 3.25102C11.8712 4.29482 12.0911 5.47355 11.9656 6.64113C11.8402 7.80871 11.3748 8.9138 10.6273 9.81945L8.48791 8.05358C8.88983 7.56665 9.14002 6.97248 9.20748 6.34472C9.27495 5.71696 9.15671 5.0832 8.86744 4.52199C8.57816 3.96078 8.13056 3.4968 7.58011 3.18754C7.02966 2.87828 6.40056 2.73734 5.77078 2.7822C5.141 2.82706 4.53823 3.05573 4.03717 3.43988C3.53611 3.82404 3.15879 4.34678 2.95197 4.94332C2.74516 5.53986 2.71794 6.18398 2.8737 6.79585C2.99267 7.2632 3.21439 7.69604 3.52054 8.06385L4.4974 7.00003L4.67134 11.0822L0.589186 11.2561L1.63523 10.117Z" fill="white"/>
                    </svg>
                  </div>
                  <div class="text-white text-center font-bold mx-[10px] w-[50px]">{{+selectedComponentSettings.rotate}}°</div>
                  <div class="cursor-pointer" @click="changeLineRotate(+selectedComponentSettings.rotate + 5)">
                    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M10.3644 10.4967C11.062 9.75706 11.5621 8.85017 11.8141 7.85999C12.1038 6.72198 12.0532 5.52398 11.6685 4.41447C11.2839 3.30495 10.5821 2.3327 9.65018 1.61821C8.71825 0.903722 7.59714 0.478407 6.42581 0.394979C5.25448 0.31155 4.08441 0.573676 3.06062 1.14887C2.03684 1.72406 1.20434 2.58704 0.666318 3.63083C0.128293 4.67463 -0.0916091 5.85336 0.0338652 7.02094C0.159339 8.18852 0.624674 9.29362 1.3722 10.1993L3.5116 8.43339C3.10968 7.94646 2.85949 7.3523 2.79203 6.72454C2.72457 6.09677 2.8428 5.46302 3.13207 4.90181C3.42135 4.3406 3.86895 3.87661 4.4194 3.56735C4.96985 3.25809 5.59895 3.11716 6.22873 3.16201C6.85851 3.20687 7.46128 3.43555 7.96234 3.8197C8.4634 4.20385 8.84073 4.72659 9.04754 5.32314C9.25435 5.91968 9.28157 6.5638 9.12581 7.17566C9.00685 7.64298 8.78515 8.0758 8.47904 8.44358L7.50225 7.37984L7.3283 11.462L11.4105 11.6359L10.3644 10.4967Z" fill="white"/>
                    </svg>
                  </div>
                </div>
              </div>
  
              <div class="w-[15%]">
                <div class="text-white">
                  Ширина лінії
                </div>
                <div class="flex items-center">
                  <div class="cursor-pointer" @click="changeSinusoidLineStrokeWeight(+currentSinusoidLineSettings.strokeWeight + 1)">
                    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <circle cx="6" cy="6" r="6" fill="white"/>
                      <path d="M6 3V6M6 9V6M6 6H3H9" stroke="black"/>
                    </svg>
                  </div>
                  <div class="text-white text-center font-bold mx-[10px]">{{currentSinusoidLineSettings.strokeWeight}}</div>
                  <div class="cursor-pointer" @click="changeSinusoidLineStrokeWeight(+currentSinusoidLineSettings.strokeWeight - 1)">
                    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="6" cy="6" r="6" fill="white"/>
                    <path d="M6 6H3H9" stroke="black"/>
                    </svg>
                  </div>
                </div>
              </div>
  
              <div class="w-[20%]">
                <div @click="deleteSinusoidLine" class="bg-red-600 text-center cursor-pointer text-white font-semibold py-[5px] rounded-lg mt-[15px]">
                  Видалити
                </div>
              </div>
  
            </div>
          </div>
          <div v-if="textSelectedFlag" class="bg-black rounded-md w-[100%] h-[117px] mx-auto py-[10px] mt-[40px]">
            <!-- <div class="w-full text-white text-center py-[10px]">Редагування тексту</div> -->
  
            <div class="px-[10px] flex">
  
              <div class="w-[25%] relative">
                <div class="text-white">
                  Колір тексту
                </div>
                <div class="flex">
                  <div @click="changeTextColor('black')" class="bg-black border-[1px] h-[30px] w-[30px] rounded-md cursor-pointer" :class="{'border-2 border-[#BD9135]' : currentTextSettings.color == 'black'}"></div>
                  <div @click="changeTextColor('white')" class="bg-white h-[30px] w-[30px] rounded-md cursor-pointer" :class="{'border-2 border-[#BD9135]' : currentTextSettings.color == 'white'}"></div>
                  <div @click="changeTextColor('red')" class="bg-red-600 h-[30px] w-[30px] rounded-md cursor-pointer" :class="{'border-2 border-[#BD9135]' : currentTextSettings.color == 'red'}"></div>
                  <div @click="changeTextColor('green')" class="bg-green-600 h-[30px] w-[30px] rounded-md cursor-pointer" :class="{'border-2 border-[#BD9135]' : currentTextSettings.color == 'green'}"></div>
                  <div @click="changeTextColor('blue')" class="bg-blue-600 h-[30px] w-[30px] rounded-md cursor-pointer" :class="{'border-2 border-[#BD9135]' : currentTextSettings.color == 'blue'}"></div>
                  <div @click="changeTextColor('yellow')" class="bg-yellow-600 h-[30px] w-[30px] rounded-md cursor-pointer" :class="{'border-2 border-[#BD9135]' : currentTextSettings.color == 'yellow'}"></div>
                </div>
              </div>

              <div class="w-[20%]">
                <div class="text-white ">
                  Текст
                </div>
                <div class="flex items-center">
                  <input @change="e => changeTextValue(e.target.value)" :value="currentTextSettings.text" class="bg-slate-300 text-black w-[70px] px-[2px]" placeholder="Введіть текст..."  />
                </div>
              </div>
  
              <div class="w-[25%]">
                <div class="text-white">
                  Стиль шрифту
                </div>
                <div>
                  <div @click="changeTextFontStyle('normal')" class="p-[5px] text-white h-[20px] flex items-center justify-center mr-[10px] w-max rounded-md cursor-pointer" :class="{'bg-[#BD9135]' : currentTextSettings.fontStyle == 'normal'}">
                    Звичайний
                  </div>
                  <div @click="changeTextFontStyle('italic')" class="p-[5px] text-white h-[20px] flex items-center justify-center w-max rounded-md cursor-pointer" :class="{'bg-[#BD9135]' : currentTextSettings.fontStyle == 'italic'}">
                    Курсив
                  </div>
                  <div @click="changeTextFontWeight('800')" class="p-[5px] text-white h-[20px] flex items-center justify-center w-max rounded-md cursor-pointer" :class="{'bg-[#BD9135]' : currentTextSettings.fontStyle == 'bold'}">
                    Жирний
                  </div>
                </div>
              </div>
  
              <div class="w-[25%]">
                <div class="text-white">
                  Розмір шрифту
                </div>
                <div class="flex items-center">
                  <div class="cursor-pointer" @click="changeTextFontSize(+currentTextSettings.fontSize + 1)">
                    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <circle cx="6" cy="6" r="6" fill="white"/>
                      <path d="M6 3V6M6 9V6M6 6H3H9" stroke="black"/>
                    </svg>
                  </div>
                  <div class="text-white text-center font-bold mx-[10px]">{{currentTextSettings.fontSize}}</div>
                  <div class="cursor-pointer" @click="changeTextFontSize(+currentTextSettings.fontSize - 1)">
                    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="6" cy="6" r="6" fill="white"/>
                    <path d="M6 6H3H9" stroke="black"/>
                    </svg>
                  </div>
                </div>
              </div>
  
              <div class="w-[25%]">
                <div @click="deleteSinusoidLine" class="bg-red-600 text-center cursor-pointer text-white font-semibold py-[5px] rounded-lg mt-[15px]">
                  Видалити
                </div>
              </div>
  
            </div>
          </div>
          <div v-if="selectedComponentSettingsFlag" class="bg-black rounded-md w-[100%] h-[117px] mx-auto py-[10px] mt-[40px] select-none">
            <div class="w-full text-white text-center py-[10px]">Редагування елементу</div>
  
            <div class="px-[10px] flex">
  
              <div class="w-[25%]">
                <div class="text-white">
                  Колір
                </div>
                <div class="flex">
                  <div @click="changeComponentColor('#474849')" class="bg-[#474849] border-[1px] h-[30px] w-[30px] rounded-md cursor-pointer" :class="{'border-2 border-[#BD9135]' : selectedComponentSettings.color == 'black'}"></div>
                  <div @click="changeComponentColor('white')" class="bg-white h-[30px] w-[30px] rounded-md cursor-pointer" :class="{'border-2 border-[#BD9135]' : selectedComponentSettings.color == 'white'}"></div>
                  <div @click="changeComponentColor('#C59014')" class="bg-[#C59014] h-[30px] w-[30px] rounded-md cursor-pointer" :class="{'border-2 border-[#BD9135]' : selectedComponentSettings.color == 'red'}"></div>
                  <div @click="changeComponentColor('#8FC408')" class="bg-[#8FC408] h-[30px] w-[30px] rounded-md cursor-pointer" :class="{'border-2 border-[#BD9135]' : selectedComponentSettings.color == 'green'}"></div>
                  <div @click="changeComponentColor('#0ABFC2')" class="bg-[#0ABFC2] h-[30px] w-[30px] rounded-md cursor-pointer" :class="{'border-2 border-[#BD9135]' : selectedComponentSettings.color == 'blue'}"></div>
                  <div @click="changeComponentColor('#DA4167')" class="bg-[#DA4167] h-[30px] w-[30px] rounded-md cursor-pointer" :class="{'border-2 border-[#BD9135]' : selectedComponentSettings.color == 'blue'}"></div>
                  <div @click="changeComponentColor('#1B4079')" class="bg-[#1B4079] h-[30px] w-[30px] rounded-md cursor-pointer" :class="{'border-2 border-[#BD9135]' : selectedComponentSettings.color == 'yellow'}"></div>
                </div>
              </div>
  
              <div class="w-[25%]">
                <div class="text-white">
                  Розмір
                </div>
                <div class="flex items-center">
                  <div class="cursor-pointer" @click="changeComponentScale(+selectedComponentSettings.scale + 0.05)">
                    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <circle cx="6" cy="6" r="6" fill="white"/>
                      <path d="M6 3V6M6 9V6M6 6H3H9" stroke="black"/>
                    </svg>
                  </div>
                  <div class="text-white text-center font-bold mx-[10px]">{{(selectedComponentSettings.scale * 100)?.toFixed(2)}}%</div>
                  <div class="cursor-pointer" @click="changeComponentScale(+selectedComponentSettings.scale - 0.05)">
                    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="6" cy="6" r="6" fill="white"/>
                    <path d="M6 6H3H9" stroke="black"/>
                    </svg>
                  </div>
                </div>
              </div>
  
              <div class="w-[25%]">
                <div class="text-white">
                  Поворот
                </div>
                <div class="flex items-center">
                  <div class="cursor-pointer" @click="changeComponentRotate(+selectedComponentSettings.rotate - 5)">
                    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M1.63523 10.117C0.937534 9.3773 0.437455 8.47039 0.185387 7.48018C-0.104303 6.34217 -0.0536836 5.14417 0.330971 4.03465C0.715626 2.92514 1.41741 1.95289 2.34933 1.2384C3.28126 0.523908 4.40237 0.0985928 5.5737 0.0151644C6.74504 -0.0682639 7.9151 0.193862 8.93889 0.769056C9.96267 1.34425 10.7952 2.20722 11.3332 3.25102C11.8712 4.29482 12.0911 5.47355 11.9656 6.64113C11.8402 7.80871 11.3748 8.9138 10.6273 9.81945L8.48791 8.05358C8.88983 7.56665 9.14002 6.97248 9.20748 6.34472C9.27495 5.71696 9.15671 5.0832 8.86744 4.52199C8.57816 3.96078 8.13056 3.4968 7.58011 3.18754C7.02966 2.87828 6.40056 2.73734 5.77078 2.7822C5.141 2.82706 4.53823 3.05573 4.03717 3.43988C3.53611 3.82404 3.15879 4.34678 2.95197 4.94332C2.74516 5.53986 2.71794 6.18398 2.8737 6.79585C2.99267 7.2632 3.21439 7.69604 3.52054 8.06385L4.4974 7.00003L4.67134 11.0822L0.589186 11.2561L1.63523 10.117Z" fill="white"/>
                    </svg>
                  </div>
                  <div class="text-white text-center font-bold mx-[10px] w-[100px]">{{+selectedComponentSettings.rotate}}°</div>
                  <div class="cursor-pointer" @click="changeComponentRotate(+selectedComponentSettings.rotate + 5)">
                    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M10.3644 10.4967C11.062 9.75706 11.5621 8.85017 11.8141 7.85999C12.1038 6.72198 12.0532 5.52398 11.6685 4.41447C11.2839 3.30495 10.5821 2.3327 9.65018 1.61821C8.71825 0.903722 7.59714 0.478407 6.42581 0.394979C5.25448 0.31155 4.08441 0.573676 3.06062 1.14887C2.03684 1.72406 1.20434 2.58704 0.666318 3.63083C0.128293 4.67463 -0.0916091 5.85336 0.0338652 7.02094C0.159339 8.18852 0.624674 9.29362 1.3722 10.1993L3.5116 8.43339C3.10968 7.94646 2.85949 7.3523 2.79203 6.72454C2.72457 6.09677 2.8428 5.46302 3.13207 4.90181C3.42135 4.3406 3.86895 3.87661 4.4194 3.56735C4.96985 3.25809 5.59895 3.11716 6.22873 3.16201C6.85851 3.20687 7.46128 3.43555 7.96234 3.8197C8.4634 4.20385 8.84073 4.72659 9.04754 5.32314C9.25435 5.91968 9.28157 6.5638 9.12581 7.17566C9.00685 7.64298 8.78515 8.0758 8.47904 8.44358L7.50225 7.37984L7.3283 11.462L11.4105 11.6359L10.3644 10.4967Z" fill="white"/>
                    </svg>
                  </div>
                </div>
              </div>

              <div v-if="selectedComponentSettings.componentType == 'small-player' || selectedComponentSettings.componentType == 'player1'" class="w-[25%]">
                <div class="text-white">
                  Номер гравця
                </div>
                <div class="flex items-center">
                  <input @change="e => changeComponentPlayerNumber(e.target.value)" :value="selectedComponentSettings.playerNumber" class="bg-slate-300 text-black w-[40px] text-center" placeholder="Введіть текст..."  />
                </div>
              </div>
  
              <div class="w-[5%]">
                <!-- <div @click="deletePresetComponent" class="bg-red-600 text-center cursor-pointer text-white font-semibold py-[5px] rounded-lg mt-[15px]">
                  Видалити
                </div> -->

                <img @click="deletePresetComponent" src="./assets/trash_icon.svg" class="w-[45px] h-[45px]" alt="">
              </div>
  
            </div>
          </div>
          <div v-if="sectionSelectedFlag" class="bg-black rounded-md w-[100%] h-[117px] mx-auto py-[10px] mt-[40px]">
            <div class="w-full text-white text-center py-[10px]">Редагування секції</div>
  
            <div class="px-[10px] flex justify-between">
              
              <div class="w-[25%] relative bottom-[45px]">
                <div class="w-[100%]">
                  <div class="text-white text-[12px]">
                    Колір заливки
                  </div>
  
                  <div class="flex">
                    <div @click="changeSectionFill('rgba(71, 72, 73, 0.5)')" class="bg-[#474849] border-[1px] h-[30px] w-[30px] rounded-md cursor-pointer" :class="{'border-4 border-[#BD9135]' : currentSectionSettings.fill == 'rgba(71, 72, 73, 0.5)'}"></div>
                    <div @click="changeSectionFill('rgba(255, 255, 255, 0.5)')" class="bg-white h-[30px] w-[30px] rounded-md cursor-pointer" :class="{'border-4 border-[#BD9135]' : currentSectionSettings.fill == 'rgba(255, 255, 255, 0.5)'}"></div>
                    <div @click="changeSectionFill('rgba(197, 144, 20, 0.5)')" class="bg-[#C59014] h-[30px] w-[30px] rounded-md cursor-pointer" :class="{'border-4 border-[#BD9135]' : currentSectionSettings.fill == 'rgba(197, 144, 20, 0.5)'}"></div>
                    <div @click="changeSectionFill('rgba(143, 196, 8, 0.5)')" class="bg-[#8FC408] h-[30px] w-[30px] rounded-md cursor-pointer" :class="{'border-4 border-[#BD9135]' : currentSectionSettings.fill == 'rgba(143, 196, 8, 0.5)'}"></div>
                    <div @click="changeSectionFill('rgba(10, 191, 194, 0.5)')" class="bg-[#0ABFC2] h-[30px] w-[30px] rounded-md cursor-pointer" :class="{'border-4 border-[#BD9135]' : currentSectionSettings.fill == 'rgba(10, 191, 194, 0.5)'}"></div>
                    <div @click="changeSectionFill('rgba(218, 65, 103, 0.5)')" class="bg-[#DA4167] h-[30px] w-[30px] rounded-md cursor-pointer" :class="{'border-4 border-[#BD9135]' : currentSectionSettings.fill == 'rgba(218, 65, 103, 0.5)'}"></div>
                    <div @click="changeSectionFill('rgba(27, 64, 121, 0.5)')" class="bg-[#1B4079] h-[30px] w-[30px] rounded-md cursor-pointer" :class="{'border-4 border-[#BD9135]' : currentSectionSettings.fill == 'rgba(27, 64, 121, 0.5)'}"></div>
                  </div>
                </div>
                <div class="w-[100%] mt-[5px]">
                  <div class="text-white text-[12px]">
                    Колір обводки
                  </div>
  
                  <div class="flex">
                    <div @click="changeSectionStroke('rgba(71, 72, 73)')" class="bg-[#474849] border-[1px] h-[30px] w-[30px] rounded-md cursor-pointer" :class="{'border-4 border-[#BD9135]' : currentSectionSettings.stroke == 'rgba(71, 72, 73)'}"></div>
                    <div @click="changeSectionStroke('rgba(255, 255, 255)')" class="bg-white h-[30px] w-[30px] rounded-md cursor-pointer" :class="{'border-4 border-[#BD9135]' : currentSectionSettings.stroke == 'rgba(255, 255, 255)'}"></div>
                    <div @click="changeSectionStroke('rgba(197, 144, 20)')" class="bg-[#C59014] h-[30px] w-[30px] rounded-md cursor-pointer" :class="{'border-4 border-[#BD9135]' : currentSectionSettings.stroke == 'rgba(197, 144, 20)'}"></div>
                    <div @click="changeSectionStroke('rgba(143, 196, 8)')" class="bg-[#8FC408] h-[30px] w-[30px] rounded-md cursor-pointer" :class="{'border-4 border-[#BD9135]' : currentSectionSettings.stroke == 'rgba(143, 196, 8)'}"></div>
                    <div @click="changeSectionStroke('rgba(10, 191, 194)')" class="bg-[#0ABFC2] h-[30px] w-[30px] rounded-md cursor-pointer" :class="{'border-4 border-[#BD9135]' : currentSectionSettings.stroke == 'rgba(10, 191, 194)'}"></div>
                    <div @click="changeSectionStroke('rgba(218, 65, 103)')" class="bg-[#DA4167] h-[30px] w-[30px] rounded-md cursor-pointer" :class="{'border-4 border-[#BD9135]' : currentSectionSettings.stroke == 'rgba(218, 65, 103)'}"></div>
                    <div @click="changeSectionStroke('rgba(27, 64, 121)')" class="bg-[#1B4079] h-[30px] w-[30px] rounded-md cursor-pointer" :class="{'border-4 border-[#BD9135]' : currentSectionSettings.stroke == 'rgba(27, 64, 121)'}"></div>
                  </div>
                </div>
              </div>
              <div>
                <div class="text-white">
                  Розмір обводки
                </div>

                <div class="flex items-center">
                  <div class="cursor-pointer" @click="changeSectionStrokeWidth(+currentSectionSettings.strokeWidth + 1)">
                    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <circle cx="6" cy="6" r="6" fill="white"/>
                      <path d="M6 3V6M6 9V6M6 6H3H9" stroke="black"/>
                    </svg>
                  </div>
                  <div class="text-white text-center font-bold mx-[10px]">{{currentSectionSettings.strokeWidth}}</div>
                  <div class="cursor-pointer" @click="changeSectionStrokeWidth(+currentSectionSettings.strokeWidth - 1)">
                    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <circle cx="6" cy="6" r="6" fill="white"/>
                      <path d="M6 6H3H9" stroke="black"/>
                    </svg>
                  </div>

                </div> 

              </div>

              <div>
                <div class="text-white">
                  Текстура
                </div>

                <div class="flex">
                  <div @click="changeSectionFill('url(#zebraPatternBlack)', true)" class="h-[30px] w-[30px] mr-[10px] rounded-md cursor-pointer" :class="{'border-4 border-[#BD9135]' : currentSectionSettings.fill.includes('url')}">
                    <img src="./assets/zebra_texture_icon.svg" alt="">
                  </div>
                  <div @click="changeSectionFill('rgba(255, 255, 255, 0.5)', true)" class="h-[30px] w-[30px] rounded-md cursor-pointer" :class="{'border-4 border-[#BD9135]' : currentSectionSettings.fill.includes('rgba')}">
                    <img src="./assets/solid_texture_icon.svg" alt="">
                  </div>
                </div>

              </div>

              <div class="w-[25%]">
                <div @click="deleteSection" class="bg-red-600 text-center cursor-pointer text-white font-semibold py-[5px] rounded-lg mt-[15px]">
                  Видалити
                </div>
              </div>

          </div>  
        </div>

          <div v-if="!lineSelectedFlag && !curveLineSelectedFlag && !brokenLineSelectedFlag && !sinusoidLineSelectedFlag && !textSelectedFlag && !selectedComponentSettingsFlag && !sectionSelectedFlag" id="components" class="w-[1106px] mt-[20px] flex mx-auto justify-between py-[10px] border-[#363636] rounded-md">
            <div>
              <div class="flex gap-[10px]">
              </div>
              <div class="flex justify-between w-full bg-[#0f0f0f] mt-[10px] px-[10px] py-[10px] rounded-[9px]">
                <div class="flex gap-[12px]">
                  <!-- <div @click="deleteLastElement" class="px-[3px] py-[3px] rounded-md"><img @click="" src="./assets/back_icon.svg" class="w-[25px] cursor-pointer"></div> -->
                  <div @click="downloadImageAsPNG" class="flex w-[70px] rounded-md p-[10px] flex-col items-center">
                    <img @click="" src="./assets/download_icon.svg" class="w-[45px] cursor-pointer">
                    <div class="flex justify-center text-white text-[12px] text-center">
                      Завантажити<br>файл
                    </div>
                  </div>
                  <div v-if="!isSchemeSaved" @click="saveNewSchema" class="flex rounded-md w-[70px] p-[10px] flex-col items-center">
                    <img @click="" src="./assets/save_button.svg" class="w-[45px] cursor-pointer">
                    <div class="flex justify-center text-white text-[12px] text-center">
                      Зберегти<br>схему
                    </div>
                  </div>
                  <div v-else @click="updateSchema" class="flex rounded-md w-[70px] p-[10px] flex-col items-center">
                    <img @click="" src="./assets/save_button.svg" class="w-[45px] cursor-pointer">
                    <div class="flex justify-center text-white text-[12px] text-center">
                      Оновити<br>схему
                    </div>
                  </div>
                  <div v-if="isSchemeSaved" @click="createEmptySchema" class="flex rounded-md w-[70px] p-[10px] flex-col items-center">
                    <img @click="" src="./assets/new_schema_icon.svg" class="w-[45px] cursor-pointer">
                    <div class="flex justify-center text-white text-[12px] text-center">
                      Нова<br>схема
                    </div>
                  </div>
                  <!-- /schemas-table -->
                  <!-- @click="selectShowFlag = true" -->
                  <!-- @click="$router.push('/schemas-table')" -->
                  <div @click="$router.push('/schemas-table')" class="flex w-[70px] rounded-md p-[10px] flex-col items-center">
                    <img @click="" src="./assets/catalog_icon.svg" class="w-[45px] cursor-pointer">
                    <div class="flex justify-center text-white text-[12px] text-center">
                      Каталог<br>схем
                    </div>
                  </div>
  
                  <div class="w-[40px]"></div>
                  
                  <div @click="startDrawing" :class="{'bg-yellow-700' : startsDrawingFlag}" class="flex w-[70px] rounded-md p-[10px] flex-col items-center">
                    <img @click="" src="./assets/pen_icon.svg" class="w-[45px] cursor-pointer">
                    <div class="flex justify-center text-white text-[12px] text-center">
                      Олівець
                    </div>
                  </div>
                  <!-- <div @click="startDrawing" class="px-[3px] py-[3px] rounded-md" :class="{'bg-yellow-700' : startsDrawingFlag}"><img @click="" src="./assets/pen_icon.svg" class="w-[25px] cursor-pointer"></div> -->
                  
                  <div class="relative">
                    <div v-if="lineSectionFlag" class="absolute w-[120px] h-[120px] bottom-[104px] left-[-40px] bg-black rounded-t-md p-[10px]">
                      <div class="flex items-center">
                        <div @click="() => {nowDrawingLine = !nowDrawingLine; nowDrawingCurveLine = false; nowDrawingBrokenLine = false; nowDrawingSinusoidLine = false; startAddingTextFieldFlag = false}" class="px-[3px] w-[25px] h-[25px] py-[3px] rounded-md flex justify-center items-center" :class="{'bg-yellow-700' : nowDrawingLine}"><img @click="" src="./assets/arrow_icon.svg" class="w-[25px] cursor-pointer"></div>
                        <div class="ml-[4px] text-[12px]">пряма</div>
                      </div>
                      <div class="flex items-center">
                        <div @click="() => {nowDrawingCurveLine = !nowDrawingCurveLine; nowDrawingLine = false; nowDrawingBrokenLine = false; nowDrawingSinusoidLine = false; startAddingTextFieldFlag = false}" class="px-[3px] w-[25px] h-[25px] py-[3px] rounded-md flex justify-center items-center" :class="{'bg-yellow-700' : nowDrawingCurveLine}"><img @click="" src="./assets/curve_arrow_icon.svg" class="w-[25px] cursor-pointer"></div>
                        <div class="ml-[4px] text-[12px]">крива</div>
                      </div>
                      <div class="flex items-center">
                        <div @click="() => {nowDrawingBrokenLine = !nowDrawingBrokenLine; nowDrawingCurveLine = false; nowDrawingLine = false; nowDrawingSinusoidLine = false; startAddingTextFieldFlag = false}" class="px-[3px] w-[25px] h-[25px] py-[3px] rounded-md flex justify-center items-center" :class="{'bg-yellow-700' : nowDrawingBrokenLine}"><img @click="" src="./assets/broken_arrow_icon.svg" class="w-[25px] cursor-pointer"></div>
                        <div class="ml-[4px] text-[12px]">ломана</div>
                      </div>
                      <div class="flex items-center">
                        <div @click="() => {nowDrawingSinusoidLine = !nowDrawingSinusoidLine; nowDrawingCurveLine = false; nowDrawingLine = false; nowDrawingBrokenLine = false; startAddingTextFieldFlag = false}" class="px-[3px] w-[25px] h-[25px] py-[3px] rounded-md flex justify-center items-center" :class="{'bg-yellow-700' : nowDrawingSinusoidLine}"><img @click="" src="./assets/sinusoidal_line_icon.svg" class="w-[25px] cursor-pointer"></div>    
                        <div class="ml-[4px] text-[12px]">сінусоїда</div>
                      </div>
                      <!-- <div @click="() => {nowDrawingCurveLine = !nowDrawingCurveLine; nowDrawingLine = false; nowDrawingBrokenLine = false; nowDrawingSinusoidLine = false; startAddingTextFieldFlag = false}" class="px-[3px] w-[25px] h-[25px] py-[3px] rounded-md flex justify-center items-center" :class="{'bg-yellow-700' : nowDrawingCurveLine}"><img @click="" src="./assets/curve_arrow_icon.svg" class="w-[25px] cursor-pointer"></div>
                      <div @click="() => {nowDrawingBrokenLine = !nowDrawingBrokenLine; nowDrawingCurveLine = false; nowDrawingLine = false; nowDrawingSinusoidLine = false; startAddingTextFieldFlag = false}" class="px-[3px] w-[25px] h-[25px] py-[3px] rounded-md flex justify-center items-center" :class="{'bg-yellow-700' : nowDrawingBrokenLine}"><img @click="" src="./assets/broken_arrow_icon.svg" class="w-[25px] cursor-pointer"></div>
                      <div @click="() => {nowDrawingSinusoidLine = !nowDrawingSinusoidLine; nowDrawingCurveLine = false; nowDrawingLine = false; nowDrawingBrokenLine = false; startAddingTextFieldFlag = false}" class="px-[3px] w-[25px] h-[25px] py-[3px] rounded-md flex justify-center items-center" :class="{'bg-yellow-700' : nowDrawingSinusoidLine}"><img @click="" src="./assets/sinusoidal_line_icon.svg" class="w-[25px] cursor-pointer"></div>     -->
                    </div>
                    
                    <div @click="lineSectionFlag = !lineSectionFlag" class="flex w-[70px] rounded-md p-[10px] flex-col items-center">
                      <img @click="" src="./assets/arrow_icon.svg" class="w-[45px] cursor-pointer">
                      <div class="flex justify-center text-white text-[12px] text-center">
                        Лінії
                      </div>
                    </div>
                  </div>
  
                  <div @click="() => {startAddingTextFieldFlag = !startAddingTextFieldFlag; nowDrawingCurveLine = false; nowDrawingLine = false; nowDrawingBrokenLine = false; nowDrawingSinusoidLine = false}" :class="{'bg-yellow-700' : startAddingTextFieldFlag}" class="flex w-[70px] rounded-md p-[10px] flex-col items-center">
                    <img @click="" src="./assets/text_icon.svg" class="w-[35px] mt-[6px] mb-[5px] cursor-pointer">
                    <div class="flex justify-center text-white text-[12px] text-center">
                      Текст
                    </div>
                  </div>
  
                  <div class="w-[40px]"></div>
  
                  <div class="relative">
                    <div v-if="shapesSectionFlag" class="absolute w-[120px] h-[120px] bottom-[104px] left-[-40px] bg-black rounded-t-md p-[10px]">
                      <div class="flex items-center">
                        <div @click="() => {nowDrawingSquareSection = !nowDrawingSquareSection; nowDrawingCurveLine = false; nowDrawingLine = false; nowDrawingBrokenLine = false; nowDrawingSinusoidLine = false}" class="px-[3px] py-[3px] w-[20px] h-[20px] rounded-md flex justify-center items-center" :class="{'bg-yellow-700' : nowDrawingSquareSection}"><img @click="" src="./assets/square_icon.svg" class="w-[18px] cursor-pointer"></div>
                        <div class="ml-[4px] text-[12px]">прямокутник</div>
                      </div>
                      <div class="flex items-center">
                        <div @click="() => {nowDrawingCircleSection = !nowDrawingCircleSection; nowDrawingCurveLine = false; nowDrawingLine = false; nowDrawingBrokenLine = false; nowDrawingSinusoidLine = false}" class="px-[3px] py-[3px] w-[20px] h-[20px] rounded-md flex justify-center items-center" :class="{'bg-yellow-700' : nowDrawingCircleSection}"><img @click="" src="./assets/circle_icon.svg" class="w-[18px] cursor-pointer"></div>
                        <div class="ml-[4px] text-[12px]">овал</div>
                      </div>
                      <div class="flex items-center">
                        <div @click="() => {nowDrawingTriangleSection = !nowDrawingTriangleSection; nowDrawingCurveLine = false; nowDrawingLine = false; nowDrawingBrokenLine = false; nowDrawingSinusoidLine = false}" class="px-[3px] py-[3px] w-[20px] h-[20px] rounded-md flex justify-center items-center" :class="{'bg-yellow-700' : nowDrawingTriangleSection}"><img @click="" src="./assets/triangle_icon.svg" class="w-[18px] cursor-pointer"></div>
                        <div class="ml-[4px] text-[12px]">трикутник</div>
                      </div>
                    </div>
                    
                    <div @click="shapesSectionFlag = !shapesSectionFlag" class="flex w-[70px] rounded-md p-[10px] flex-col items-center">
                      <img @click="" src="./assets/shapes_icon.svg" class="w-[44px] mt-[5px] cursor-pointer">
                      <div class="flex justify-center text-white mt-[5px] text-[12px] text-center">
                        Зони
                      </div>
                    </div>
                  </div>
  
                  <div class="w-[40px]"></div>
  
                  <div class="relative">
                    <div v-if="componentsSectionFlag" class="absolute flex gap-[5px] w-[300px] h-[320px] bottom-[104px] left-[-100px] bg-black rounded-md p-[10px]">
                      <div class="grid grid-cols-3 gap-[5px] gap-y-[10px] overflow-y-auto">
                        <div 
                          @click="() => {selectedComponentForAddName = selectedComponentForAddName == 'player1' ? null : 'player1'}" 
                          id="player1" 
                          class="bg-[#474849] w-[90px] h-[90px] rounded-md flex justify-center items-center cursor-pointer"
                          :class="{'bg-yellow-700' : selectedComponentForAddName == 'player1'}"  
                        >
                          <player-one :screenWidth="screenWidth" :number="countPlayers.firstPlayer"/>
                        </div>
                        <div 
                          @click="() => {selectedComponentForAddName = selectedComponentForAddName == 'small-player' ? null : 'small-player'}" 
                          :class="{'bg-yellow-700' : selectedComponentForAddName == 'small-player'}"
                          id="small-player" 
                          class="bg-[#474849] w-[90px] h-[90px] rounded-md flex justify-center items-center cursor-pointer"
                        >
                          <small-player :screenWidth="screenWidth" :number="countPlayers.secondPlayer"/>
                        </div>
                        <div 
                          @click="selectedComponentForAddName = selectedComponentForAddName == 'coach' ? null : 'coach'" 
                          id="coach" 
                          class="bg-[#474849] w-[90px] h-[90px] rounded-md flex justify-center items-center cursor-pointer"
                          :class="{'bg-yellow-700' : selectedComponentForAddName == 'coach'}"
                        >
                          <Coach :screenWidth="screenWidth"/>
                        </div>
                        <div 
                          @click="selectedComponentForAddName = selectedComponentForAddName == 'circle1' ? null : 'circle1'" 
                          id="circle1" 
                          class="bg-[#474849] w-[90px] h-[90px] rounded-md flex justify-center items-center cursor-pointer"
                          :class="{'bg-yellow-700' : selectedComponentForAddName == 'circle1'}"  
                        >
                          <dot :screenWidth="screenWidth" :color="'#FFF'" />
                        </div>
                        <div 
                          @click="selectedComponentForAddName = selectedComponentForAddName == 'circle2' ? null : 'circle2'" 
                          id="circle2" 
                          class="bg-[#474849] w-[90px] h-[90px] rounded-md flex justify-center items-center cursor-pointer"
                          :class="{'bg-yellow-700' : selectedComponentForAddName == 'circle2'}"  
                        >
                          <Circle :screenWidth="screenWidth" :color="'#FFF'" />
                        </div>
                        <div 
                          @click="selectedComponentForAddName = selectedComponentForAddName == 'linear-dot1' ? null : 'linear-dot1'" 
                          id="linear-dot1"  
                          class="bg-[#474849] w-[90px] h-[90px] rounded-md flex justify-center items-center cursor-pointer"
                          :class="{'bg-yellow-700' : selectedComponentForAddName == 'linear-dot1'}"  
                        >
                          <linear-dot :screenWidth="screenWidth" :color="'#FFF'" />
                        </div>
                        <div 
                          @click="selectedComponentForAddName = selectedComponentForAddName == 'ball' ? null : 'ball'" 
                          id="ball"  
                          class="bg-[#474849] w-[90px] h-[90px] rounded-md flex justify-center items-center cursor-pointer"
                          :class="{'bg-yellow-700' : selectedComponentForAddName == 'ball'}"
                        >
                          <ball :screenWidth="screenWidth"/>
                        </div>
                        <div 
                          @click="selectedComponentForAddName = selectedComponentForAddName == 'cone' ? null : 'cone'" 
                          id="cone" 
                          class="bg-[#474849] w-[90px] h-[90px] rounded-md flex justify-center items-center cursor-pointer"
                          :class="{'bg-yellow-700' : selectedComponentForAddName == 'cone'}"  
                        >
                          <cone :screenWidth="screenWidth"/>
                        </div>
                        <div 
                          @click="selectedComponentForAddName = selectedComponentForAddName == 'ballon-player' ? null : 'ballon-player'" 
                          id="ballon-player" 
                          class="bg-[#474849] w-[90px] h-[90px] rounded-md flex justify-center items-center cursor-pointer"
                          :class="{'bg-yellow-700' : selectedComponentForAddName == 'ballon-player'}"    
                        >
                          <baloon-player class="scale-[0.75] rotate-12" />
                        </div>
                        <div 
                          @click="selectedComponentForAddName = selectedComponentForAddName == 'metal-player' ? null : 'metal-player'" 
                          id="metal-player"  
                          class="bg-[#474849] w-[90px] h-[90px] rounded-md flex justify-center items-center cursor-pointer"
                          :class="{'bg-yellow-700' : selectedComponentForAddName == 'metal-player'}"  
                        >
                          <metal-player class="scale-[0.75] rotate-12" />
                        </div>
                        <div 
                          @click="selectedComponentForAddName = selectedComponentForAddName == 'stairs' ? null : 'stairs'" 
                          id="stairs" 
                          class="bg-[#474849] w-[90px] h-[90px] rounded-md flex justify-center items-center cursor-pointer"
                          :class="{'bg-yellow-700' : selectedComponentForAddName == 'stairs'}"  
                        >
                          <Stairs class="scale-[0.75] rotate-12"/>
                        </div>
                        <div 
                          @click="selectedComponentForAddName = selectedComponentForAddName == 'barrier' ? null : 'barrier'" 
                          id="barrier" 
                          class="bg-[#474849] w-[90px] h-[90px] rounded-md flex justify-center items-center cursor-pointer"
                          :class="{'bg-yellow-700' : selectedComponentForAddName == 'barrier'}"  
                        >
                          <Barrier />
                        </div>
                        <div 
                          @click="selectedComponentForAddName = selectedComponentForAddName == 'cone-barrier' ? null : 'cone-barrier'" 
                          id="cone-barrier" 
                          class="bg-[#474849] w-[90px] h-[90px] rounded-md flex justify-center items-center cursor-pointer"
                          :class="{'bg-yellow-700' : selectedComponentForAddName == 'cone-barrier'}"  
                        >
                          <ConeBarrier />
                        </div>
                        <div 
                          @click="selectedComponentForAddName = selectedComponentForAddName == 'flag1' ? null : 'flag1'" 
                          id="flag1" 
                          class="bg-[#474849] w-[90px] h-[90px] rounded-md flex justify-center items-center cursor-pointer"
                          :class="{'bg-yellow-700' : selectedComponentForAddName == 'flag1'}"  
                        >
                          <flag :screenWidth="screenWidth" :color="'#BD9135'"/>
                        </div>
                        <div 
                          @click="selectedComponentForAddName = selectedComponentForAddName == 'stick' ? null : 'stick'" 
                          id="stick" 
                          class="bg-[#474849] w-[90px] h-[90px] rounded-md flex justify-center items-center cursor-pointer"
                          :class="{'bg-yellow-700' : selectedComponentForAddName == 'stick'}"  
                        >
                          <Stick :screenWidth="screenWidth" :color="'#BD9135'"/>
                        </div>
                        <div 
                          @click="selectedComponentForAddName = selectedComponentForAddName == 'right-gates' ? null : 'right-gates'" 
                          id="right-gates" 
                          class="bg-[#474849] w-[90px] h-[90px] rounded-md flex justify-center items-center cursor-pointer"
                          :class="{'bg-yellow-700' : selectedComponentForAddName == 'right-gates'}"  
                        >
                          <right-gates class="scale-[0.85]" :screenWidth="screenWidth" :color="'#FF0000'" />
                        </div>
                        <div 
                          @click="selectedComponentForAddName = selectedComponentForAddName == 'front-gates' ? null : 'front-gates'" 
                          id="front-gates"  
                          class="bg-[#474849] w-[90px] h-[90px] rounded-md flex justify-center items-center cursor-pointer"
                          :class="{'bg-yellow-700' : selectedComponentForAddName == 'front-gates'}"  
                        >
                          <front-gates class="scale-[0.85]" :screenWidth="screenWidth" :color="'#FF9900'" />
                        </div>
                        <div 
                          @click="selectedComponentForAddName = selectedComponentForAddName == 'left-gates' ? null : 'left-gates'" 
                          id="left-gates" 
                          class="bg-[#474849] w-[90px] h-[90px] rounded-md flex justify-center items-center cursor-pointer"
                          :class="{'bg-yellow-700' : selectedComponentForAddName == 'left-gates'}"  
                        >
                          <left-gates class="scale-[0.85]" :screenWidth="screenWidth" :color="'#52FF00'" />
                        </div>
                        <div 
                          @click="selectedComponentForAddName = selectedComponentForAddName == 'top-front-gates' ? null : 'top-front-gates'" 
                          id="top-front-gates" 
                          class="bg-[#474849] w-[90px] h-[90px] rounded-md flex justify-center items-center cursor-pointer"
                          :class="{'bg-yellow-700' : selectedComponentForAddName == 'top-front-gates'}"  
                        >
                          <top-front-gates class="scale-[0.85]" :screenWidth="screenWidth" :color="'#FFF'" />
                        </div>
                        <div 
                          @click="selectedComponentForAddName = selectedComponentForAddName == 'top-back-gates' ? null : 'top-back-gates'" 
                          id="top-back-gates" 
                          class="bg-[#474849] w-[90px] h-[90px] rounded-md flex justify-center items-center cursor-pointer"
                          :class="{'bg-yellow-700' : selectedComponentForAddName == 'top-back-gates'}"  
                        >
                          <top-back-gates class="scale-[0.85]" :screenWidth="screenWidth" :color="'#FFF'" />
                        </div>
                        <div 
                          @click="selectedComponentForAddName = selectedComponentForAddName == 'top-left-gates' ? null : 'top-left-gates'" 
                          id="top-left-gates" 
                          class="bg-[#474849] w-[90px] h-[90px] rounded-md flex justify-center items-center cursor-pointer"
                          :class="{'bg-yellow-700' : selectedComponentForAddName == 'top-left-gates'}"
                        >
                          <top-left-gates class="scale-[0.85]" :screenWidth="screenWidth" :color="'#FFF'" />
                        </div>
                        <div 
                          @click="selectedComponentForAddName = selectedComponentForAddName == 'top-right-gates' ? null : 'top-right-gates'" 
                          id="top-right-gates" 
                          class="bg-[#474849] w-[90px] h-[90px] rounded-md flex justify-center items-center cursor-pointer"
                          :class="{'bg-yellow-700' : selectedComponentForAddName == 'top-right-gates'}"  
                        >
                          <top-right-gates class="scale-[0.85]" :screenWidth="screenWidth" :color="'#FFF'" />
                        </div>
                      </div>
                      
                      <!-- <div class="flex flex-col justify-center relative">
                        <div class="flex ">
                          <div @click="() => {selectNewComponent('player1'); countPlayers.firstPlayer++}" id="player1" class=" w-max pr-[10px] flex justify-center align-middle cursor-pointer">
                            <player-one :screenWidth="screenWidth" :number="countPlayers.firstPlayer"/>
                          </div>
                          <div @click="() => {selectNewComponent('small-player'); countPlayers.secondPlayer++}" id="small-player" class=" w-max pr-[10px] flex justify-center align-middle cursor-pointer">
                            <small-player :screenWidth="screenWidth" :number="countPlayers.secondPlayer"/>
                          </div>
                        </div>
                        <div class="flex items-center mt-[20px]">
                          <div @click="selectNewComponent('circle1')" id="circle1" class="w-max mr-[10px] flex justify-center align-middle cursor-pointer">
                            <dot :screenWidth="screenWidth" :color="'#FFF'" />
                          </div>
                          <div @click="selectNewComponent('linear-dot1')" id="linear-dot1" class="w-max mr-[10px] flex justify-center align-middle cursor-pointer">
                            <linear-dot :screenWidth="screenWidth" :color="'#FFF'" />
                          </div>
                          <div @click="selectNewComponent('ball')" id="ball" class="w-max py-[20px] flex justify-center items-center cursor-pointer">
                            <ball :screenWidth="screenWidth"/>
                          </div>
                          <div @click="selectNewComponent('cone')" id="cone" class="w-max py-[20px] ml-[10px] flex justify-center items-center cursor-pointer">
                            <cone :screenWidth="screenWidth"/>
                          </div>
                        </div>
  
                        <div class="flex items-center mt-[20px]">
                          <div @click="selectNewComponent('ballon-player')" id="ballon-player" class="w-max mr-[10px] flex justify-center align-middle cursor-pointer">
                            <baloon-player />
                          </div>
                          <div @click="selectNewComponent('metal-player')" id="metal-player" class="w-max mr-[10px] flex justify-center align-middle cursor-pointer">
                            <metal-player />
                          </div>
                          <div @click="selectNewComponent('stairs')" id="stairs" class="w-max mr-[10px] flex justify-center align-middle cursor-pointer">
                            <Stairs />
                          </div>
                          <div @click="selectNewComponent('barrier')" id="barrier" class="w-max mr-[10px] flex justify-center align-middle cursor-pointer">
                            <Barrier />
                          </div>
                          <div @click="selectNewComponent('cone-barrier')" id="cone-barrier" class="w-max mr-[10px] flex justify-center align-middle cursor-pointer">
                            <ConeBarrier />
                          </div>
                          <div @click="selectNewComponent('flag1')" id="flag1" class="w-max mr-[10px] flex justify-center align-middle cursor-pointer">
                            <flag :screenWidth="screenWidth" :color="'#BD9135'"/>
                          </div>
                          <div @click="selectNewComponent('stick')" id="stick" class="w-max mr-[10px] flex justify-center align-middle cursor-pointer">
                            <Stick :screenWidth="screenWidth" :color="'#BD9135'"/>
                          </div>
                        </div>
                        <div class="flex mt-[20px]">
                          <div @click="selectNewComponent('right-gates')" id="right-gates" class="w-max mr-[10px] flex justify-center align-middle cursor-pointer">
                            <right-gates :screenWidth="screenWidth" :color="'#FF0000'" />
                          </div>
                          <div @click="selectNewComponent('front-gates')" id="front-gates" class="w-max mr-[10px] flex justify-center align-middle cursor-pointer">
                            <front-gates :screenWidth="screenWidth" :color="'#FF9900'" />
                          </div>
                          <div @click="selectNewComponent('left-gates')" id="left-gates" class="w-max mr-[10px] flex justify-center align-middle cursor-pointer">
                            <left-gates :screenWidth="screenWidth" :color="'#52FF00'" />
                          </div>
                        </div>
                      </div> -->
                    </div>
                    
                    <div @click="componentsSectionFlag = !componentsSectionFlag" class="flex w-[70px] rounded-md p-[10px] flex-col items-center">
                      <img @click="" src="./assets/components_icon.svg" class="w-[44px] mt-[5px] cursor-pointer">
                      <div class="flex justify-center text-white text-[12px] mt-[2px] text-center">
                        Компоненти
                      </div>
                    </div>
                  </div>
  
                  <div class="relative">
                    <div v-if="fieldSectionFlag" class="absolute grid grid-cols-2 overflow-y-auto gap-[5px] w-[360px] h-[320px] bottom-[104px] left-[-170px] bg-black rounded-md p-[10px]">
                      <div @click="fieldMode = 'clean'" :class="{
                        'border-[2px] border-[#C59014]': fieldMode == 'clean',
                      }" class="cursor-pointer h-max">
                        <img src="./assets/mini_clean_field.svg" class="w-[200px]" alt="">
                      </div>
                      <div @click="fieldMode = 'full'" :class="{
                        'border-[2px] border-[#C59014]': fieldMode == 'full',
                      }" class="cursor-pointer h-max">
                        <img src="./assets/mini_full_field.svg" class="w-[200px]" alt="">
                      </div>
                      <div @click="fieldMode = 'half'" :class="{
                        'border-[2px] border-[#C59014]': fieldMode == 'half',
                      }" class="cursor-pointer h-max">
                        <img src="./assets/mini_half_field.svg" class="w-[200px]" alt="">
                      </div>
                      <div @click="fieldMode = 'another-half'" :class="{
                        'border-[2px] border-[#C59014]': fieldMode == 'another-half',
                      }" class="cursor-pointer h-max">
                        <img src="./assets/mini_half_field.svg" class="w-[200px] rotate-180" alt="">
                      </div>
                      <div @click="fieldMode = 'full-lines'" :class="{
                        'border-[2px] border-[#C59014]': fieldMode == 'full-lines',
                      }" class="cursor-pointer h-max">
                        <img src="./assets/mini_full_lines_field_icon.svg" class="w-[200px]" alt="">
                      </div>
                      <div @click="fieldMode = 'full-lines2'" :class="{
                        'border-[2px] border-[#C59014]': fieldMode == 'full-lines2',
                      }" class="cursor-pointer h-max">
                        <img src="./assets/full_lines_field2.svg" class="w-[200px]" alt="">
                      </div>
                      <div @click="fieldMode = 'full-lines3'" :class="{
                        'border-[2px] border-[#C59014]': fieldMode == 'full-lines3',
                      }" class="cursor-pointer h-max">
                        <img src="./assets/mini_full_lines3.svg" class="w-[200px]" alt="">
                      </div>
                      <div @click="fieldMode = 'full-lines4'" :class="{
                        'border-[2px] border-[#C59014]': fieldMode == 'full-lines4',
                      }" class="cursor-pointer h-max">
                        <img src="./assets/mini_full_lines4.svg" class="w-[200px]" alt="">
                      </div>
                      <div @click="fieldMode = 'full-lines5'" :class="{
                        'border-[2px] border-[#C59014]': fieldMode == 'full-lines5',
                      }" class="cursor-pointer h-max">
                        <img src="./assets/mini_full_lines5.svg" class="w-[200px]" alt="">
                      </div>
                      <div @click="fieldMode = 'full-lines6'" :class="{
                        'border-[2px] border-[#C59014]': fieldMode == 'full-lines6',
                      }" class="cursor-pointer h-max">
                        <img src="./assets/mini_full_lines6.svg" class="w-[200px]" alt="">
                      </div>
                      <div @click="fieldMode = 'full-lines7'" :class="{
                        'border-[2px] border-[#C59014]': fieldMode == 'full-lines7',
                      }" class="cursor-pointer h-max">
                        <img src="./assets/mini_full_lines7.svg" class="w-[200px]" alt="">
                      </div>
                      <div @click="fieldMode = 'court'" :class="{
                        'border-[2px] border-[#C59014]': fieldMode == 'court',
                      }" class="cursor-pointer h-max">
                        <img src="./assets/mini_court.svg" class="w-[200px]" alt="">
                      </div>
                      <div @click="fieldMode = 'penalty-zone'" :class="{
                        'border-[2px] border-[#C59014]': fieldMode == 'penalty-zone',
                      }" class="cursor-pointer h-max">
                        <img src="./assets/mini_penalty_zone.svg" class="w-[200px]" alt="">
                      </div>
                      <div @click="fieldMode = 'penalty-zone-lines'" :class="{
                        'border-[2px] border-[#C59014]': fieldMode == 'penalty-zone-lines',
                      }" class="cursor-pointer h-max">
                        <img src="./assets/mini_penalty_zone_lines.svg" class="w-[200px]" alt="">
                      </div>
                    </div>
                    
                    <div @click="fieldSectionFlag = !fieldSectionFlag" class="flex w-[70px] rounded-md p-[10px] flex-col items-center">
                      <img @click="" src="./assets/football_field_icon.svg" class="w-[44px] mt-[5px] cursor-pointer">
                      <div class="flex justify-center text-white text-[12px] text-center">
                        Поля
                      </div>
                    </div>
                  </div>
  
                  <div class="w-[40px]"></div>
                  
                  <!-- <div @click="() => {startAddingTextFieldFlag = !startAddingTextFieldFlag; nowDrawingCurveLine = false; nowDrawingLine = false; nowDrawingBrokenLine = false; nowDrawingSinusoidLine = false}" class="px-[3px] py-[3px] rounded-md flex justify-center items-center" :class="{'bg-yellow-700' : startAddingTextFieldFlag}"><img @click="" src="./assets/text_icon.svg" class="w-[18px] cursor-pointer"></div> -->
                  <!-- <div @click="() => {nowDrawingSquareSection = !nowDrawingSquareSection; nowDrawingCurveLine = false; nowDrawingLine = false; nowDrawingBrokenLine = false; nowDrawingSinusoidLine = false}" class="px-[3px] py-[3px] rounded-md flex justify-center items-center" :class="{'bg-yellow-700' : nowDrawingSquareSection}"><img @click="" src="./assets/square_icon.svg" class="w-[18px] cursor-pointer"></div>
                  <div @click="() => {nowDrawingCircleSection = !nowDrawingCircleSection; nowDrawingCurveLine = false; nowDrawingLine = false; nowDrawingBrokenLine = false; nowDrawingSinusoidLine = false}" class="px-[3px] py-[3px] rounded-md flex justify-center items-center" :class="{'bg-yellow-700' : nowDrawingCircleSection}"><img @click="" src="./assets/circle_icon.svg" class="w-[18px] cursor-pointer"></div> -->
                
                </div>
                <!-- <div @click="clearAllField" class="px-[3px] py-[3px] rounded-md"><img @click="" src="./assets/trash_icon.svg" class="w-[25px] cursor-pointer"></div> -->
                <div @click="clearAllField" class="flex rounded-md p-[10px] flex-col items-center">
                  <img @click="" src="./assets/trash_icon.svg" class="w-[45px] cursor-pointer">
                  <div class="flex justify-center text-[#FF0000] text-[12px] text-center">
                    Очистити<br>холст
                  </div>
                </div>
                <div @click="deleteLastElement" class="flex rounded-md p-[10px] mt-[5px] flex-col items-center">
                  <img @click="" src="./assets/delete_last_component_icon.svg" class="w-[45px] cursor-pointer">
                  <div class="flex justify-center text-[#FF0000] mt-[12px] text-[12px] text-center">
                    Відмінити<br>дію
                  </div>
                </div>
              </div>
              <!-- <div @click="downloadImageAsPNG" class="bg-green-600 text-center cursor-pointer text-white font-semibold py-[5px] rounded-lg mt-[15px]">
                Завантажити
              </div>
              <div v-if="savedSchemaFlag" @click="createEmptySchema" class="bg-green-600 text-center cursor-pointer text-white font-semibold py-[5px] rounded-lg mt-[15px]">
                Нова схема
              </div>
              <div v-if="savedSchemaFlag" class="flex w-full justify-between">
                <div @click="updateSchema" class="w-[48%] bg-green-600 text-center cursor-pointer text-white font-semibold py-[5px] rounded-lg mt-[15px]">
                  Оновити
                </div>
                <div @click="deleteSchema" class="w-[48%] bg-red-600 text-center cursor-pointer text-white font-semibold py-[5px] rounded-lg mt-[15px]">
                  Видалити
                </div>
              </div>
              <button v-else-if="!elementId" :disabled="name.length == 0" @click="saveNewSchema" class="w-full disabled:opacity-40 bg-green-600 text-center cursor-pointer text-white font-semibold py-[5px] rounded-lg mt-[15px]">
                Зберегти
              </button>
              <button v-else-if="elementId" :disabled="name.length == 0" @click="addToElement" class="w-full disabled:opacity-40 bg-green-600 text-center cursor-pointer text-white font-semibold py-[5px] rounded-lg mt-[15px]">
                Додати до елемента
              </button>
              <div @click="selectShowFlag = true" class="bg-[#BD9135] text-center cursor-pointer text-white font-semibold py-[5px] rounded-lg mt-[15px]">
                Вибрати з каталога
              </div>
              <div v-if="addedToElementSchema" @click="$router.push(`/training-element/${elementIdRouteParam}`)" class="bg-[#BD9135] text-center cursor-pointer text-white font-semibold py-[5px] rounded-lg mt-[15px]">
                До елемента
              </div> -->
            </div>
          </div>
          <div v-if="$route.query.element_id" id="components" class="bg-black w-[300px] mt-[20px] flex justify-center mx-auto py-[10px] border-[#363636] rounded-md">
            <div class="flex">
              <div v-if="$route.query.element_id" :disabled="name.length == 0" @click="addToElement" class="flex w-[90px] rounded-md p-[10px] flex-col items-center">
                <img @click="" src="./assets/pin_icon.svg" class="w-[45px] cursor-pointer">
                <div class="flex justify-center text-white text-[12px] text-center">
                  Додати до елемента
                </div>
              </div>
              <div v-if="$route.query.element_id" @click="$router.push(`/training-element/${$route.query.element_id}`)" class="flex w-[90px] rounded-md p-[10px] flex-col items-center">
                <img @click="" src="./assets/back_to_element_icon.svg" class="w-[45px] cursor-pointer">
                <div class="flex justify-center text-white text-[12px] text-center">
                  Перейти до елемента
                </div>
              </div>
              <div v-if="$route.query.element_id" @click="$router.push(`/training-element/${$route.query.element_id}?delete_schema_element_id=${$route.query.schema_id}`)" class="flex w-[90px] rounded-md p-[10px] flex-col items-center">
                <img @click="" src="./assets/unpin_icon.svg" class="w-[45px] cursor-pointer">
                <div class="flex justify-center text-white text-[12px] text-center">
                  Видалити схему з елемента
                </div>
              </div>
            </div>
          </div>
        </div>
        <SchemasSelect v-if="selectShowFlag" @return="selectShowFlag = false" @selectSchema="schema => selectSchema(schema)" />
      </div>
    </div>
  </template>
  
  <script>
  import { ref, reactive, onMounted, watch } from 'vue'
  import { useRouter, useRoute } from 'vue-router'
  import { createNewTrainingSchemaRequest, getSvgCodeOfSchema, deleteSchemaByIdRequest, updateSchemaByIdRequest, getSchemaById } from '@/desktopApp/services/apiRequests'

  import SchemasSelect from './components/SchemasSelect.vue'
  
  import Field from './components/Field.vue'
  import CleanField from './components/CleanField.vue'
  import PlayerOne from './components/PlayerOne.vue'
  import PlayerTwo from './components/PlayerTwo.vue'
  import Ball from './components/Ball.vue'
  import Flag from './components/Flag.vue'
  import Dot from './components/Dot.vue'
  import PenaltyZone from './components/PenaltyZone.vue'
  import SmallPlayer from './components/SmallPlayer.vue'
  import LinearDot from './components/LinearDot.vue'
  import BaloonPlayer from './components/BaloonPlayer.vue'
  import MetalPlayer from './components/MetalPlayer.vue'
  import Stairs from './components/Stairs.vue'
  import Barrier from './components/Barrier.vue'
  import ConeBarrier from './components/ConeBarrier.vue'
  import Stick from './components/Stick.vue'
  import RightGates from './components/RightGates.vue'
  import LeftGates from './components/LeftGates.vue'
  import FrontGates from './components/FrontGates.vue'
  import Cone from './components/Cone.vue'
  import Coach from './components/Coach.vue'
  import TopFrontGates from './components/TopFrontGates.vue'
  import TopBackGates from './components/TopBackGates.vue'
  import TopLeftGates from './components/TopLeftGates.vue'
  import TopRightGates from './components/TopRightGates.vue'
  import HalfField from './components/HalfField.vue'
  import AnotherHalfField from './components/AnotherHalfField.vue'
  import PenaltyZoneLines from './components/PenaltyZoneLines.vue'
  import FullLinesField from './components/FullLinesField.vue'
  import FullLinesField2 from './components/FullLinesField2.vue'
  import FullLinesField3 from './components/FullLinesField3.vue'
  import FullLinesField4 from './components/FullLinesField4.vue'
  import FullLinesField5 from './components/FullLinesField5.vue'
  import FullLinesField6 from './components/FullLinesField6.vue'
  import FullLinesField7 from './components/FullLinesField7.vue'
  import Court from './components/Court.vue'
  import Circle from './components/Circle.vue'

  function isPointOnSegment(A, B, K) {
    // Функция проверяет, находится ли точка K на отрезке AB

    // Рассчитываем уравнение прямой через точки A и B: y = mx + c
    let m = (B[1] - A[1]) / (B[0] - A[0]);
    let c = A[1] - m * A[0];

    // Проверяем, лежит ли точка K на прямой AB
    if (K[1] === m * K[0] + c) {
      console.log('in line')
        // Проверяем, находится ли точка K между точками A и B по оси X
        if ((K[0] >= A[0] && K[0] <= B[0]) || (K[0] <= A[0] && K[0] >= B[0])) {
            // Проверяем, находится ли точка K между точками A и B по оси Y
            if ((K[1] >= A[1] && K[1] <= B[1]) || (K[1] <= A[1] && K[1] >= B[1])) {
                return true;
            }
        }
    }

    return false;
  }
  
  export default {
    name: 'TrainingSchemes',
  
    setup() {  
      const startsDrawingFlag = ref(false)
      const processDrawingFlag = ref(false)

      const newPathId = ref(null)

      const fieldMode = ref('full')

      const parseFillColorToTexture = (color) => {
        // rgba(71, 72, 73, 0.5)
        // rgba(255, 255, 255, 0.5)
        // rgba(197, 144, 20, 0.5)
        // rgba(143, 196, 8, 0.5)
        // rgba(10, 191, 194, 0.5)
        // rgba(218, 65, 103, 0.5)
        // rgba(27, 64, 121, 0.5)

        if (color === 'rgba(71, 72, 73, 0.5)') {
          return 'url(#zebraPatternGray)'
        }
        else if (color === 'rgba(255, 255, 255, 0.5)') {
          return 'url(#zebraPatternWhite)'
        }
        else if (color === 'rgba(197, 144, 20, 0.5)') {
          return 'url(#zebraPatternOrange)'
        }
        else if (color === 'rgba(143, 196, 8, 0.5)') {
          return 'url(#zebraPatternGreen)'
        }
        else if (color === 'rgba(10, 191, 194, 0.5)') {
          return 'url(#zebraPatternBlue)'
        }
        else if (color === 'rgba(218, 65, 103, 0.5)') {
          return 'url(#zebraPatternRose)'
        }
        else if (color === 'rgba(27, 64, 121, 0.5)') {
          return 'url(#zebraPatternDarkBlue)'
        }
      }

      //sections flags
      const lineSectionFlag = ref(false)
      const shapesSectionFlag = ref(false)
      const fieldSectionFlag = ref(false)
      const componentsSectionFlag = ref(false)

      //line vars
      const newLinePathId = ref(null)
      const newCurveLinePathId = ref(null)
      const newBrokenLinePathId = ref(null)
      
      const nowDrawingLine = ref(false)
      const nowDrawingCurveLine = ref(false)
      const nowDrawingBrokenLine = ref(false)

      const newLineD = ref('')
      const newCurveLineD = ref('')
      const newBrokenLineD = ref('')

      const startLineDrawingCoords = ref({ x: 0, y: 0 })
      const startCurveLineDrawingCoords = ref({ x: 0, y: 0 })
      const startBrokenLineDrawingCoords = ref({ x: 0, y: 0 })

      const curveProjectingFlag = ref(false)
      const curveProjectingPoint = ref(null)
      
      const brokenProjectingFlag = ref(false)
      const brokenProjectingPoint = ref(null)

      const lineProjectingFlag = ref(false)
      const lineProjectingPoint = ref(null)

      const changeLineColor = (color) => {
        const path = selectedElement.value.getElementsByTagName('path')[0]
        path.setAttribute('stroke', color)
      }

      const changeCurveLineColor = (color) => {
        const schemaContainer = document.getElementById('schema-container').querySelector('svg')
        const line = schemaContainer.getElementById(newCurveLinePathId.value)
        line.setAttribute('stroke', color)
      }

      const changeBrokenLineColor = (color) => {
        const schemaContainer = document.getElementById('schema-container').querySelector('svg')
        const line = schemaContainer.getElementById(newBrokenLinePathId.value)
        line.setAttribute('stroke', color)
      }

      const changeSinusoidLineColor = (color) => {
        const schemaContainer = document.getElementById('schema-container').querySelector('svg')
        const line = schemaContainer.getElementById(newSinusoidLinePathId.value)
        line.setAttribute('stroke', color)
      }

      //sinusoid line
      const nowDrawingSinusoidLine = ref(false)
      const newSinusoidLinePathId = ref(null)
      const newSinusoidLineD = ref('')
      const startSinusoidLineDrawingCoords = ref({ x: 0, y: 0 })

      const drawingLinesListeners = () => {
        const schemaContainer = document.getElementById('schema-container').querySelector('svg')

        schemaContainer.addEventListener('mousedown', function (e) {
          if (nowDrawingLine.value) {
            newLinePathId.value = new Date().getTime()

            const newGroup = document.createElementNS('http://www.w3.org/2000/svg', 'g');
            newGroup.setAttribute('id', newLinePathId.value + '_group');
            newGroup.setAttribute('class', 'line');

            const newLine = document.createElementNS('http://www.w3.org/2000/svg', 'path');

            const newLineEl = document.createElementNS('http://www.w3.org/2000/svg', 'line');

            newLineEl.setAttribute('x1', e.offsetX);
            newLineEl.setAttribute('y1', e.offsetY);
            newLineEl.setAttribute('x2', e.offsetX);
            newLineEl.setAttribute('y2', e.offsetY);

            newLineEl.setAttribute('stroke', 'none');
            newLineEl.setAttribute('stroke-width', '2');
            newLineEl.setAttribute('id', newLinePathId.value + '_start-line');

            newGroup.appendChild(newLineEl)
            
            newLine.setAttribute('d', `M${e.offsetX} ${e.offsetY} `);
            newLine.setAttribute('fill', 'none');
            newLine.setAttribute('style', 'cursor: pointer;');

            newLine.setAttribute('stroke', 'black');
            newLine.setAttribute('stroke-width', '2');
            newLine.setAttribute('id', newLinePathId.value);
            
            newGroup.appendChild(newLine)
            schemaContainer.appendChild(newGroup)
          }
          else if (nowDrawingCurveLine.value) {
            newCurveLinePathId.value = new Date().getTime()

            const newGroup = document.createElementNS('http://www.w3.org/2000/svg', 'g');
            newGroup.setAttribute('id', newCurveLinePathId.value + '_group');
            newGroup.setAttribute('class', 'curve-line');

            const newLine = document.createElementNS('http://www.w3.org/2000/svg', 'path');

            newLine.setAttribute('d', `M${e.offsetX} ${e.offsetY} `);
            newLine.setAttribute('fill', 'none');

            newLine.setAttribute('stroke', 'black');
            newLine.setAttribute('stroke-width', '2');
            newLine.setAttribute('style', 'cursor: pointer;');
            newLine.setAttribute('id', newCurveLinePathId.value);

            const startCircle = document.createElementNS('http://www.w3.org/2000/svg', 'circle');
            startCircle.setAttribute('cx', e.offsetX);
            startCircle.setAttribute('cy', e.offsetY);
            startCircle.setAttribute('r', '5');
            startCircle.setAttribute('fill', 'black');
            startCircle.setAttribute('id', newCurveLinePathId.value + '_circle-curve-line_' + 0);
            startCircle.setAttribute('class', 'curve-move-point_' + newCurveLinePathId.value);
            startCircle.setAttribute('style', 'display: none;');

            newGroup.appendChild(newLine)
            newGroup.appendChild(startCircle)

            schemaContainer.appendChild(newGroup)

            startCurveLineDrawingCoords.value = { x: e.offsetX, y: e.offsetY }
          }
          else if (nowDrawingBrokenLine.value) {
            newBrokenLinePathId.value = new Date().getTime()

            const newGroup = document.createElementNS('http://www.w3.org/2000/svg', 'g');
            newGroup.setAttribute('id', newBrokenLinePathId.value + '_group');
            newGroup.setAttribute('class', 'broken-line');

            const newLine = document.createElementNS('http://www.w3.org/2000/svg', 'path');

            newLine.setAttribute('d', `M${e.offsetX} ${e.offsetY} `);
            newLine.setAttribute('fill', 'none');

            newLine.setAttribute('stroke', 'black');
            newLine.setAttribute('stroke-width', '2');
            newLine.setAttribute('style', 'cursor: pointer;');
            newLine.setAttribute('id', newBrokenLinePathId.value);

            const startCircle = document.createElementNS('http://www.w3.org/2000/svg', 'circle');
            startCircle.setAttribute('cx', e.offsetX);
            startCircle.setAttribute('cy', e.offsetY);
            startCircle.setAttribute('r', '5');
            startCircle.setAttribute('fill', 'black');
            startCircle.setAttribute('id', newBrokenLinePathId.value + '_circle-broken-line_' + 0);
            startCircle.setAttribute('class', 'broken-move-point_' + newBrokenLinePathId.value);
            startCircle.setAttribute('style', 'display: none;');

            newGroup.appendChild(newLine)
            newGroup.appendChild(startCircle)

            schemaContainer.appendChild(newGroup)

            startBrokenLineDrawingCoords.value = { x: e.offsetX, y: e.offsetY }
          }
          else if (nowDrawingSinusoidLine.value) {
            newSinusoidLinePathId.value = new Date().getTime()

            const newGroup = document.createElementNS('http://www.w3.org/2000/svg', 'g');
            newGroup.setAttribute('id', newSinusoidLinePathId.value + '_group');
            newGroup.setAttribute('class', 'sinusoid-line');

            const newLine = document.createElementNS('http://www.w3.org/2000/svg', 'path');

            const newLineEl = document.createElementNS('http://www.w3.org/2000/svg', 'line');

            newLineEl.setAttribute('x1', e.offsetX);
            newLineEl.setAttribute('y1', e.offsetY);
            newLineEl.setAttribute('x2', e.offsetX);
            newLineEl.setAttribute('y2', e.offsetY);

            newLineEl.setAttribute('stroke', 'none');
            newLineEl.setAttribute('stroke-width', '2');
            newLineEl.setAttribute('id', newSinusoidLinePathId.value + '_start-line');

            newGroup.appendChild(newLineEl)

            newLine.setAttribute('d', `M${e.offsetX} ${e.offsetY} `);
            newLine.setAttribute('fill', 'none');

            newLine.setAttribute('stroke', 'black');
            newLine.setAttribute('stroke-width', '2');
            newLine.setAttribute('style', 'cursor: pointer;');
            newLine.setAttribute('id', newSinusoidLinePathId.value);

            newGroup.appendChild(newLine)
            schemaContainer.appendChild(newGroup)

            startSinusoidLineDrawingCoords.value = { x: e.offsetX, y: e.offsetY }
          }
        })

        schemaContainer.addEventListener('mousemove', function (e) {
          if (nowDrawingLine.value && newLinePathId.value) {
            const formattedLineD = schemaContainer.getElementById(newLinePathId.value).getAttribute('d').replace(newLineD.value, '');
            const newStartLine = schemaContainer.getElementById(newLinePathId.value + '_start-line')

            newStartLine.setAttribute('x2', e.offsetX);
            newStartLine.setAttribute('y2', e.offsetY);


            newLineD.value = `L${e.offsetX} ${e.offsetY} `;

            schemaContainer.getElementById(newLinePathId.value).setAttribute('d', formattedLineD + newLineD.value);
          } 
          else if (nowDrawingCurveLine.value && newCurveLinePathId.value) {
            const formattedLineD = schemaContainer.getElementById(newCurveLinePathId.value).getAttribute('d').replace(newCurveLineD.value, '');

            const mouseX = e.clientX - schemaContainer.getBoundingClientRect().left;
            const mouseY = e.clientY - schemaContainer.getBoundingClientRect().top;

            newCurveLineD.value = `L${mouseX} ${mouseY} `;

            schemaContainer.getElementById(newCurveLinePathId.value).setAttribute('d', formattedLineD + newCurveLineD.value);
          }
          else if (nowDrawingBrokenLine.value && newBrokenLinePathId.value) {
            const formattedLineD = schemaContainer.getElementById(newBrokenLinePathId.value).getAttribute('d').replace(newBrokenLineD.value, '');

            newBrokenLineD.value = `L${e.offsetX} ${e.offsetY} `;

            schemaContainer.getElementById(newBrokenLinePathId.value).setAttribute('d', formattedLineD + newBrokenLineD.value);
          }
          else if (nowDrawingSinusoidLine.value && newSinusoidLinePathId.value) {
            const currentLine = schemaContainer.getElementById(newSinusoidLinePathId.value)

            const currentUserCursor = {
              x: e.offsetX,
              y: e.offsetY
            }

            const sinusoidalDots = []
            const rotateAngle = Math.atan((currentUserCursor.y - startSinusoidLineDrawingCoords.value.y) / (currentUserCursor.x - startSinusoidLineDrawingCoords.value.x)) * (180 / Math.PI)
            const rotateAngle360 = currentUserCursor.x < startSinusoidLineDrawingCoords.value.x ? rotateAngle + 180 : rotateAngle
            const pointFromStartToCursor = Math.sqrt(Math.pow(currentUserCursor.x - startSinusoidLineDrawingCoords.value.x, 2) + Math.pow(currentUserCursor.y - startSinusoidLineDrawingCoords.value.y, 2))

            for (let i = 0; i < pointFromStartToCursor; i++) {
              const x = startSinusoidLineDrawingCoords.value.x + i
              const y = Math.sin(x / 3) * 5 + startSinusoidLineDrawingCoords.value.y
              sinusoidalDots.push({ x: x, y: y })
            }

            currentLine.setAttribute('transform', `rotate(${rotateAngle360})`)
            currentLine.setAttribute('transform-origin', `${startSinusoidLineDrawingCoords.value.x}px ${startSinusoidLineDrawingCoords.value.y}px`)

            let d = `M${sinusoidalDots[0].x} ${sinusoidalDots[0].y} `
            for (let i = 1; i < sinusoidalDots.length; i++) {
              d += `L${sinusoidalDots[i].x} ${sinusoidalDots[i].y} `
            }

            const newStartLine = schemaContainer.getElementById(newSinusoidLinePathId.value + '_start-line')

            newStartLine.setAttribute('x2', e.offsetX);
            newStartLine.setAttribute('y2', e.offsetY);

            currentLine.setAttribute('d', d)
          }

          if (curveProjectingFlag.value) {
            // if (!elementMoveFlag.value) {
              const pathId = curveProjectingPoint.value.id.split('_')[0]
              let circleId = curveProjectingPoint.value.id.split('_')[2]

              const currentGroup = schemaContainer.getElementById(pathId + '_group')
              const currentPath = schemaContainer.getElementById(pathId)
              const collider = currentGroup.getElementsByTagName('rect')[0]
              //vert
              // const mouseX = e.clientX - schemaContainer.getBoundingClientRect().left;
              let mouseY = e.clientY - schemaContainer.getBoundingClientRect().top;
              let mouseX = e.clientX - schemaContainer.getBoundingClientRect().left;

              console.log('currentGroup -------- ', currentGroup)
              if (currentGroup.getAttribute('transform')) {
                const translateX = currentGroup.getAttribute('transform').split('(')[1].split(')')[0].split(',')[0]
                const translateY = currentGroup.getAttribute('transform').split('(')[1].split(')')[0].split(',')[1]

                mouseX = e.clientX - schemaContainer.getBoundingClientRect().left - translateX;
                mouseY = e.clientY - schemaContainer.getBoundingClientRect().top - translateY;
              }

              curveProjectingPoint.value.setAttribute('cx', mouseX)
              curveProjectingPoint.value.setAttribute('cy', mouseY)
            // }
          }
          if (brokenProjectingFlag.value) {
            const pathId = brokenProjectingPoint.value.id.split('_')[0]
            let circleId = brokenProjectingPoint.value.id.split('_')[2]

            const currentGroup = schemaContainer.getElementById(pathId + '_group')
            
            let mouseY = e.clientY - schemaContainer.getBoundingClientRect().top;
            let mouseX = e.clientX - schemaContainer.getBoundingClientRect().left;

            console.log('currentGroup -------- ', currentGroup)
            if (currentGroup.getAttribute('transform')) {
              const translateX = currentGroup.getAttribute('transform').split('(')[1].split(')')[0].split(',')[0]
              const translateY = currentGroup.getAttribute('transform').split('(')[1].split(')')[0].split(',')[1]

              mouseX = e.clientX - schemaContainer.getBoundingClientRect().left - translateX;
              mouseY = e.clientY - schemaContainer.getBoundingClientRect().top - translateY;
            }

            // const mouseX = e.clientX - schemaContainer.getBoundingClientRect().left;
            // const mouseY = e.clientY - schemaContainer.getBoundingClientRect().top;
            brokenProjectingPoint.value.setAttribute('cx', mouseX)
            brokenProjectingPoint.value.setAttribute('cy', mouseY)
          }
          if (lineProjectingFlag.value) {
            const pathId = lineProjectingPoint.value.id.split('_')[0]
            let circleId = lineProjectingPoint.value.id.split('_')[2]

            const currentGroup = schemaContainer.getElementById(pathId + '_group')
            
            let mouseY = e.clientY - schemaContainer.getBoundingClientRect().top;
            let mouseX = e.clientX - schemaContainer.getBoundingClientRect().left;

            console.log('currentGroup -------- ', currentGroup)
            if (currentGroup.getAttribute('transform')) {
              const translateX = currentGroup.getAttribute('transform').split('(')[1].split(')')[0].split(',')[0]
              const translateY = currentGroup.getAttribute('transform').split('(')[1].split(')')[0].split(',')[1]

              mouseX = e.clientX - schemaContainer.getBoundingClientRect().left - translateX;
              mouseY = e.clientY - schemaContainer.getBoundingClientRect().top - translateY;
            }

            // const mouseX = e.clientX - schemaContainer.getBoundingClientRect().left;
            // const mouseY = e.clientY - schemaContainer.getBoundingClientRect().top;
            lineProjectingPoint.value.setAttribute('cx', mouseX)
            lineProjectingPoint.value.setAttribute('cy', mouseY)
          }
        })

        schemaContainer.addEventListener('mouseup', function (e) {
          if (nowDrawingLine.value && newLinePathId.value) {

            //add collider to line
            const path = schemaContainer.getElementById(newLinePathId.value)
            const lineGroup = schemaContainer.getElementById(newLinePathId.value + '_group')
            console.log('lineGroup -------- ', lineGroup)

            const startLineCoords = {
              x: path.getAttribute('d').split('M')[1].split(' ')[0],
              y: path.getAttribute('d').split('M')[1].split(' ')[1]
            }

            const pathCollider = document.createElementNS('http://www.w3.org/2000/svg', 'rect');
            pathCollider.setAttribute('x', path.getBBox().x - 5);
            pathCollider.setAttribute('y', path.getBBox().y - 10);
            pathCollider.setAttribute('width', path.getBBox().width + 10);
            pathCollider.setAttribute('height', path.getBBox().height + 20);
            pathCollider.setAttribute('fill', 'transparent');
            pathCollider.setAttribute('stroke', 'transparent');
            pathCollider.setAttribute('id', 'line-collider');
            pathCollider.setAttribute('style', 'cursor: pointer;');
            lineGroup.appendChild(pathCollider)

            const startCircle = document.createElementNS('http://www.w3.org/2000/svg', 'circle');
            startCircle.setAttribute('cx', startLineCoords.x);
            startCircle.setAttribute('cy', startLineCoords.y);

            startCircle.setAttribute('r', '5');
            startCircle.setAttribute('fill', 'black');
            startCircle.setAttribute('id', newLinePathId.value + '_circle-line_' + 0);
            startCircle.setAttribute('class', 'move-point_' + newLinePathId.value);
            startCircle.setAttribute('style', 'display: none;');
            startCircle.addEventListener('mousedown', function (e) {
              lineProjectingFlag.value = true
              lineProjectingPoint.value = e.target
            })

            lineGroup.appendChild(startCircle)

            const endCircle = document.createElementNS('http://www.w3.org/2000/svg', 'circle');
            endCircle.setAttribute('cx', e.offsetX);
            endCircle.setAttribute('cy', e.offsetY);

            endCircle.setAttribute('r', '5');
            endCircle.setAttribute('fill', 'black');
            endCircle.setAttribute('id', newLinePathId.value + '_circle-line_' + 1);
            endCircle.setAttribute('class', 'move-point_' + newLinePathId.value);
            endCircle.setAttribute('style', 'display: none;');
            endCircle.addEventListener('mousedown', function (e) {
              lineProjectingFlag.value = true
              lineProjectingPoint.value = e.target
            })

            lineGroup.appendChild(endCircle)

            selectionOfLine(e)
            newLinePathId.value = null
          } 
          else if (nowDrawingCurveLine.value && newCurveLinePathId.value) {
            const currentGroup = schemaContainer.getElementById(newCurveLinePathId.value + '_group')

            let startLinePointCoords = []

            startLinePointCoords.push({ x: startCurveLineDrawingCoords.value.x, y: startCurveLineDrawingCoords.value.y })

            for (let i = 1; i < 5; i++) {
              const newCircle = document.createElementNS('http://www.w3.org/2000/svg', 'circle');
              newCircle.setAttribute('cx', startCurveLineDrawingCoords.value.x + (e.offsetX - startCurveLineDrawingCoords.value.x) / 4 * i);
              newCircle.setAttribute('cy', startCurveLineDrawingCoords.value.y + (e.offsetY - startCurveLineDrawingCoords.value.y) / 4 * i);
              newCircle.setAttribute('r', '5');
              newCircle.setAttribute('fill', 'black');
              newCircle.setAttribute('id', newCurveLinePathId.value + '_circle-curve-line_' + i);
              newCircle.setAttribute('class', 'curve-move-point_' + newCurveLinePathId.value);
              newCircle.setAttribute('style', 'display: none;');

              startLinePointCoords.push({ x: startCurveLineDrawingCoords.value.x + (e.offsetX - startCurveLineDrawingCoords.value.x) / 4 * i, y: startCurveLineDrawingCoords.value.y + (e.offsetY - startCurveLineDrawingCoords.value.y) / 4 * i })

              newCircle.addEventListener('mousedown', function (e) {
                curveProjectingFlag.value = true
                curveProjectingPoint.value = e.target
              })

              document.getElementById(newCurveLinePathId.value + '_circle-curve-line_' + 0).addEventListener('mousedown', function (e) {
                curveProjectingFlag.value = true
                curveProjectingPoint.value = e.target
              })

              currentGroup.appendChild(newCircle)
            }

            const firstStartLine = document.createElementNS('http://www.w3.org/2000/svg', 'line');

            firstStartLine.setAttribute('x1', startLinePointCoords[0].x);
            firstStartLine.setAttribute('y1', startLinePointCoords[0].y);
            firstStartLine.setAttribute('x2', startLinePointCoords[1].x);
            firstStartLine.setAttribute('y2', startLinePointCoords[1].y);

            firstStartLine.setAttribute('stroke', 'none');
            firstStartLine.setAttribute('stroke-width', '2');
            firstStartLine.setAttribute('id', newCurveLinePathId.value + '_start-line_0');

            currentGroup.appendChild(firstStartLine)

            const secondStartLine = document.createElementNS('http://www.w3.org/2000/svg', 'line');

            secondStartLine.setAttribute('x1', startLinePointCoords[3].x);
            secondStartLine.setAttribute('y1', startLinePointCoords[3].y);
            secondStartLine.setAttribute('x2', startLinePointCoords[4].x);
            secondStartLine.setAttribute('y2', startLinePointCoords[4].y);

            secondStartLine.setAttribute('stroke', 'none');
            secondStartLine.setAttribute('stroke-width', '2');
            secondStartLine.setAttribute('id', newCurveLinePathId.value + '_start-line_1');

            currentGroup.appendChild(secondStartLine)

            console.log('startLinePointCoords -------- ', startLinePointCoords)

            // add collider to curve line
            const pathCollider = document.createElementNS('http://www.w3.org/2000/svg', 'rect');
            pathCollider.setAttribute('x', currentGroup.getBBox().x - 5);
            pathCollider.setAttribute('y', currentGroup.getBBox().y - 10);
            pathCollider.setAttribute('width', currentGroup.getBBox().width + 10);
            pathCollider.setAttribute('height', currentGroup.getBBox().height + 20);
            pathCollider.setAttribute('fill', 'transparent');
            pathCollider.setAttribute('stroke', 'transparent');
            pathCollider.setAttribute('id', 'line-collider');
            pathCollider.setAttribute('style', 'cursor: pointer;');
            currentGroup.insertBefore(pathCollider, currentGroup.firstChild);

            selectionOfCurveLine(e)

            newCurveLinePathId.value = null
            nowDrawingCurveLine.value = false
          }
          else if (nowDrawingBrokenLine.value && newBrokenLinePathId.value) {
            const currentGroup = schemaContainer.getElementById(newBrokenLinePathId.value + '_group')

            let startLinePointCoords = []
            startLinePointCoords.push({ x: startBrokenLineDrawingCoords.value.x, y: startBrokenLineDrawingCoords.value.y })


            for (let i = 1; i < 5; i++) {
              const newCircle = document.createElementNS('http://www.w3.org/2000/svg', 'circle');
              newCircle.setAttribute('cx', startBrokenLineDrawingCoords.value.x + (e.offsetX - startBrokenLineDrawingCoords.value.x) / 4 * i);
              newCircle.setAttribute('cy', startBrokenLineDrawingCoords.value.y + (e.offsetY - startBrokenLineDrawingCoords.value.y) / 4 * i);
              newCircle.setAttribute('r', '5');
              newCircle.setAttribute('fill', 'black');
              newCircle.setAttribute('id', newBrokenLinePathId.value + '_circle-broken-line_' + i);
              newCircle.setAttribute('class', 'broken-move-point_' + newBrokenLinePathId.value);
              newCircle.setAttribute('style', 'display: none;');

              startLinePointCoords.push({ x: startBrokenLineDrawingCoords.value.x + (e.offsetX - startBrokenLineDrawingCoords.value.x) / 4 * i, y: startBrokenLineDrawingCoords.value.y + (e.offsetY - startBrokenLineDrawingCoords.value.y) / 4 * i })

              newCircle.addEventListener('mousedown', function (e) {
                brokenProjectingFlag.value = true
                brokenProjectingPoint.value = e.target
              })

              document.getElementById(newBrokenLinePathId.value + '_circle-broken-line_' + 0).addEventListener('mousedown', function (e) {
                brokenProjectingFlag.value = true
                brokenProjectingPoint.value = e.target
              })

              currentGroup.appendChild(newCircle)
            }

            const firstStartLine = document.createElementNS('http://www.w3.org/2000/svg', 'line');

            firstStartLine.setAttribute('x1', startLinePointCoords[0].x);
            firstStartLine.setAttribute('y1', startLinePointCoords[0].y);
            firstStartLine.setAttribute('x2', startLinePointCoords[1].x);
            firstStartLine.setAttribute('y2', startLinePointCoords[1].y);

            firstStartLine.setAttribute('stroke', 'none');
            firstStartLine.setAttribute('stroke-width', '2');
            firstStartLine.setAttribute('id', newBrokenLinePathId.value + '_start-line_0');

            currentGroup.appendChild(firstStartLine)

            const secondStartLine = document.createElementNS('http://www.w3.org/2000/svg', 'line');

            secondStartLine.setAttribute('x1', startLinePointCoords[3].x);
            secondStartLine.setAttribute('y1', startLinePointCoords[3].y);
            secondStartLine.setAttribute('x2', startLinePointCoords[4].x);
            secondStartLine.setAttribute('y2', startLinePointCoords[4].y);

            secondStartLine.setAttribute('stroke', 'none');
            secondStartLine.setAttribute('stroke-width', '2');
            secondStartLine.setAttribute('id', newBrokenLinePathId.value + '_start-line_1');

            currentGroup.appendChild(secondStartLine)

            console.log('startLinePointCoords -------- ', startLinePointCoords)
            const pathCollider = document.createElementNS('http://www.w3.org/2000/svg', 'rect');
            pathCollider.setAttribute('x', currentGroup.getBBox().x - 5);
            pathCollider.setAttribute('y', currentGroup.getBBox().y - 10);
            pathCollider.setAttribute('width', currentGroup.getBBox().width + 10);
            pathCollider.setAttribute('height', currentGroup.getBBox().height + 20);
            pathCollider.setAttribute('fill', 'transparent');
            pathCollider.setAttribute('stroke', 'transparent');
            pathCollider.setAttribute('id', 'line-collider');
            pathCollider.setAttribute('style', 'cursor: pointer;');
            currentGroup.insertBefore(pathCollider, currentGroup.firstChild);

            selectionOfBrokenLine(e)

            newBrokenLinePathId.value = null
            nowDrawingBrokenLine.value = false
          }
          else if (nowDrawingSinusoidLine.value && newSinusoidLinePathId.value) {
            const currentGroup = schemaContainer.getElementById(newSinusoidLinePathId.value + '_group')

            const pathCollider = document.createElementNS('http://www.w3.org/2000/svg', 'rect');
            pathCollider.setAttribute('x', currentGroup.getBBox().x - 5);
            pathCollider.setAttribute('y', currentGroup.getBBox().y - 10);
            pathCollider.setAttribute('width', currentGroup.getBBox().width + 10);
            pathCollider.setAttribute('height', currentGroup.getBBox().height + 20);
            pathCollider.setAttribute('fill', 'transparent');
            pathCollider.setAttribute('stroke', 'transparent');
            pathCollider.setAttribute('id', 'line-collider');
            pathCollider.setAttribute('style', 'cursor: pointer;');
            currentGroup.insertBefore(pathCollider, currentGroup.firstChild);

            selectionOfSinusoidLine(e)
            newSinusoidLinePathId.value = null
          }

          if (curveProjectingFlag.value) {
            curveProjectingFlag.value = false
            const pathId = curveProjectingPoint.value.id.split('_')[0]
            
            const currentLine = schemaContainer.getElementById(pathId)
            const currentGroup = schemaContainer.getElementById(pathId + '_group')
            currentLine.setAttribute('d', '')

            const allCircles = schemaContainer.querySelectorAll('.curve-move-point_' + pathId)
            console.log('allCircles -------- ', allCircles)

            let d = `M${allCircles[0].getAttribute('cx')} ${allCircles[0].getAttribute('cy')} `
            for (let i = 1; i < allCircles.length - 1; i+= 2) {
              d += `Q${+allCircles[i].getAttribute('cx')},${+allCircles[i].getAttribute('cy')} ${+allCircles[i + 1].getAttribute('cx')},${+allCircles[i + 1].getAttribute('cy')} `
            }

            const firstStartLine = schemaContainer.getElementById(pathId + '_start-line_0')
            const secondStartLine = schemaContainer.getElementById(pathId + '_start-line_1')

            firstStartLine.setAttribute('x1', allCircles[0].getAttribute('cx'));
            firstStartLine.setAttribute('y1', allCircles[0].getAttribute('cy'));
            firstStartLine.setAttribute('x2', allCircles[1].getAttribute('cx'));
            firstStartLine.setAttribute('y2', allCircles[1].getAttribute('cy'));

            secondStartLine.setAttribute('x1', allCircles[3].getAttribute('cx'));
            secondStartLine.setAttribute('y1', allCircles[3].getAttribute('cy'));
            secondStartLine.setAttribute('x2', allCircles[4].getAttribute('cx'));
            secondStartLine.setAttribute('y2', allCircles[4].getAttribute('cy'));

            currentLine.setAttribute('d', d)

            const pathCollider = currentGroup.getElementsByTagName('rect')[0]
            pathCollider.setAttribute('x', currentLine.getBBox().x - 5);
            pathCollider.setAttribute('y', currentLine.getBBox().y - 10);
            pathCollider.setAttribute('width', currentLine.getBBox().width + 10);
            pathCollider.setAttribute('height', currentLine.getBBox().height + 20);
          }
          if (brokenProjectingFlag.value) {
            brokenProjectingFlag.value = false
            const pathId = brokenProjectingPoint.value.id.split('_')[0]
            
            const currentLine = schemaContainer.getElementById(pathId)
            const currentGroup = schemaContainer.getElementById(pathId + '_group')
            currentLine.setAttribute('d', '')

            const allCircles = schemaContainer.querySelectorAll('.broken-move-point_' + pathId)

            let d = `M${allCircles[0].getAttribute('cx')} ${allCircles[0].getAttribute('cy')} `
            for (let i = 1; i < allCircles.length - 1; i+= 2) {
              d += `L${+allCircles[i].getAttribute('cx')},${+allCircles[i].getAttribute('cy')} ${+allCircles[i + 1].getAttribute('cx')},${+allCircles[i + 1].getAttribute('cy')} `
            }

            const firstStartLine = schemaContainer.getElementById(pathId + '_start-line_0')
            const secondStartLine = schemaContainer.getElementById(pathId + '_start-line_1')

            firstStartLine.setAttribute('x1', allCircles[0].getAttribute('cx'));
            firstStartLine.setAttribute('y1', allCircles[0].getAttribute('cy'));
            firstStartLine.setAttribute('x2', allCircles[1].getAttribute('cx'));
            firstStartLine.setAttribute('y2', allCircles[1].getAttribute('cy'));

            secondStartLine.setAttribute('x1', allCircles[3].getAttribute('cx'));
            secondStartLine.setAttribute('y1', allCircles[3].getAttribute('cy'));
            secondStartLine.setAttribute('x2', allCircles[4].getAttribute('cx'));
            secondStartLine.setAttribute('y2', allCircles[4].getAttribute('cy'));

            currentLine.setAttribute('d', d)

            const pathCollider = currentGroup.getElementsByTagName('rect')[0]
            pathCollider.setAttribute('x', currentLine.getBBox().x - 5);
            pathCollider.setAttribute('y', currentLine.getBBox().y - 10);
            pathCollider.setAttribute('width', currentLine.getBBox().width + 10);
            pathCollider.setAttribute('height', currentLine.getBBox().height + 20);
          }
          if (lineProjectingFlag.value) {
            lineProjectingFlag.value = false
            const pathId = lineProjectingPoint.value.id.split('_')[0]
            
            const currentLine = schemaContainer.getElementById(pathId)
            const currentGroup = schemaContainer.getElementById(pathId + '_group')
            currentLine.setAttribute('d', '')

            const allCircles = schemaContainer.querySelectorAll('.move-point_' + pathId)

            let d = `M${allCircles[0].getAttribute('cx')} ${allCircles[0].getAttribute('cy')} `
            for (let i = 1; i < allCircles.length; i++) {
              d += `L${+allCircles[i].getAttribute('cx')},${+allCircles[i].getAttribute('cy')} `
            }

            currentLine.setAttribute('d', d)

            const pathCollider = currentGroup.getElementsByTagName('rect')[0]
            pathCollider.setAttribute('x', currentLine.getBBox().x - 5);
            pathCollider.setAttribute('y', currentLine.getBBox().y - 10);
            pathCollider.setAttribute('width', currentLine.getBBox().width + 10);
            pathCollider.setAttribute('height', currentLine.getBBox().height + 20);
          }
        })
      }

      //text field variables
      const startAddingTextFieldFlag = ref(false)
      const newTextFieldId = ref(null)
      const newTextFieldValue = ref(null)
      const newTextFieldCoords = ref({ x: 0, y: 0 })
      const startInputTextFieldFlag = ref(false)

      const addTextField = () => {
        const schemaContainer = document.getElementById('schema-container').querySelector('svg')
        newTextFieldId.value = new Date().getTime()
        console.log('newTextFieldId.value -------- ', newTextFieldId.value)

        schemaContainer.addEventListener('mousedown', function (e) {
          if (startAddingTextFieldFlag.value) {
            console.log('startAddingTextFieldFlag -------- ', startAddingTextFieldFlag.value)
            startInputTextFieldFlag.value = true
            newTextFieldCoords.value = { x: e.offsetX, y: e.offsetY }
            console.log('newTextFieldCoords.value -------- ', newTextFieldCoords.value)
          }
        })
      }

      const addTextFieldToCanvas = () => {
        startInputTextFieldFlag.value = false
        const schemaContainer = document.getElementById('schema-container').querySelector('svg')
        const newTextField = document.createElementNS('http://www.w3.org/2000/svg', 'text');
        newTextField.setAttribute('x', newTextFieldCoords.value.x);
        newTextField.setAttribute('y', newTextFieldCoords.value.y);
        newTextField.setAttribute('fill', 'black');
        newTextField.setAttribute('font-size', '20');
        newTextField.setAttribute('style', 'cursor: pointer;');
        newTextField.setAttribute('id', 'text-field-' + newTextFieldId.value);
        newTextField.textContent = newTextFieldValue.value;
        selectionOfText(newTextField)
        schemaContainer.appendChild(newTextField)
      }

      //sections
      const nowDrawingSquareSection = ref(false)
      const nowDrawingCircleSection = ref(false)
      const nowDrawingTriangleSection = ref(false)

      const newSectionId = ref(null)

      const squareSectionStartCoords = ref({ x: 0, y: 0 })
      const circleSectionStartCoords = ref({ x: 0, y: 0 })
      const triangleSectionStartCoords = ref({ x: 0, y: 0 })

      const squareSectionProcess = ref(false)
      const circleSectionProcess = ref(false)
      const triangleSectionProcess = ref(false)

      const addSections = () => {
        const schemaContainer = document.getElementById('schema-container').querySelector('svg')

        schemaContainer.addEventListener('mousedown', function (e) {
          if (nowDrawingSquareSection.value) {
            newSectionId.value = new Date().getTime()
            squareSectionStartCoords.value = { x: e.offsetX, y: e.offsetY }
            squareSectionProcess.value = true
          }
          else if (nowDrawingCircleSection.value) {
            newSectionId.value = new Date().getTime()
            circleSectionStartCoords.value = { x: e.offsetX, y: e.offsetY }
            circleSectionProcess.value = true
          }
          else if (nowDrawingTriangleSection.value) {
            newSectionId.value = new Date().getTime()
            triangleSectionStartCoords.value = { x: e.offsetX, y: e.offsetY }
            triangleSectionProcess.value = true
          }
        })

        schemaContainer.addEventListener('mousemove', function (e) {
          if (squareSectionProcess.value) {
            const currentSection = schemaContainer.getElementById(newSectionId.value)

            if (currentSection) {
              if (e.offsetX - squareSectionStartCoords.value.x < 0) {
                currentSection.setAttribute('x', e.offsetX)
                currentSection.setAttribute('width', Math.abs(e.offsetX - squareSectionStartCoords.value.x))
              }
              else {
                currentSection.setAttribute('x', squareSectionStartCoords.value.x)
                currentSection.setAttribute('width', Math.abs(e.offsetX - squareSectionStartCoords.value.x))
              }

              if (e.offsetY - squareSectionStartCoords.value.y < 0) {
                currentSection.setAttribute('y', e.offsetY)
                currentSection.setAttribute('height', Math.abs(e.offsetY - squareSectionStartCoords.value.y))
              }
              else {
                currentSection.setAttribute('y', squareSectionStartCoords.value.y)
                currentSection.setAttribute('height', Math.abs(e.offsetY - squareSectionStartCoords.value.y))
              }
            } else {
              const newSection = document.createElementNS('http://www.w3.org/2000/svg', 'rect');
              newSection.setAttribute('x', squareSectionStartCoords.value.x);
              newSection.setAttribute('y', squareSectionStartCoords.value.y);
              newSection.setAttribute('width', e.offsetX - squareSectionStartCoords.value.x);
              newSection.setAttribute('height', e.offsetY - squareSectionStartCoords.value.y);
              newSection.setAttribute('style', 'cursor: pointer;');
              newSection.setAttribute('fill', 'rgba(0,0,0,0.15)');
              newSection.setAttribute('stroke', 'black');
              newSection.setAttribute('stroke-width', '2');
              newSection.setAttribute('id', newSectionId.value);
              newSection.setAttribute('class', 'section')

              schemaContainer.appendChild(newSection)
              selectionOfSection(newSection)
            }
          }
          else if (circleSectionProcess.value) {
            const currentSection = schemaContainer.getElementById(newSectionId.value)

            const currentSectionCoords = {
              cx: circleSectionStartCoords.value.x + (e.offsetX - circleSectionStartCoords.value.x) / 2,
              cy: circleSectionStartCoords.value.y + (e.offsetY - circleSectionStartCoords.value.y) / 2,
              rx: Math.abs(e.offsetX - circleSectionStartCoords.value.x),
              ry: Math.abs(e.offsetY - circleSectionStartCoords.value.y)  
            }

            if (currentSection) {
              currentSection.setAttribute('cx', currentSectionCoords.cx)
              currentSection.setAttribute('cy', currentSectionCoords.cy)
              currentSection.setAttribute('rx', currentSectionCoords.rx)
              currentSection.setAttribute('ry', currentSectionCoords.ry)
            } else {
              const newSection = document.createElementNS('http://www.w3.org/2000/svg', 'ellipse');
              newSection.setAttribute('cx', currentSectionCoords.cx);
              newSection.setAttribute('cy', currentSectionCoords.cy);
              newSection.setAttribute('rx', currentSectionCoords.rx);
              newSection.setAttribute('ry', currentSectionCoords.ry);
              newSection.setAttribute('style', 'cursor: pointer;');
              newSection.setAttribute('fill', 'rgba(0,0,0,0.15)');
              newSection.setAttribute('stroke', 'black');
              newSection.setAttribute('stroke-width', '2');
              newSection.setAttribute('id', newSectionId.value);
              newSection.setAttribute('class', 'section')

              schemaContainer.appendChild(newSection)
              selectionOfSection(newSection)
            }

          }
          else if (triangleSectionProcess.value) {
            const currentSection = schemaContainer.getElementById(newSectionId.value)
            const currentSectionCoords = {
              x1: triangleSectionStartCoords.value.x,
              y1: triangleSectionStartCoords.value.y,
              x2: e.offsetX,
              y2: e.offsetY,
              x3: triangleSectionStartCoords.value.x + (e.offsetX - triangleSectionStartCoords.value.x) * 2,
              y3: triangleSectionStartCoords.value.y
            }

            // form triangle
            if (currentSection) {
              currentSection.setAttribute('points', `${currentSectionCoords.x1},${currentSectionCoords.y1} ${currentSectionCoords.x2},${currentSectionCoords.y2} ${currentSectionCoords.x3},${currentSectionCoords.y3}`)
            } else {
              const newSection = document.createElementNS('http://www.w3.org/2000/svg', 'polygon');
              newSection.setAttribute('points', `${currentSectionCoords.x1},${currentSectionCoords.y1} ${currentSectionCoords.x2},${currentSectionCoords.y2} ${currentSectionCoords.x3},${currentSectionCoords.y3}`)
              newSection.setAttribute('style', 'cursor: pointer;');
              newSection.setAttribute('fill', 'rgba(0,0,0,0.15)');
              newSection.setAttribute('stroke', 'black');
              newSection.setAttribute('stroke-width', '2');
              newSection.setAttribute('id', newSectionId.value);
              newSection.setAttribute('class', 'section')

              schemaContainer.appendChild(newSection)
              selectionOfSection(newSection)
            }
          }

          //   currentSection.setAttribute('points', `${currentSectionCoords.x1},${currentSectionCoords.y1} ${currentSectionCoords.x2},${currentSectionCoords.y2} ${currentSectionCoords.x3},${currentSectionCoords.y3}`)
        })

        schemaContainer.addEventListener('mouseup', function (e) {
          if (squareSectionProcess.value) {
            squareSectionProcess.value = false
          }
          else if (circleSectionProcess.value) {
            circleSectionProcess.value = false
          }
          else if (triangleSectionProcess.value) {
            triangleSectionProcess.value = false
          }
        })
      }

      //section selection logic
      const sectionSelectedFlag = ref(false)
      const currentSectionSettings = ref({
        fill: 'rgba(0,0,0,0.15)',
        stroke: 'black',
        strokeWidth: 2
      })

      const selectedSectionLastStroke = ref(null)

      const changeSectionFill = (fill, texture = false) => {
        const section = selectedElement.value

        if (!texture) {
          if (currentSectionSettings.value.fill.includes('url')) {
            console.log('fill -------- ', fill)
            fill = parseFillColorToTexture(fill)
          }
        }

        currentSectionSettings.value.fill = fill
        section.setAttribute('fill', fill)

        selectedElementLastColor.value = fill
      }

      const changeSectionStroke = (stroke) => {
        const section = selectedElement.value
        currentSectionSettings.value.stroke = stroke
        section.setAttribute('stroke', stroke)

        selectedSectionLastStroke.value = stroke
      }

      const changeSectionStrokeWidth = (strokeWidth) => {
        const section = selectedElement.value
        currentSectionSettings.value.strokeWidth = strokeWidth
        section.setAttribute('stroke-width', strokeWidth)
      }

      const selectionOfSection = (section) => {
        section.addEventListener('click', function (e) {
          if (selectedElement.value) {
            if (selectedElementType.value == 'broken-line') {
              stopSelectionOfBrokenLine()
            }
            else if (selectedElementType.value == 'curve-line') {
              stopSelectionOfCurveLine()
            }
            else if (selectedElementType.value == 'line') {
              stopSelectionOfLine()
            }
            else if (selectedElementType.value == 'sinusoid-line') {
              stopSelectionOfSinusoidLine()
            }
            else if (selectedElementType.value == 'text') {
              stopSelectionOfText()
            }
          }
          console.log('e.target.tagName -------- ', e.target.tagName)
          selectedElement.value = e.target
          if (selectedElement.value.getAttribute('fill') != `rgba(255, 0, 0, 0.5)`) {
            selectedElementLastColor.value = selectedElement.value.getAttribute('fill')
          }

          if (selectedElement.value.getAttribute('stroke') != `red`) {
            selectedSectionLastStroke.value = selectedElement.value.getAttribute('stroke')
          } 

          sectionSelectedFlag.value = true
          currentSectionSettings.value = {
            fill: selectedElement.value.getAttribute('fill'),
            stroke: selectedElement.value.getAttribute('stroke'),
            strokeWidth: selectedElement.value.getAttribute('stroke-width')
          }
          selectedElement.value.setAttribute('fill', 'rgba(255, 0, 0, 0.5)')
          selectedElement.value.setAttribute('stroke', 'red')

          selectedElementType.value = 'section'

          selectedElement.value.addEventListener('mousedown', function (e) {
            if (selectedElement.value) {
              elementMoveFlag.value = true
              selectedElementType.value == 'section'
            }
          })
        })
      }

      const stopSelectionOfSection = () => {
        selectedElement.value.setAttribute('fill', selectedElementLastColor.value)
        selectedElement.value.setAttribute('stroke', selectedSectionLastStroke.value)
        sectionSelectedFlag.value = false
      }

      const deleteSection = () => {
        if (selectedElement.value) {
          const schemaContainer = document.getElementById('schema-container').querySelector('svg')
          schemaContainer.removeChild(selectedElement.value)
          selectedElement.value = null
          sectionSelectedFlag.value = false
        }
      }

      //end of section selection logic

      //element selection logic
      const selectedElement = ref(null)
      const selectedElementLastColor = ref(null)
      const selectedElementFlag = ref(false)
      const selectedElementType = ref('')

      const elementMoveFlag = ref(false)

      const addSelectionOfElements = () => {
        const schemaContainer = document.getElementById('schema-container').querySelector('svg')

        //element movement logic
        schemaContainer.addEventListener('mousemove', function (e) {
          if (elementMoveFlag.value) {
            if (selectedElementType.value == 'line') {
              if (selectedElement.value) {
                if (e.target.tagName === 'circle') {
                  return
                }
                const path = selectedElement.value.getElementsByTagName('path')[0]
                const collider = selectedElement.value.getElementsByTagName('rect')[0]
                const schemaContainer = document.getElementById('schema-container').querySelector('svg')

                const schemaBounding = schemaContainer.getBoundingClientRect()

                const colliderCenterX = +collider.getAttribute('x') + +collider.getAttribute('width') / 2
                const colliderCenterY = +collider.getAttribute('y') + +collider.getAttribute('height') / 2
                
                const startX = +path.getAttribute('d').split('M')[1].split(' ')[0]
                const startY = +path.getAttribute('d').split('M')[1].split(' ')[1]

                const endX = +path.getAttribute('d').split('L')[1].split(' ')[0]
                const endY = +path.getAttribute('d').split('L')[1].split(' ')[1]

                // const dX = e.offsetX - ((startX + endX) / 2) + 100
                // const dY = e.offsetY - ((startY + endY) / 2) + 100

                const dX = e.offsetX - colliderCenterX
                const dY = e.offsetY - colliderCenterY

                const currentTransform = selectedElement.value.getAttribute('transform')
                if (currentTransform?.includes('rotate')) {
                  const currentRotate = currentTransform.split('rotate(')[1].split(')')[0]
                  selectedElement.value.setAttribute('transform', `translate(${dX}, ${dY}) rotate(${currentRotate})`)
                } else {
                  selectedElement.value.setAttribute('transform', `translate(${dX}, ${dY})`)
                }

                console.log('moving line')
                // selectedElement.value.setAttribute('transform', `translate(${dX}, ${dY})`)
              }
            }
            else if (selectedElementType.value == 'curve-line') {
              if (selectedElement.value) {
                if (e.target.tagName === 'circle') {
                  return
                }
                const collider = selectedElement.value.getElementsByTagName('rect')[0]

                const colliderCenterX = +collider.getAttribute('x') + +collider.getAttribute('width') / 2
                const colliderCenterY = +collider.getAttribute('y') + +collider.getAttribute('height') / 2

                const path = selectedElement.value.getElementsByTagName('path')[0]
                const allCircles = selectedElement.value.getElementsByTagName('circle')

                const dX = e.offsetX - colliderCenterX
                const dY = e.offsetY - colliderCenterY

                // selectedElement.value.setAttribute('transform', `translate(${dX}, ${dY})`)
                const currentTransform = selectedElement.value.getAttribute('transform')
                if (currentTransform?.includes('rotate')) {
                  const currentRotate = currentTransform.split('rotate(')[1].split(')')[0]
                  selectedElement.value.setAttribute('transform', `translate(${dX}, ${dY}) rotate(${currentRotate})`)
                } else {
                  selectedElement.value.setAttribute('transform', `translate(${dX}, ${dY})`)
                }

                console.log('moving curve-line')
              }
            }
            else if (selectedElementType.value == 'broken-line') {
              if (selectedElement.value) {
                if (e.target.tagName === 'circle') {
                  return
                }
                const collider = selectedElement.value.getElementsByTagName('rect')[0]

                const colliderCenterX = +collider.getAttribute('x') + +collider.getAttribute('width') / 2
                const colliderCenterY = +collider.getAttribute('y') + +collider.getAttribute('height') / 2

                const path = selectedElement.value.getElementsByTagName('path')[0]
                const allCircles = selectedElement.value.getElementsByTagName('circle')

                const dX = e.offsetX - colliderCenterX
                const dY = e.offsetY - colliderCenterY

                // selectedElement.value.setAttribute('transform', `translate(${dX}, ${dY})`)
                const currentTransform = selectedElement.value.getAttribute('transform')
                if (currentTransform?.includes('rotate')) {
                  const currentRotate = currentTransform.split('rotate(')[1].split(')')[0]
                  selectedElement.value.setAttribute('transform', `translate(${dX}, ${dY}) rotate(${currentRotate})`)
                } else {
                  selectedElement.value.setAttribute('transform', `translate(${dX}, ${dY})`)
                }

                console.log('broken-line')
              }
            }
            else if (selectedElementType.value == 'sinusoid-line') {
              if (selectedElement.value) {
                const path = selectedElement.value.getElementsByTagName('path')[0]

                const collider = selectedElement.value.getElementsByTagName('rect')[0]

                const colliderCenterX = +collider.getAttribute('x') + +collider.getAttribute('width') / 2
                const colliderCenterY = +collider.getAttribute('y') + +collider.getAttribute('height') / 2
                
                const startX = +path.getAttribute('d').split('M')[1].split(' ')[0]
                const startY = +path.getAttribute('d').split('M')[1].split(' ')[1]

                const endX = +path.getAttribute('d').split('L')[1].split(' ')[0]
                const endY = +path.getAttribute('d').split('L')[1].split(' ')[1]

                const dX = e.offsetX - colliderCenterX
                const dY = e.offsetY - colliderCenterY

                // selectedElement.value.setAttribute('transform', `translate(${dX}, ${dY})`)
                const currentTransform = selectedElement.value.getAttribute('transform')
                if (currentTransform?.includes('rotate')) {
                  const currentRotate = currentTransform.split('rotate(')[1].split(')')[0]
                  selectedElement.value.setAttribute('transform', `translate(${dX}, ${dY}) rotate(${currentRotate})`)
                } else {
                  selectedElement.value.setAttribute('transform', `translate(${dX}, ${dY})`)
                }

                console.log('sinusoid-line')
              }
            }
            else if (selectedElementType.value == 'text') {
              if (selectedElement.value) {
                const dX = e.offsetX - (+selectedElement.value.getAttribute('x'))
                const dY = e.offsetY - (+selectedElement.value.getAttribute('y'))

                selectedElement.value.setAttribute('transform', `translate(${dX}, ${dY})`)

                console.log('text')
              }
            }
            else if (selectedElementType.value == 'section') {
              if (selectedElement.value) {
                const colliderCenterX = selectedElement.value.getBBox().x + selectedElement.value.getBBox().width / 2
                const colliderCenterY = selectedElement.value.getBBox().y + selectedElement.value.getBBox().height / 2

                const dX = e.offsetX - colliderCenterX
                const dY = e.offsetY - colliderCenterY

                selectedElement.value.setAttribute('transform', `translate(${dX}, ${dY})`)

                console.log('section')
              }
            }

          }
        })

        schemaContainer.addEventListener('mouseup', function (e) {
          if (selectedElement.value) {
            elementMoveFlag.value = false
          }
        })  
      }

      //line selection logic
      const lineSelectedFlag = ref(false)
      const currentLineSettings = ref({
        strokeStyle: 'dotted',
        startStyle: 'none',
        endStyle: 'none',
        strokeWeight: 2
      })

      const changeLineStrokeStyle = (strokeStyle) => {
        const path = selectedElement.value.getElementsByTagName('path')[0]
        currentLineSettings.value.strokeStyle = strokeStyle

        if (strokeStyle == 'solid') {
          path.setAttribute('stroke-dasharray', 'none')
          currentLineSettings.value.strokeStyle = 'solid'
        }
        else if (strokeStyle == 'dotted') {
          path.setAttribute('stroke-dasharray', '5,5')
          currentLineSettings.value.strokeStyle = 'dotted'
        }
      }

      const changeLineStartStyle = (startStyle) => {
        const path = selectedElement.value.getElementsByTagName('path')[0]
        const startLine = selectedElement.value.getElementsByTagName('line')[0]

        
        if (startStyle == 'none') {
          startLine.setAttribute('marker-start', 'none')
          currentLineSettings.value.startStyle = 'none'
        }
        else if (startStyle == 'arrow') {
          startLine.setAttribute('marker-start', 'url(#arrow-start)')
          currentLineSettings.value.startStyle = 'arrow'
        }
      }

      const changeLineEndStyle = (endStyle) => {
        const path = selectedElement.value.getElementsByTagName('path')[0]
        const startLine = selectedElement.value.getElementsByTagName('line')[0]

        if (endStyle == 'none') {
          startLine.setAttribute('marker-end', 'none')
          currentLineSettings.value.endStyle = 'none'
        }
        else if (endStyle == 'arrow') {
          startLine.setAttribute('marker-end', 'url(#arrow-end)')
          currentLineSettings.value.endStyle = 'arrow'
        }
      }

      const changeLineStrokeWeight = (strokeWeight) => {
        if (strokeWeight < 1) {
          return
        }
        const path = selectedElement.value.getElementsByTagName('path')[0]
        currentLineSettings.value.strokeWeight = strokeWeight
        path.setAttribute('stroke-width', strokeWeight)
      }

      const deleteLine = () => {
        const schemaContainer = document.getElementById('schema-container').querySelector('svg')
        schemaContainer.removeChild(selectedElement.value)
        selectedElement.value = null
        lineSelectedFlag.value = false
      }

      const changeLineRotate = (angle) => {
        const currentTransformD = selectedElement.value.getAttribute('transform')

        if (currentTransformD?.includes('translate')) {
          const currentTranslate = currentTransformD ? currentTransformD.split('translate(')[1].split(')')[0].split(',') : [0, 0]
          selectedElement.value.setAttribute('transform', `translate(${currentTranslate[0]}, ${currentTranslate[1]}) rotate(${angle}, ${selectedElement.value.getBBox().x + selectedElement.value.getBBox().width / 2}, ${selectedElement.value.getBBox().y + selectedElement.value.getBBox().height / 2})`)
        } else {
          selectedElement.value.setAttribute('transform', `rotate(${angle}, ${selectedElement.value.getBBox().x + selectedElement.value.getBBox().width / 2}, ${selectedElement.value.getBBox().y + selectedElement.value.getBBox().height / 2})`)
        }
        selectedComponentSettings.value.rotate = angle
        // const startLineRotate
      }

      const selectionOfLine = (e) => {
        const schemaContainer = document.getElementById('schema-container').querySelector('svg')
        schemaContainer.getElementById(newLinePathId.value + '_group').addEventListener('click', function (e) {
          if (selectedElement.value) {
            if (selectedElementType.value == 'broken-line') {
              stopSelectionOfBrokenLine()
            }
            else if (selectedElementType.value == 'curve-line') {
              stopSelectionOfCurveLine()
            }
            else if (selectedElementType.value == 'line') {
              stopSelectionOfLine()
            }
          }

          if (e.target.parentElement.tagName === 'g') {
            selectedElement.value = e.target.parentElement
            const path = selectedElement.value.getElementsByTagName('path')[0]
            const startLine = selectedElement.value.getElementsByTagName('line')[0]

            // add collider to line
            // const pathCollider = document.createElementNS('http://www.w3.org/2000/svg', 'rect');
            // pathCollider.setAttribute('x', path.getBBox().x);
            // pathCollider.setAttribute('y', path.getBBox().y);
            // pathCollider.setAttribute('width', path.getBBox().width);
            // pathCollider.setAttribute('height', path.getBBox().height);
            // pathCollider.setAttribute('fill', 'transparent');
            // pathCollider.setAttribute('stroke', 'red');
            // pathCollider.setAttribute('id', 'line-collider');
            // pathCollider.setAttribute('style', 'cursor: pointer;');
            // selectedElement.value.appendChild(pathCollider)

            const allCircles = selectedElement.value.getElementsByTagName('circle')

            allCircles.forEach(circle => {
              circle.setAttribute('style', 'display: block;')
            })

            currentLineSettings.value.strokeStyle = path.getAttribute('stroke-dasharray') ? 'dotted' : 'solid'
            currentLineSettings.value.startStyle = startLine.getAttribute('marker-start') ? 'arrow' : 'none'
            currentLineSettings.value.endStyle = startLine.getAttribute('marker-end') ? 'arrow' : 'none'
            currentLineSettings.value.strokeWeight = path.getAttribute('stroke-width')
            console.log('currentLineSettings.value -------- ', currentLineSettings.value)
            lineSelectedFlag.value = true
  
            const centerCircleX = (+path.getAttribute('d').split('M')[1].split(' ')[0] + +path.getAttribute('d').split('L')[1].split(' ')[0]) / 2
            const centerCircleY = (+path.getAttribute('d').split('M')[1].split(' ')[1] + +path.getAttribute('d').split('L')[1].split(' ')[1]) / 2

            const centerColliderX = path.getBBox().x + path.getBBox().width / 2
            const centerColliderY = path.getBBox().y + path.getBBox().height / 2
  
            const movePoint = document.createElementNS('http://www.w3.org/2000/svg', 'g');
  
            movePoint.innerHTML = `<path d="M8.48713 17.349C8.67986 17.5467 8.99641 17.5508 9.19418 17.3581L12.4169 14.2174C12.6147 14.0247 12.6188 13.7081 12.4261 13.5104C12.2333 13.3126 11.9168 13.3085 11.719 13.5012L8.85434 16.293L6.06263 13.4283C5.8699 13.2305 5.55334 13.2264 5.35558 13.4192C5.15782 13.6119 5.15373 13.9284 5.34646 14.1262L8.48713 17.349ZM9.4097 0.651037C9.21697 0.453272 8.90042 0.449188 8.70265 0.641916L5.47989 3.78259C5.28213 3.97531 5.27804 4.29187 5.47077 4.48963C5.6635 4.6874 5.98005 4.69148 6.17782 4.49876L9.0425 1.70705L11.8342 4.57172C12.0269 4.76949 12.3435 4.77357 12.5413 4.58084C12.739 4.38812 12.7431 4.07156 12.5504 3.8738L9.4097 0.651037ZM9.34517 17.0064L9.55157 1.00645L8.55166 0.993551L8.34526 16.9936L9.34517 17.0064Z" fill="black"/> <path d="M0.646447 8.69808C0.451184 8.89334 0.451184 9.20993 0.646447 9.40519L3.82843 12.5872C4.02369 12.7824 4.34027 12.7824 4.53553 12.5872C4.7308 12.3919 4.7308 12.0753 4.53553 11.8801L1.70711 9.05164L4.53553 6.22321C4.7308 6.02795 4.7308 5.71136 4.53553 5.5161C4.34027 5.32084 4.02369 5.32084 3.82843 5.5161L0.646447 8.69808ZM17.4568 9.40519C17.6521 9.20993 17.6521 8.89334 17.4568 8.69808L14.2748 5.5161C14.0796 5.32084 13.763 5.32084 13.5677 5.5161C13.3724 5.71136 13.3724 6.02795 13.5677 6.22321L16.3961 9.05164L13.5677 11.8801C13.3724 12.0753 13.3724 12.3919 13.5677 12.5872C13.763 12.7824 14.0796 12.7824 14.2748 12.5872L17.4568 9.40519ZM1 9.55164L17.1032 9.55164L17.1032 8.55164L1 8.55164L1 9.55164Z" fill="black"/>`
  
            movePoint.setAttribute('transform', `translate(${centerColliderX}, ${centerColliderY})`);
            movePoint.setAttribute('style', 'cursor: pointer;');
            movePoint.setAttribute('id', newBrokenLinePathId.value + '_move-point');
  
            // selectedElement.value.appendChild(movePoint)

            const pathCollider = selectedElement.value.getElementsByTagName('rect')[0]
            pathCollider.setAttribute('stroke', 'red');
  
            pathCollider.addEventListener('mousedown', function (e) {
              if (selectedElement.value) {
                elementMoveFlag.value = true
                selectedElementType.value == 'line'
              }
            })
  
            selectedElementLastColor.value = selectedElement.value.getElementsByTagName('path')[0].getAttribute('stroke')
            // selectedElement.value.getElementsByTagName('path')[0].setAttribute('stroke', 'red')
            selectedElementType.value = 'line'
          }
        })
      }

      const stopSelectionOfLine = () => {
        if (lineSelectedFlag.value) {
          // selectedElement.value.getElementsByTagName('path')[0].setAttribute('stroke', selectedElementLastColor.value)
          selectedElement.value.getElementsByTagName('g')[0]?.remove()

          selectedElement.value.getElementsByTagName('circle').forEach(circle => {
            circle.setAttribute('fill', 'black')
            circle.setAttribute('style', 'display: none;')
          })
  
          //remove collider
          selectedElement.value.getElementsByTagName('rect')[0].setAttribute('stroke', 'transparent')
  
          lineSelectedFlag.value = false
        }
      }
      //end of line selection logic


      //curve line selection logic
      const curveLineSelectedFlag = ref(false)
      const currentCurveLineSettings = ref({
        strokeStyle: 'dotted',
        startStyle: 'none',
        endStyle: 'none',
        strokeWeight: 2
      })

      const changeCurveLineStrokeStyle = (strokeStyle) => {
        const path = selectedElement.value.getElementsByTagName('path')[0]
        currentCurveLineSettings.value.strokeStyle = strokeStyle

        if (strokeStyle == 'solid') {
          path.setAttribute('stroke-dasharray', 'none')
          currentCurveLineSettings.value.strokeStyle = 'solid'
        }
        else if (strokeStyle == 'dotted') {
          path.setAttribute('stroke-dasharray', '5,5')
          currentCurveLineSettings.value.strokeStyle = 'dotted'
        }
      }

      const changeCurveLineStartStyle = (startStyle) => {
        const path = selectedElement.value.getElementsByTagName('path')[0]
        const startLine = selectedElement.value.getElementsByTagName('line')[0]

        
        if (startStyle == 'none') {
          startLine.setAttribute('marker-start', 'none')
          currentCurveLineSettings.value.startStyle = 'none'
        }
        else if (startStyle == 'arrow') {
          startLine.setAttribute('marker-start', 'url(#arrow-start)')
          currentCurveLineSettings.value.startStyle = 'arrow'
        }
      }

      const changeCurveLineEndStyle = (endStyle) => {
        const path = selectedElement.value.getElementsByTagName('path')[0]
        const startLine = selectedElement.value.getElementsByTagName('line')[1]

        if (endStyle == 'none') {
          startLine.setAttribute('marker-end', 'none')
          currentCurveLineSettings.value.endStyle = 'none'
        }
        else if (endStyle == 'arrow') {
          startLine.setAttribute('marker-end', 'url(#arrow-start)')
          currentCurveLineSettings.value.endStyle = 'arrow'
        }
      }

      const changeCurveLineStrokeWeight = (strokeWeight) => {
        if (strokeWeight < 1) {
          return
        }
        const path = selectedElement.value.getElementsByTagName('path')[0]
        currentCurveLineSettings.value.strokeWeight = strokeWeight
        path.setAttribute('stroke-width', strokeWeight)
      }

      const deleteCurveLine = () => {
        const schemaContainer = document.getElementById('schema-container').querySelector('svg')
        schemaContainer.removeChild(selectedElement.value)
        selectedElement.value = null
        curveLineSelectedFlag.value = false
      }

      const selectionOfCurveLine = (e) => {
        const schemaContainer = document.getElementById('schema-container').querySelector('svg')
        schemaContainer.getElementById(newCurveLinePathId.value + '_group').addEventListener('click', function (e) {
          if (selectedElement.value) {
            if (selectedElementType.value == 'broken-line') {
              stopSelectionOfBrokenLine()
            }
            else if (selectedElementType.value == 'curve-line') {
              stopSelectionOfCurveLine()
            }
            else if (selectedElementType.value == 'line') {
              stopSelectionOfLine()
            }
          }
          if (e.target.parentElement.tagName === 'g') {
            selectedElement.value = e.target.parentElement
            const allCircles = selectedElement.value.getElementsByTagName('circle')

            allCircles.forEach(circle => {
              circle.setAttribute('style', 'display: block;')
            })

            const centerCircleX = +allCircles[2].getAttribute('cx')
            const centerCircleY = +allCircles[2].getAttribute('cy')

            currentCurveLineSettings.value = {
              strokeStyle: selectedElement.value.getElementsByTagName('path')[0].getAttribute('stroke-dasharray') ? 'dotted' : 'solid',
              startStyle: selectedElement.value.getElementsByTagName('line')[0].getAttribute('marker-start') ? 'arrow' : 'none',
              endStyle: selectedElement.value.getElementsByTagName('line')[1].getAttribute('marker-end') ? 'arrow' : 'none',
              strokeWeight: selectedElement.value.getElementsByTagName('path')[0].getAttribute('stroke-width')
            }
            curveLineSelectedFlag.value = true

            if (selectedElement.value.getElementsByClassName('move-point').length == 0) {
              const movePoint = document.createElementNS('http://www.w3.org/2000/svg', 'g');
              const collider = selectedElement.value.getElementsByTagName('rect')[0]

              const colliderCenterX = selectedElement.value.getBBox().x
              const colliderCenterY = selectedElement.value.getBBox().y

              movePoint.innerHTML = `<path d="M8.48713 17.349C8.67986 17.5467 8.99641 17.5508 9.19418 17.3581L12.4169 14.2174C12.6147 14.0247 12.6188 13.7081 12.4261 13.5104C12.2333 13.3126 11.9168 13.3085 11.719 13.5012L8.85434 16.293L6.06263 13.4283C5.8699 13.2305 5.55334 13.2264 5.35558 13.4192C5.15782 13.6119 5.15373 13.9284 5.34646 14.1262L8.48713 17.349ZM9.4097 0.651037C9.21697 0.453272 8.90042 0.449188 8.70265 0.641916L5.47989 3.78259C5.28213 3.97531 5.27804 4.29187 5.47077 4.48963C5.6635 4.6874 5.98005 4.69148 6.17782 4.49876L9.0425 1.70705L11.8342 4.57172C12.0269 4.76949 12.3435 4.77357 12.5413 4.58084C12.739 4.38812 12.7431 4.07156 12.5504 3.8738L9.4097 0.651037ZM9.34517 17.0064L9.55157 1.00645L8.55166 0.993551L8.34526 16.9936L9.34517 17.0064Z" fill="black"/> <path d="M0.646447 8.69808C0.451184 8.89334 0.451184 9.20993 0.646447 9.40519L3.82843 12.5872C4.02369 12.7824 4.34027 12.7824 4.53553 12.5872C4.7308 12.3919 4.7308 12.0753 4.53553 11.8801L1.70711 9.05164L4.53553 6.22321C4.7308 6.02795 4.7308 5.71136 4.53553 5.5161C4.34027 5.32084 4.02369 5.32084 3.82843 5.5161L0.646447 8.69808ZM17.4568 9.40519C17.6521 9.20993 17.6521 8.89334 17.4568 8.69808L14.2748 5.5161C14.0796 5.32084 13.763 5.32084 13.5677 5.5161C13.3724 5.71136 13.3724 6.02795 13.5677 6.22321L16.3961 9.05164L13.5677 11.8801C13.3724 12.0753 13.3724 12.3919 13.5677 12.5872C13.763 12.7824 14.0796 12.7824 14.2748 12.5872L17.4568 9.40519ZM1 9.55164L17.1032 9.55164L17.1032 8.55164L1 8.55164L1 9.55164Z" fill="black"/>`

              movePoint.setAttribute('transform', `translate(${colliderCenterX}, ${colliderCenterY})`);
              movePoint.setAttribute('style', 'cursor: pointer;');
              movePoint.setAttribute('class', 'move-point');

              // selectedElement.value.appendChild(movePoint)

              collider.addEventListener('mousedown', function (e) {
                if (selectedElement.value) {
                  elementMoveFlag.value = true
                  selectedElementType.value == 'curve-line'
                }
              }) 
            } else {
              const currentMovePoint = selectedElement.value.getElementsByClassName('move-point')[0]
              currentMovePoint.setAttribute('transform', `translate(${centerCircleX - 100}, ${centerCircleY - 100})`);
              currentMovePoint.setAttribute('style', 'display: block; cursor: pointer;');
            }

            selectedElementLastColor.value = selectedElement.value.getElementsByTagName('path')[0].getAttribute('stroke')
            // selectedElement.value.getElementsByTagName('path')[0].setAttribute('stroke', 'red')

            //select collider
            const pathCollider = selectedElement.value.getElementsByTagName('rect')[0]
            pathCollider.setAttribute('stroke', 'red');

            //selection of curve line
            // selectedElement.value.getElementsByTagName('circle').forEach(circle => {
            //   circle.setAttribute('fill', 'red')
            // })
            selectedElementType.value = 'curve-line'
          }
        })
      }

      const stopSelectionOfCurveLine = () => {
        curveLineSelectedFlag.value = false
        // selectedElement.value.getElementsByTagName('path')[0].setAttribute('stroke', 'black')
        selectedElement.value.getElementsByTagName('circle').forEach(circle => {
          circle.setAttribute('fill', 'black')
          circle.setAttribute('style', 'display: none;')
        })
        console.log('selectedElement.value', selectedElement.value.getElementsByTagName('g')[0])
        selectedElement.value.getElementsByTagName('g').forEach(el => el.setAttribute('style', 'display: none;'))

        //select collider
        const pathCollider = selectedElement.value.getElementsByTagName('rect')[0]
        pathCollider.setAttribute('stroke', 'transparent');
      }
      //end curve line selection logic

      //start broken line selection logic
      const brokenLineSelectedFlag = ref(false)
      const currentBrokenLineSettings = ref({
        strokeStyle: 'dotted',
        startStyle: 'none',
        endStyle: 'none',
        strokeWeight: 2
      })

      const selectionOfBrokenLine = (e) => {
        const schemaContainer = document.getElementById('schema-container').querySelector('svg')
        schemaContainer.getElementById(newBrokenLinePathId.value + '_group').addEventListener('click', function (e) {
          if (selectedElement.value) {
            if (selectedElementType.value == 'broken-line') {
              stopSelectionOfBrokenLine()
            }
            else if (selectedElementType.value == 'curve-line') {
              stopSelectionOfCurveLine()
            }
            else if (selectedElementType.value == 'line') {
              stopSelectionOfLine()
            }
          }
          if (e.target.parentElement.tagName === 'g') {            
            selectedElement.value = e.target.parentElement

            // show circles
            const allCircles = selectedElement.value.getElementsByTagName('circle')

            allCircles.forEach(circle => {
              circle.setAttribute('style', 'display: block;')
            })

            //show move point
            const centerCircleX = +allCircles[2].getAttribute('cx')
            const centerCircleY = +allCircles[2].getAttribute('cy')

            currentBrokenLineSettings.value = {
              strokeStyle: selectedElement.value.getElementsByTagName('path')[0].getAttribute('stroke-dasharray') ? 'dotted' : 'solid',
              startStyle: selectedElement.value.getElementsByTagName('line')[0].getAttribute('marker-start') ? 'arrow' : 'none',
              endStyle: selectedElement.value.getElementsByTagName('line')[1].getAttribute('marker-end') ? 'arrow' : 'none',
              strokeWeight: selectedElement.value.getElementsByTagName('path')[0].getAttribute('stroke-width')
            }
            brokenLineSelectedFlag.value = true

            if (selectedElement.value.getElementsByClassName('move-point').length == 0) {
              const movePoint = document.createElementNS('http://www.w3.org/2000/svg', 'g');
              const collider = selectedElement.value.getElementsByTagName('rect')[0]

              const colliderCenterX = selectedElement.value.getBBox().x
              const colliderCenterY = selectedElement.value.getBBox().y

              movePoint.innerHTML = `<path d="M8.48713 17.349C8.67986 17.5467 8.99641 17.5508 9.19418 17.3581L12.4169 14.2174C12.6147 14.0247 12.6188 13.7081 12.4261 13.5104C12.2333 13.3126 11.9168 13.3085 11.719 13.5012L8.85434 16.293L6.06263 13.4283C5.8699 13.2305 5.55334 13.2264 5.35558 13.4192C5.15782 13.6119 5.15373 13.9284 5.34646 14.1262L8.48713 17.349ZM9.4097 0.651037C9.21697 0.453272 8.90042 0.449188 8.70265 0.641916L5.47989 3.78259C5.28213 3.97531 5.27804 4.29187 5.47077 4.48963C5.6635 4.6874 5.98005 4.69148 6.17782 4.49876L9.0425 1.70705L11.8342 4.57172C12.0269 4.76949 12.3435 4.77357 12.5413 4.58084C12.739 4.38812 12.7431 4.07156 12.5504 3.8738L9.4097 0.651037ZM9.34517 17.0064L9.55157 1.00645L8.55166 0.993551L8.34526 16.9936L9.34517 17.0064Z" fill="black"/> <path d="M0.646447 8.69808C0.451184 8.89334 0.451184 9.20993 0.646447 9.40519L3.82843 12.5872C4.02369 12.7824 4.34027 12.7824 4.53553 12.5872C4.7308 12.3919 4.7308 12.0753 4.53553 11.8801L1.70711 9.05164L4.53553 6.22321C4.7308 6.02795 4.7308 5.71136 4.53553 5.5161C4.34027 5.32084 4.02369 5.32084 3.82843 5.5161L0.646447 8.69808ZM17.4568 9.40519C17.6521 9.20993 17.6521 8.89334 17.4568 8.69808L14.2748 5.5161C14.0796 5.32084 13.763 5.32084 13.5677 5.5161C13.3724 5.71136 13.3724 6.02795 13.5677 6.22321L16.3961 9.05164L13.5677 11.8801C13.3724 12.0753 13.3724 12.3919 13.5677 12.5872C13.763 12.7824 14.0796 12.7824 14.2748 12.5872L17.4568 9.40519ZM1 9.55164L17.1032 9.55164L17.1032 8.55164L1 8.55164L1 9.55164Z" fill="black"/>`

              movePoint.setAttribute('transform', `translate(${colliderCenterX}, ${colliderCenterY})`);
              movePoint.setAttribute('style', 'cursor: pointer;');
              movePoint.setAttribute('class', 'move-point');

              // selectedElement.value.appendChild(movePoint)

              collider.addEventListener('mousedown', function (e) {
                if (selectedElement.value) {
                  elementMoveFlag.value = true
                  selectedElementType.value == 'broken-line'
                }
              }) 
            } else {
              const currentMovePoint = selectedElement.value.getElementsByClassName('move-point')[0]
              currentMovePoint.setAttribute('transform', `translate(${centerCircleX - 100}, ${centerCircleY - 100})`);
              currentMovePoint.setAttribute('style', 'display: block; cursor: pointer;');
            }

            const pathCollider = selectedElement.value.getElementsByTagName('rect')[0]
            pathCollider.setAttribute('stroke', 'red');

            //selection of broken line
            selectedElementLastColor.value = selectedElement.value.getElementsByTagName('path')[0].getAttribute('stroke')
            // selectedElement.value.getElementsByTagName('path')[0].setAttribute('stroke', 'red')
            // selectedElement.value.getElementsByTagName('circle').forEach(circle => {
            //   circle.setAttribute('fill', 'red')
            // })
            selectedElementType.value = 'broken-line'
          }
        })
      }

      const changeBrokenLineEndStyle = (endStyle) => {
        const path = selectedElement.value.getElementsByTagName('path')[0]
        const startLine = selectedElement.value.getElementsByTagName('line')[1]

        if (endStyle == 'none') {
          startLine.setAttribute('marker-end', 'none')
          currentBrokenLineSettings.value.endStyle = 'none'
        }
        else if (endStyle == 'arrow') {
          startLine.setAttribute('marker-end', 'url(#arrow-start)')
          currentBrokenLineSettings.value.endStyle = 'arrow'
        }
      }

      const changeBrokenLineStrokeStyle = (strokeStyle) => {
        const path = selectedElement.value.getElementsByTagName('path')[0]
        currentBrokenLineSettings.value.strokeStyle = strokeStyle

        if (strokeStyle == 'solid') {
          path.setAttribute('stroke-dasharray', 'none')
          currentBrokenLineSettings.value.strokeStyle = 'solid'
        }
        else if (strokeStyle == 'dotted') {
          path.setAttribute('stroke-dasharray', '5,5')
          currentBrokenLineSettings.value.strokeStyle = 'dotted'
        }
      }

      const changeBrokenLineStartStyle = (startStyle) => {
        const path = selectedElement.value.getElementsByTagName('path')[0]
        const startLine = selectedElement.value.getElementsByTagName('line')[0]

        
        if (startStyle == 'none') {
          startLine.setAttribute('marker-start', 'none')
          currentBrokenLineSettings.value.startStyle = 'none'
        }
        else if (startStyle == 'arrow') {
          startLine.setAttribute('marker-start', 'url(#arrow-start)')
          currentBrokenLineSettings.value.startStyle = 'arrow'
        }
      }

      const changeBrokenLineStrokeWeight = (strokeWeight) => {
        if (strokeWeight < 1) {
          return
        }
        const path = selectedElement.value.getElementsByTagName('path')[0]
        currentBrokenLineSettings.value.strokeWeight = strokeWeight
        path.setAttribute('stroke-width', strokeWeight)
      }

      const deleteBrokenLine = () => {
        const schemaContainer = document.getElementById('schema-container').querySelector('svg')
        schemaContainer.removeChild(selectedElement.value)
        selectedElement.value = null
        brokenLineSelectedFlag.value = false
      }

      const stopSelectionOfBrokenLine = () => {
        // selectedElement.value.getElementsByTagName('path')[0].setAttribute('stroke', 'black')
        selectedElement.value.getElementsByTagName('circle').forEach(circle => {
          circle.setAttribute('fill', 'black')
          circle.setAttribute('style', 'display: none;')
        })
        selectedElement.value.getElementsByTagName('g').forEach(el => el.setAttribute('style', 'display: none;'))
        const pathCollider = selectedElement.value.getElementsByTagName('rect')[0]
        pathCollider.setAttribute('stroke', 'transparent');
        brokenLineSelectedFlag.value = false
      }
      //end of broken line selection logic

      //start sinusoid line selection logic
      const sinusoidLineSelectedFlag = ref(false)
      const currentSinusoidLineSettings = ref({
        strokeStyle: 'solid',
        startStyle: 'none',
        endStyle: 'none',
        strokeWeight: 2
      })

      const changeSinusoidLineStrokeStyle = (strokeStyle) => {
        const path = selectedElement.value.getElementsByTagName('path')[0]
        currentSinusoidLineSettings.value.strokeStyle = strokeStyle

        if (strokeStyle == 'solid') {
          path.setAttribute('stroke-dasharray', 'none')
          currentSinusoidLineSettings.value.strokeStyle = 'solid'
        }
        else if (strokeStyle == 'dotted') {
          path.setAttribute('stroke-dasharray', '5,5')
          currentSinusoidLineSettings.value.strokeStyle = 'dotted'
        }
      }

      const changeSinusoidLineStartStyle = (startStyle) => {
        const path = selectedElement.value.getElementsByTagName('path')[0]
        const startLine = selectedElement.value.getElementsByTagName('line')[0]

        
        if (startStyle == 'none') {
          startLine.setAttribute('marker-start', 'none')
          currentSinusoidLineSettings.value.startStyle = 'none'
        }
        else if (startStyle == 'arrow') {
          startLine.setAttribute('marker-start', 'url(#arrow-start)')
          currentSinusoidLineSettings.value.startStyle = 'arrow'
        }
      }

      const changeSinusoidLineEndStyle = (endStyle) => {
        const path = selectedElement.value.getElementsByTagName('path')[0]
        const startLine = selectedElement.value.getElementsByTagName('line')[0]

        if (endStyle == 'none') {
          startLine.setAttribute('marker-end', 'none')
          currentSinusoidLineSettings.value.endStyle = 'none'
        }
        else if (endStyle == 'arrow') {
          startLine.setAttribute('marker-end', 'url(#arrow-start)')
          currentSinusoidLineSettings.value.endStyle = 'arrow'
        }
      }

      const changeSinusoidLineStrokeWeight = (strokeWeight) => {
        if (strokeWeight < 1) {
          return
        }
        const path = selectedElement.value.getElementsByTagName('path')[0]
        currentSinusoidLineSettings.value.strokeWeight = strokeWeight
        path.setAttribute('stroke-width', strokeWeight)
      }

      const deleteSinusoidLine = () => {
        const schemaContainer = document.getElementById('schema-container').querySelector('svg')
        schemaContainer.removeChild(selectedElement.value)
        selectedElement.value = null
        sinusoidLineSelectedFlag.value = false
        textSelectedFlag.value = false
      }

      const selectionOfSinusoidLine = (e) => {
        const schemaContainer = document.getElementById('schema-container').querySelector('svg')
        schemaContainer.getElementById(newSinusoidLinePathId.value + '_group').addEventListener('click', function (e) {
          if (selectedElement.value) {
            if (selectedElementType.value == 'broken-line') {
              stopSelectionOfBrokenLine()
            }
            else if (selectedElementType.value == 'curve-line') {
              stopSelectionOfCurveLine()
            }
            else if (selectedElementType.value == 'line') {
              stopSelectionOfLine()
            }
          }

          if (e.target.parentElement.tagName === 'g') {
            selectedElement.value = e.target.parentElement
            const path = selectedElement.value.getElementsByTagName('path')[0]
  
            const centerCircleX = (+path.getAttribute('d').split('M')[1].split(' ')[0] + +path.getAttribute('d').split('L')[1].split(' ')[0]) / 2
            const centerCircleY = (+path.getAttribute('d').split('M')[1].split(' ')[1] + +path.getAttribute('d').split('L')[1].split(' ')[1]) / 2
  
            const movePoint = document.createElementNS('http://www.w3.org/2000/svg', 'g');
  
            movePoint.innerHTML = `<path d="M8.48713 17.349C8.67986 17.5467 8.99641 17.5508 9.19418 17.3581L12.4169 14.2174C12.6147 14.0247 12.6188 13.7081 12.4261 13.5104C12.2333 13.3126 11.9168 13.3085 11.719 13.5012L8.85434 16.293L6.06263 13.4283C5.8699 13.2305 5.55334 13.2264 5.35558 13.4192C5.15782 13.6119 5.15373 13.9284 5.34646 14.1262L8.48713 17.349ZM9.4097 0.651037C9.21697 0.453272 8.90042 0.449188 8.70265 0.641916L5.47989 3.78259C5.28213 3.97531 5.27804 4.29187 5.47077 4.48963C5.6635 4.6874 5.98005 4.69148 6.17782 4.49876L9.0425 1.70705L11.8342 4.57172C12.0269 4.76949 12.3435 4.77357 12.5413 4.58084C12.739 4.38812 12.7431 4.07156 12.5504 3.8738L9.4097 0.651037ZM9.34517 17.0064L9.55157 1.00645L8.55166 0.993551L8.34526 16.9936L9.34517 17.0064Z" fill="black"/> <path d="M0.646447 8.69808C0.451184 8.89334 0.451184 9.20993 0.646447 9.40519L3.82843 12.5872C4.02369 12.7824 4.34027 12.7824 4.53553 12.5872C4.7308 12.3919 4.7308 12.0753 4.53553 11.8801L1.70711 9.05164L4.53553 6.22321C4.7308 6.02795 4.7308 5.71136 4.53553 5.5161C4.34027 5.32084 4.02369 5.32084 3.82843 5.5161L0.646447 8.69808ZM17.4568 9.40519C17.6521 9.20993 17.6521 8.89334 17.4568 8.69808L14.2748 5.5161C14.0796 5.32084 13.763 5.32084 13.5677 5.5161C13.3724 5.71136 13.3724 6.02795 13.5677 6.22321L16.3961 9.05164L13.5677 11.8801C13.3724 12.0753 13.3724 12.3919 13.5677 12.5872C13.763 12.7824 14.0796 12.7824 14.2748 12.5872L17.4568 9.40519ZM1 9.55164L17.1032 9.55164L17.1032 8.55164L1 8.55164L1 9.55164Z" fill="black"/>`
  
            const centerColliderX = selectedElement.value.getBBox().x
            const centerColliderY = selectedElement.value.getBBox().y

            movePoint.setAttribute('transform', `translate(${centerColliderX}, ${centerColliderY})`);
            movePoint.setAttribute('style', 'cursor: pointer;');
            movePoint.setAttribute('id', newBrokenLinePathId.value + '_move-point');
  
            // selectedElement.value.appendChild(movePoint)

            sinusoidLineSelectedFlag.value = true

            const pathCollider = selectedElement.value.getElementsByTagName('rect')[0]
            pathCollider.setAttribute('stroke', 'red');
  
            pathCollider.addEventListener('mousedown', function (e) {
              if (selectedElement.value) {
                elementMoveFlag.value = true
                selectedElementType.value == 'sinusoid-line'
              }
            })
  
            selectedElementLastColor.value = selectedElement.value.getElementsByTagName('path')[0].getAttribute('stroke')
            // selectedElement.value.getElementsByTagName('path')[0].setAttribute('stroke', 'red')
            selectedElementType.value = 'sinusoid-line'
          }
        })
      }

      const stopSelectionOfSinusoidLine = () => {
        // selectedElement.value.getElementsByTagName('path')[0].setAttribute('stroke', selectedElementLastColor.value)
        // selectedElement.value.getElementsByTagName('g')[0].remove()
        const pathCollider = selectedElement.value.getElementsByTagName('rect')[0]
        pathCollider.setAttribute('stroke', 'transparent');
        sinusoidLineSelectedFlag.value = false
      }
      //end sinusoid line selection logic

      //start text selection logic
      const currentTextSettings = ref({
        fontSize: 20,
        fontFamily: 'Arial',
        fontWeight: 'normal',
        fontStyle: 'normal',
        textDecoration: 'none',
        textAnchor: 'start',
        fill: 'black',
        text: ''
      })
      const textSelectedFlag = ref(false)
      const newTextColor = ref('black')

      const selectionOfText = (textField) => {
        const schemaContainer = document.getElementById('schema-container').querySelector('svg')
        textField.addEventListener('click', function (e) {
          if (selectedElement.value) {
            if (selectedElementType.value == 'broken-line') {
              stopSelectionOfBrokenLine()
            }
            else if (selectedElementType.value == 'curve-line') {
              stopSelectionOfCurveLine()
            }
            else if (selectedElementType.value == 'line') {
              stopSelectionOfLine()
            }
            else if (selectedElementType.value == 'sinusoid-line') {
              stopSelectionOfSinusoidLine()
            }
            else if (selectedElementType.value == 'text') {
              stopSelectionOfText()
            }
          }
          if (e.target.tagName === 'text') {
            selectedElement.value = e.target
            selectedElementLastColor.value = selectedElement.value.getAttribute('fill')
            // selectedElement.value.setAttribute('fill', 'red')
            selectedElementType.value = 'text'

            currentTextSettings.value = {
              fontSize: +selectedElement.value.getAttribute('font-size'),
              fontFamily: selectedElement.value.getAttribute('font-family'),
              fontWeight: selectedElement.value.getAttribute('font-weight'),
              fontStyle: selectedElement.value.getAttribute('font-style'),
              textDecoration: selectedElement.value.getAttribute('text-decoration'),
              textAnchor: selectedElement.value.getAttribute('text-anchor'),
              fill: selectedElement.value.getAttribute('fill'),
              text: selectedElement.value.innerHTML
            }

            newTextColor.value = selectedElement.value.getAttribute('fill')
            textSelectedFlag.value = true

            selectedElement.value.addEventListener('mousedown', function (e) {
              if (selectedElement.value) {
                elementMoveFlag.value = true
                selectedElementType.value == 'text'
              }
            })
          }
        })
      }

      const changeTextValue = (text) => {
        selectedElement.value.innerHTML = text
        currentTextSettings.value.text = text
      } 

      const changeTextFontSize = (fontSize) => {
        if (fontSize < 1) {
          return
        }
        selectedElement.value.setAttribute('font-size', fontSize)
        currentTextSettings.value.fontSize = fontSize
      }

      const changeTextColor = (color) => {
        selectedElement.value.setAttribute('fill', color)
        newTextColor.value = color
        currentTextSettings.value.fill = color
      }

      const changeTextFontWeight = (fontWeight) => {
        if (fontWeight == '800') {
          currentTextSettings.value.fontStyle = 'bold'
          selectedElement.value.setAttribute('font-style', 'normal')
        } 
        selectedElement.value.setAttribute('font-weight', fontWeight)
        currentTextSettings.value.fontWeight = fontWeight
      }

      const changeTextTextDecoration = (textDecoration) => {
        selectedElement.value.setAttribute('text-decoration', textDecoration)
        currentTextSettings.value.textDecoration = textDecoration
      }

      const changeTextFontStyle = (fontStyle) => {
        if (fontStyle == 'normal') {
          currentTextSettings.value.fontStyle = 'italic'
          selectedElement.value.setAttribute('font-weight', 'normal')
        }
        selectedElement.value.setAttribute('font-style', fontStyle)
        currentTextSettings.value.fontStyle = fontStyle
      }

      const stopSelectionOfText = () => {
        selectedElement.value.setAttribute('fill', newTextColor.value)
        textSelectedFlag.value = false
      }
      //end text selection logic
      


      //other shit
      const startsLineDrawingFlag = ref(false)
      const processLineDrawingFlag = ref(false)

      const screenWidth = ref(window.innerWidth)

      const router = useRouter()
      const route = useRoute()

      const name = ref('')
      const selectShowFlag = ref(false)
      const savedSchemaFlag = ref(false)
      const openSchema = ref({})
      const updateSchemeFlag = ref(false)
      const saveSchemeFlag = ref(false)

      const addedToElementSchema = ref(false)

      const elementId = ref(route.params.id)
      const elementIdRouteParam = ref(route.query.element_id)

      const nameErrorFlag = ref(false)

      const countPlayers = ref({
        firstPlayer: 1,
        secondPlayer: 1
      })

      const selectedComponent = ref(null)
      const selectedComponentForChange = ref(null)
      const selectedComponentSettings = ref({
        rotate: 0,
        scale: 1,
        color: '#FFF',
        playerNumber: null,
        componentType: null
      })
      const startX = ref(0)
      const startY = ref(0)
      const selectedComponentSettingsFlag = ref(false)

      const selectedElementCenter = ref({
        x: 0,
        y: 0
      })

      const isSchemeSaved = ref(false)

      onMounted(async () => {
        window.addEventListener('resize', () => {
          screenWidth.value = window.innerWidth
        })
        elementId.value = route.params.element_id
        console.log('elementId.value -------- ', elementId.value)
        elementIdRouteParam.value = route.query.element_id
        console.log('elementIdRouteParam.value -------- ', elementIdRouteParam.value)

        isSchemeSaved.value = route.query.schema_id ? true : false

        if (elementIdRouteParam.value) {
          addedToElementSchema.value = true
        }

        if (route.query.schema_id) {
          const schema = await getSchemaById(route.query.schema_id)
          name.value = schema.name
          console.log('schema -------- ', schema)
          const res = await getSvgCodeOfSchema(schema.filename)
          console.log('res -------- ', res)
          document.getElementById('schema-container').querySelector('svg').innerHTML = res
          // document.getElementById('schema-container').getElementsByClassName('scheme-placeholder')[0].innerHTML = res

          savedSchemaFlag.value = true
          openSchema.value = schema
          //set for all <g> element event listener
          const schemaContainer = document.getElementById('schema-container').querySelector('svg')
          const allGElements = schemaContainer.querySelectorAll('g')

          allGElements.forEach((element, i) => {
            if (i == 0) return
            element.addEventListener('mousedown', function (e) {
              console.log('element -------- ', element)
              selectedComponent.value = element
            })
            element.addEventListener('touchstart', function (e) {
              selectedComponent.value = element
            })

            const elementType = element.getAttribute('class')
            if (elementType == 'line') {
              element.addEventListener('click', function (e) {
                if (selectedElement.value) {
                  if (selectedElementType.value == 'broken-line') {
                    stopSelectionOfBrokenLine()
                  }
                  else if (selectedElementType.value == 'curve-line') {
                    stopSelectionOfCurveLine()
                  }
                  else if (selectedElementType.value == 'line') {
                    stopSelectionOfLine()
                  }
                  else if (selectedElementType.value == 'sinusoid-line') {
                    stopSelectionOfSinusoidLine()
                  }
                  else if (selectedElementType.value == 'text') {
                    stopSelectionOfText()
                  }
                }
                selectedElement.value = element
                console.log('selectedElement.value', selectedElement.value)
                const path = selectedElement.value.getElementsByTagName('path')[0]
                const startLine = selectedElement.value.getElementsByTagName('line')[0]

                const allCircles = selectedElement.value.getElementsByTagName('circle')
                allCircles.forEach(circle => {
                  circle.setAttribute('style', 'display: block;')

                  circle.addEventListener('mousedown', function (e) {
                    lineProjectingFlag.value = true
                    lineProjectingPoint.value = e.target
                  })
                })

                currentLineSettings.value.strokeStyle = path.getAttribute('stroke-dasharray') ? 'dotted' : 'solid'
                currentLineSettings.value.startStyle = startLine.getAttribute('marker-start') ? 'arrow' : 'none'
                currentLineSettings.value.endStyle = startLine.getAttribute('marker-end') ? 'arrow' : 'none'
                currentLineSettings.value.strokeWeight = path.getAttribute('stroke-width')

                const lineCollider = selectedElement.value.getElementsByTagName('rect')[0]
                lineCollider.setAttribute('stroke', 'red')
                selectedElementType.value = 'line'
                selectedElementLastColor.value = selectedElement.value.getElementsByTagName('path')[0].getAttribute('stroke')

                selectedElement.value.addEventListener('mousedown', function (e) {
                  elementMoveFlag.value = true
                  lineSelectedFlag.value = true
                })

                // elementMoveFlag.value = true
                lineSelectedFlag.value = true
              })
            } else if (elementType == 'curve-line') {
              element.addEventListener('click', function (e) {
                if (selectedElement.value) {
                  if (selectedElementType.value == 'broken-line') {
                    stopSelectionOfBrokenLine()
                  }
                  else if (selectedElementType.value == 'curve-line') {
                    stopSelectionOfCurveLine()
                  }
                  else if (selectedElementType.value == 'line') {
                    stopSelectionOfLine()
                  }
                  else if (selectedElementType.value == 'sinusoid-line') {
                    stopSelectionOfSinusoidLine()
                  }
                  else if (selectedElementType.value == 'text') {
                    stopSelectionOfText()
                  }
                }
                selectedElement.value = element
                console.log('selectedElement.value', selectedElement.value)
                const path = selectedElement.value.getElementsByTagName('path')[0]
                const startLine = selectedElement.value.getElementsByTagName('line')[0]

                const lineCollider = selectedElement.value.getElementsByTagName('rect')[0]
                lineCollider.setAttribute('stroke', 'red')
                selectedElementType.value = 'curve-line'
                selectedElementLastColor.value = selectedElement.value.getElementsByTagName('path')[0].getAttribute('stroke')

                const allCircles = selectedElement.value.getElementsByTagName('circle')
                allCircles.forEach(circle => {
                  circle.setAttribute('style', 'display: block;')
                  circle.addEventListener('mousedown', function (e) {
                    curveProjectingFlag.value = true
                    curveProjectingPoint.value = e.target
                  })
                })

                currentCurveLineSettings.value = {
                  strokeStyle: selectedElement.value.getElementsByTagName('path')[0].getAttribute('stroke-dasharray') ? 'dotted' : 'solid',
                  startStyle: selectedElement.value.getElementsByTagName('line')[0].getAttribute('marker-start') ? 'arrow' : 'none',
                  endStyle: selectedElement.value.getElementsByTagName('line')[1].getAttribute('marker-end') ? 'arrow' : 'none',
                  strokeWeight: selectedElement.value.getElementsByTagName('path')[0].getAttribute('stroke-width')
                }

                selectedElement.value.addEventListener('mousedown', function (e) {
                  elementMoveFlag.value = true
                  curveLineSelectedFlag.value = true
                })

                curveLineSelectedFlag.value = true
              })
            } else if (elementType == 'broken-line') {
              element.addEventListener('click', function (e) {
                if (selectedElement.value) {
                  if (selectedElementType.value == 'broken-line') {
                    stopSelectionOfBrokenLine()
                  }
                  else if (selectedElementType.value == 'curve-line') {
                    stopSelectionOfCurveLine()
                  }
                  else if (selectedElementType.value == 'line') {
                    stopSelectionOfLine()
                  }
                  else if (selectedElementType.value == 'sinusoid-line') {
                    stopSelectionOfSinusoidLine()
                  }
                  else if (selectedElementType.value == 'text') {
                    stopSelectionOfText()
                  }
                }
                
                selectedElement.value = element
                const path = selectedElement.value.getElementsByTagName('path')[0]
                const startLine = selectedElement.value.getElementsByTagName('line')[0]

                const allCircles = selectedElement.value.getElementsByTagName('circle')
                allCircles.forEach(circle => {
                  circle.setAttribute('style', 'display: block;')

                  circle.addEventListener('mousedown', function (e) {
                    brokenProjectingFlag.value = true
                    brokenProjectingPoint.value = e.target
                  })
                })

                currentBrokenLineSettings.value.strokeStyle = path.getAttribute('stroke-dasharray') ? 'dotted' : 'solid' 
                currentBrokenLineSettings.value.startStyle = startLine.getAttribute('marker-start') ? 'arrow' : 'none'
                currentBrokenLineSettings.value.endStyle = startLine.getAttribute('marker-end') ? 'arrow' : 'none'
                currentBrokenLineSettings.value.strokeWeight = path.getAttribute('stroke-width')

                const lineCollider = selectedElement.value.getElementsByTagName('rect')[0]
                lineCollider.setAttribute('stroke', 'red')
                selectedElementType.value = 'broken-line'
                selectedElementLastColor.value = selectedElement.value.getElementsByTagName('path')[0].getAttribute('stroke')

                selectedElement.value.addEventListener('mousedown', function (e) {
                  elementMoveFlag.value = true
                  brokenLineSelectedFlag.value = true
                })

                brokenLineSelectedFlag.value = true
              })
            } else if (elementType == 'sinusoid-line') {
              element.addEventListener('click', function (e) {
                if (selectedElement.value) {
                  if (selectedElementType.value == 'broken-line') {
                    stopSelectionOfBrokenLine()
                  }
                  else if (selectedElementType.value == 'curve-line') {
                    stopSelectionOfCurveLine()
                  }
                  else if (selectedElementType.value == 'line') {
                    stopSelectionOfLine()
                  }
                  else if (selectedElementType.value == 'sinusoid-line') {
                    stopSelectionOfSinusoidLine()
                  }
                  else if (selectedElementType.value == 'text') {
                    stopSelectionOfText()
                  }
                }
                
                selectedElement.value = element
                const path = selectedElement.value.getElementsByTagName('path')[0]
                const startLine = selectedElement.value.getElementsByTagName('line')[0]

                const allCircles = selectedElement.value.getElementsByTagName('circle')
                allCircles.forEach(circle => {
                  circle.setAttribute('style', 'display: block;')

                  circle.addEventListener('mousedown', function (e) {
                    sinusoidProjectingFlag.value = true
                    sinusoidProjectingPoint.value = e.target
                  })
                })

                currentSinusoidLineSettings.value.strokeStyle = path.getAttribute('stroke-dasharray') ? 'dotted' : 'solid'
                currentSinusoidLineSettings.value.startStyle = startLine.getAttribute('marker-start') ? 'arrow' : 'none'
                currentSinusoidLineSettings.value.endStyle = startLine.getAttribute('marker-end') ? 'arrow' : 'none'
                currentSinusoidLineSettings.value.strokeWeight = path.getAttribute('stroke-width')

                const lineCollider = selectedElement.value.getElementsByTagName('rect')[0]
                lineCollider.setAttribute('stroke', 'red')
                selectedElementType.value = 'sinusoid-line'
                selectedElementLastColor.value = selectedElement.value.getElementsByTagName('path')[0].getAttribute('stroke')

                selectedElement.value.addEventListener('mousedown', function (e) {
                  elementMoveFlag.value = true
                  sinusoidLineSelectedFlag.value = true
                })

                sinusoidLineSelectedFlag.value = true
              })
            } else if (elementType == 'component') {
              element.addEventListener('click', function (e) {
                if (selectedElement.value) {
                  if (selectedElementType.value == 'broken-line') {
                    stopSelectionOfBrokenLine()
                  }
                  else if (selectedElementType.value == 'curve-line') {
                    stopSelectionOfCurveLine()
                  }
                  else if (selectedElementType.value == 'line') {
                    stopSelectionOfLine()
                  }
                  else if (selectedElementType.value == 'sinusoid-line') {
                    stopSelectionOfSinusoidLine()
                  }
                  else if (selectedElementType.value == 'text') {
                    stopSelectionOfText()
                  }
                }
                selectedElement.value = element
                selectedElementLastColor.value = selectedElement.value.getElementsByTagName('path')[0].getAttribute('fill')
                selectedElementType.value = 'component'
                selectedComponentForChange.value = selectedElement.value
                selectedComponentSettingsFlag.value = true

                const colliderPath = selectedElement.value.getElementsByTagName('rect')[0]
                colliderPath.setAttribute('stroke', 'red')

                selectedComponentSettings.value = {
                  rotate: 0,
                  scale: 1,
                  color: selectedElement.value.getElementsByTagName('path')[0].getAttribute('fill'),
                  playerNumber: selectedElement.value.getElementsByTagName('text')[0].innerHTML,
                  componentType: selectedElement.value.getAttribute('id')
                }

                selectedElementCenter.value = {
                  x: colliderPath.getBBox().x + colliderPath.getBBox().width / 2,
                  y: colliderPath.getBBox().y + colliderPath.getBBox().height / 2
                }

                

                // selectedElement.value.getElementsByTagName('path')[0].setAttribute('fill', 'red')
              })
            }


          });

          const allSections = schemaContainer.querySelectorAll('.section')

          allSections.forEach(section => {
            section.addEventListener('click', function (e) {
              if (selectedElement.value) {
                if (selectedElementType.value == 'broken-line') {
                  stopSelectionOfBrokenLine()
                }
                else if (selectedElementType.value == 'curve-line') {
                  stopSelectionOfCurveLine()
                }
                else if (selectedElementType.value == 'line') {
                  stopSelectionOfLine()
                }
                else if (selectedElementType.value == 'sinusoid-line') {
                  stopSelectionOfSinusoidLine()
                }
                else if (selectedElementType.value == 'text') {
                  stopSelectionOfText()
                }
              }
              sectionSelectedFlag.value = true
              selectedElement.value = section

              currentSectionSettings.value = {
                fill: section.getAttribute('fill'),
                stroke: section.getAttribute('stroke'),
                strokeWidth: section.getAttribute('stroke-width')
              }

              selectedElementLastColor.value = section.getAttribute('fill')
              selectedSectionLastStroke.value = section.getAttribute('stroke')

              section.addEventListener('mousedown', function (e) {
                elementMoveFlag.value = true
                sectionSelectedFlag.value = true
                selectedElementType.value = 'section'
              })
              
            })
          })

          const allTexts = schemaContainer.querySelectorAll('text')

          allTexts.forEach(text => {
            selectionOfText(text)
          })
          
          //old movement logic
          document.addEventListener('mousemove', function (e) {
            if (selectedComponent.value) {
              if (selectedElementType.value == '' || selectedElementType.value == 'line' || selectedElementType.value == 'curve-line' || selectedElementType.value == 'broken-line' || selectedElementType.value == 'sinusoid-line' || selectedElementType.value == 'text' || selectedElementType.value == 'section') {
                return
              }

              console.log('old movement logic', selectedElementType.value)
              const currentElement = selectedComponent.value
                
              var mouseX = e.clientX - schemaContainer.getBoundingClientRect().left;
              var mouseY = e.clientY - schemaContainer.getBoundingClientRect().top;
              
              var rectWidth = currentElement.getBoundingClientRect().width;
              var rectHeight = currentElement.getBoundingClientRect().height;
    
              currentElement.setAttribute('transform', 'translate(' + (mouseX - (rectWidth / 2)) + ',' + (mouseY - (rectHeight / 2)) + ')')
            }
          })
    
          document.addEventListener('mouseup', function () {
            selectedComponent.value = null;
          });

        }

        const schemaContainer = document.getElementById('schema-container').querySelector('svg')
        schemaContainer.querySelectorAll('g')[0].addEventListener('mousedown', function () {
          if (selectedElement.value) {
            if (selectedElementType.value == 'line') {
              stopSelectionOfLine()
            } else if (selectedElementType.value == 'curve-line') {
              stopSelectionOfCurveLine()
            } else if (selectedElementType.value == 'broken-line') {
              stopSelectionOfBrokenLine()
            } else if (selectedElementType.value == 'sinusoid-line') {
              stopSelectionOfSinusoidLine()
            } else if (selectedElementType.value == 'text') {
              stopSelectionOfText()
            } else if (selectedElementType.value == 'section') {
              stopSelectionOfSection()
            }

            selectedElement.value = null
            selectedElementLastColor.value = null
            selectedElementType.value = ''
            selectedElementFlag.value = false
          }

          console.log('stop selecting ----- ', schemaContainer.getElementsByClassName('collider'))
          schemaContainer.getElementsByClassName('collider').forEach(el => {
            el.setAttribute('stroke', 'transparent')
          })

          selectedComponentSettingsFlag.value = false
        });

        drawingLinesListeners()
        addTextField()
        addSections()
        addSelectionOfElements()

        // selectedComponentForAddName
        schemaContainer.querySelectorAll('g')[0].addEventListener('click', function (e) {
          if (selectedComponentForAddName.value) {
            const mouseCoords = {
              x: e.clientX - schemaContainer.getBoundingClientRect().left,
              y: e.clientY - schemaContainer.getBoundingClientRect().top
            }
            selectNewComponent(selectedComponentForAddName.value, mouseCoords)

            if (selectedComponentForAddName.value == 'player1') {
              countPlayers.value.firstPlayer++
            } else if (selectedComponentForAddName.value == 'small-player') {
              countPlayers.value.secondPlayer++
            }
          }
        })
      })

      function extractTransformValues(transformString) {
        const rotatePattern = /rotate\(([^,]+),\s*([^,]+),\s*([^,]+)\)/;
        const scalePattern = /scale\(([^)]+)\)/;

        // Ищем значение rotate
        const rotateMatch = transformString.match(rotatePattern);
        const rotate = rotateMatch ? {
          x: parseFloat(rotateMatch[1]),
          y: parseFloat(rotateMatch[2]),
          z: parseFloat(rotateMatch[3]),
        } : null;

        // Ищем значение scale
        const scaleMatch = transformString.match(scalePattern);
        const scale = scaleMatch ? parseFloat(scaleMatch[1]) : null;

        return {
          rotate,
          scale
        };
      }

      
      const selectedComponentForAddName = ref(null)

      const selectNewComponent = (componentName, mouseCoords) => {
        const component = document.getElementById(componentName).querySelector('g')
        const componentClone = component.cloneNode(true)
        
        
        const elementId = new Date().getTime()
        componentClone.setAttribute('id', elementId)
        componentClone.setAttribute('class', 'component')
        const schemaContainer = document.getElementById('schema-container').querySelector('svg')

        componentClone.setAttribute('transform', `translate(
          ${mouseCoords.x - component.getBoundingClientRect().width / 2},
          ${mouseCoords.y - component.getBoundingClientRect().height / 2}
        )`)

        componentClone.addEventListener('mousedown', function (e) {
          selectedComponent.value = document.getElementById(elementId)
        })

        componentClone.addEventListener('click', function (e) {
          selectedComponentForChange.value = document.getElementById(elementId)

          console.log('stop selecting ----- ', schemaContainer.getElementsByClassName('collider'))
          schemaContainer.getElementsByClassName('collider').forEach(el => {
            el.setAttribute('stroke', 'transparent')
          })

          const componentCollider = document.getElementById(elementId).getElementsByTagName('rect')[0]
          componentCollider.setAttribute('stroke', 'red')

          let selectedComponentRotate = 0
          let selectedComponentScale = 1
          const transformObject = extractTransformValues(document.getElementById(elementId).getAttribute('transform'))
          
          if (transformObject.rotate) {
            selectedComponentRotate = transformObject.rotate.x
          }

          if (transformObject.scale) {
            selectedComponentScale = transformObject.scale
          }
          
          const selectedComponentColor = document.getElementById(elementId).getElementsByTagName('path')[0]?.getAttribute('fill')
          const playerNumber = document.getElementById(elementId).getElementsByTagName('text')[0]?.innerHTML

          selectedComponentSettings.value = {
            rotate: selectedComponentRotate,
            scale: selectedComponentScale,
            color: selectedComponentColor,
            playerNumber: playerNumber,
            componentType: componentName
          }

          selectedElementCenter.value = {
            x: document.getElementById(elementId).getBoundingClientRect().width / 2,
            y: document.getElementById(elementId).getBoundingClientRect().height / 2
          }

          selectedComponentSettingsFlag.value = true
        })
  
        document.addEventListener('mousemove', function (e) {
          if (selectedComponent.value) {
            const currentElement = selectedComponent.value
  
            var mouseX = e.clientX - schemaContainer.getBoundingClientRect().left;
            var mouseY = e.clientY - schemaContainer.getBoundingClientRect().top;
            
            var rectWidth = currentElement.getBoundingClientRect().width;
            var rectHeight = currentElement.getBoundingClientRect().height;

            let currentScale = null
            if (currentElement.getAttribute('transform').includes('scale')) {
              currentScale = currentElement.getAttribute('transform').split('scale(')[1].split(')')[0]
            }

            let currentRotate = null
            if (currentElement.getAttribute('transform').includes('rotate')) {
              currentRotate = currentElement.getAttribute('transform').split('rotate(')[1].split(')')[0]
            }

            if (currentScale && currentRotate) {
              currentElement.setAttribute('transform', `translate(${mouseX - (rectWidth / 2)}, ${mouseY - (rectHeight / 2)}) scale(${currentScale}) rotate(${currentRotate})`)
            } else if (currentScale) {
              currentElement.setAttribute('transform', `translate(${mouseX - (rectWidth / 2)}, ${mouseY - (rectHeight / 2)}) scale(${currentScale})`)
            } else if (currentRotate) {
              currentElement.setAttribute('transform', `translate(${mouseX - (rectWidth / 2)}, ${mouseY - (rectHeight / 2)}) rotate(${currentRotate})`)
            } else {
              currentElement.setAttribute('transform', 'translate(' + (mouseX - (rectWidth / 2)) + ',' + (mouseY - (rectHeight / 2)) + ')')
            }
          }
        })
  
        document.addEventListener('mouseup', function () {
          selectedComponent.value = null;
        });
  
        componentClone.addEventListener('touchstart', function (e) {
          selectedComponent.value = document.getElementById(elementId)
        })
  
        componentClone.addEventListener('touchmove', function (e) {
          if (selectedComponent.value) {
            const currentElement = selectedComponent.value
            
            var mouseX = e.touches[0].clientX - schemaContainer.getBoundingClientRect().left;
            var mouseY = e.touches[0].clientY - schemaContainer.getBoundingClientRect().top;
            
            var rectWidth = currentElement.getBoundingClientRect().width;
            var rectHeight = currentElement.getBoundingClientRect().height;
  
            currentElement.setAttribute('transform', 'translate(' + (mouseX - (rectWidth / 2)) + ',' + (mouseY - (rectHeight / 2)) + ')')
          }
        })
  
        document.addEventListener('touchend', function () {
          selectedComponent.value = null;
        });
        schemaContainer.appendChild(componentClone)
      }

      const changeComponentPlayerNumber = (playerNumber) => {
        selectedComponentForChange.value.getElementsByTagName('text')[0].innerHTML = playerNumber
        selectedComponentSettings.value.playerNumber = playerNumber
      }

      const changeComponentColor = (color) => {
        const elementsForFill = selectedComponentForChange.value.getElementsByClassName('color_changed_fill')
        const elementsForStroke = selectedComponentForChange.value.getElementsByClassName('color_changed_stroke')

        if (elementsForFill) {
          elementsForFill.forEach(el => el.setAttribute('fill', color))
        }

        if (elementsForStroke) {
          elementsForStroke.forEach(el => el.setAttribute('stroke', color))
        }

        selectedComponentSettings.value.color = color
      }

      const changeComponentRotate = (rotate) => {
        let currentTranslate = null
        if (selectedComponentForChange.value.getAttribute('transform').includes('translate')) {
          currentTranslate = selectedComponentForChange.value.getAttribute('transform').split('translate(')[1].split(')')[0]
        }
        let currentScale = null
        if (selectedComponentForChange.value.getAttribute('transform').includes('scale')) {
          currentScale = selectedComponentForChange.value.getAttribute('transform').split('scale(')[1].split(')')[0]
        }

        const centerElementX = selectedComponentForChange.value.getBoundingClientRect().width / 2
        const centerElementY = selectedComponentForChange.value.getBoundingClientRect().height / 2
        
        if (currentTranslate && currentScale) {
          selectedComponentForChange.value.setAttribute('transform', `translate(${currentTranslate}) scale(${currentScale}) rotate(${rotate}, ${selectedElementCenter.value.x}, ${selectedElementCenter.value.y})`)
        } else if (currentTranslate) {
          selectedComponentForChange.value.setAttribute('transform', `translate(${currentTranslate}) rotate(${rotate}, ${selectedElementCenter.value.x}, ${selectedElementCenter.value.y})`)
        } else if (currentScale) {
          selectedComponentForChange.value.setAttribute('transform', `scale(${currentScale}) rotate(${rotate}, ${selectedElementCenter.value.x}, ${selectedElementCenter.value.y})`)
        } else {
          selectedComponentForChange.value.setAttribute('transform', `rotate(${rotate}, ${selectedElementCenter.value.x}, ${selectedElementCenter.value.y})`)
        }

        selectedComponentSettings.value.rotate = Math.round(parseFloat(rotate) * 100) / 100;
      }

      const changeComponentScale = (scale) => {
        let currentTranslate = null
        if (selectedComponentForChange.value.getAttribute('transform').includes('translate')) {
          currentTranslate = selectedComponentForChange.value.getAttribute('transform').split('translate(')[1].split(')')[0]
        }

        let currentRotate = null
        if (selectedComponentForChange.value.getAttribute('transform').includes('rotate')) {
          currentRotate = selectedComponentForChange.value.getAttribute('transform').split('rotate(')[1].split(')')[0]
        }

        if (currentTranslate && currentRotate) {
          selectedComponentForChange.value.setAttribute('transform', `translate(${currentTranslate}) rotate(${currentRotate}) scale(${scale})`)
        } else if (currentTranslate) {
          selectedComponentForChange.value.setAttribute('transform', `translate(${currentTranslate}) scale(${scale})`)
        } else if (currentRotate) {
          selectedComponentForChange.value.setAttribute('transform', `rotate(${currentRotate}) scale(${scale})`)
        } else {
          selectedComponentForChange.value.setAttribute('transform', `scale(${scale})`)
        }

        selectedComponentSettings.value.scale = Math.round(parseFloat(scale) * 100) / 100;
      }

      const deletePresetComponent = () => {
        const schemaContainer = document.getElementById('schema-container').querySelector('svg')
        schemaContainer.removeChild(selectedComponentForChange.value)
        selectedComponentForChange.value = null
        selectedComponentSettingsFlag.value = false
      }
  
      const startDrawing = () => {
        if (startsDrawingFlag.value) {
          startsDrawingFlag.value = false
          return
        }
        startsDrawingFlag.value = true
        startsLineDrawingFlag.value = false
  
        const schemaContainer = document.getElementById('schema-container').querySelector('svg')
  
        schemaContainer.addEventListener('mousedown', function (e) {
          if (startsDrawingFlag.value && newPathId.value == null) {
            processDrawingFlag.value = true;
            const { offsetX, offsetY } = event;
            newPathId.value = new Date().getTime()
  
            const newPath = document.createElementNS('http://www.w3.org/2000/svg', 'path');
            newPath.setAttribute('d', `M${offsetX} ${offsetY} `);
            newPath.setAttribute('fill', 'none');
            newPath.setAttribute('stroke', 'black');
            newPath.setAttribute('stroke-width', '2');
            newPath.setAttribute('id', newPathId.value);
            newPath.setAttribute('style', 'cursor: pointer;');
  
            schemaContainer.appendChild(newPath)
          }
        })
  
        schemaContainer.addEventListener('mousemove', function (e) {
          if (processDrawingFlag.value && newPathId.value) {
            const { offsetX, offsetY } = event;
            schemaContainer.getElementById(newPathId.value).setAttribute('d', schemaContainer.getElementById(newPathId.value).getAttribute('d') + `L${offsetX} ${offsetY} `);
          }
        })
  
        schemaContainer.addEventListener('mouseup', function (e) {
          processDrawingFlag.value = false;
          newPathId.value = null
        })
  
        schemaContainer.addEventListener('mouseleave', function (e) {
          processDrawingFlag.value = false;
          newPathId.value = null
        })
  
        schemaContainer.addEventListener('touchstart', function (e) {
          if (startsDrawingFlag.value && newPathId.value == null) {
            processDrawingFlag.value = true;
            newPathId.value = new Date().getTime()
  
            var mouseX = e.touches[0].clientX - schemaContainer.getBoundingClientRect().left;
            var mouseY = e.touches[0].clientY - schemaContainer.getBoundingClientRect().top;
  
            const newPath = document.createElementNS('http://www.w3.org/2000/svg', 'path');
            newPath.setAttribute('d', `M${mouseX} ${mouseY} `);
            newPath.setAttribute('fill', 'none');
            newPath.setAttribute('stroke', 'black');
            newPath.setAttribute('stroke-width', '2');
            newPath.setAttribute('id', newPathId.value);
            newPath.setAttribute('style', 'cursor: pointer;');
  
            schemaContainer.appendChild(newPath)
          }
        })
  
        schemaContainer.addEventListener('touchmove', function (e) {
          if (processDrawingFlag.value && newPathId.value) {
            var mouseX = e.touches[0].clientX - schemaContainer.getBoundingClientRect().left;
            var mouseY = e.touches[0].clientY - schemaContainer.getBoundingClientRect().top;
            schemaContainer.getElementById(newPathId.value).setAttribute('d', schemaContainer.getElementById(newPathId.value).getAttribute('d') + `L${mouseX} ${mouseY} `);
          }
        })
  
        schemaContainer.addEventListener('touchend', function (e) {
          processDrawingFlag.value = false;
          newPathId.value = null
        })
      }

      const deleteLastElement = () => {
        const schemaContainer = document.getElementById('schema-container').querySelector('svg')
        const lastElement = schemaContainer.lastChild
        if (lastElement) {
          schemaContainer.removeChild(lastElement)
        }
      }
  
      const clearAllField = () => {
        const schemaContainer = document.getElementById('schema-container').querySelector('svg')
        const defs = schemaContainer.querySelector('defs')
        const fieldGroup = schemaContainer.querySelector('g')
        schemaContainer.innerHTML = '<defs>' + defs.innerHTML + '</defs>' + '<g>' + fieldGroup.innerHTML + '</g>'

        window.addEventListener('resize', () => {
          screenWidth.value = window.innerWidth
        })
        elementId.value = route.params.element_id
        console.log('elementId.value -------- ', elementId.value)
        elementIdRouteParam.value = route.query.element_id
        console.log('elementIdRouteParam.value -------- ', elementIdRouteParam.value)

        if (elementIdRouteParam.value) {
          addedToElementSchema.value = true
        }

        // const schemaContainer = document.getElementById('schema-container').querySelector('svg')
        schemaContainer.querySelectorAll('g')[0].addEventListener('mousedown', function () {
          if (selectedElement.value) {
            if (selectedElementType.value == 'line') {
              stopSelectionOfLine()
            } else if (selectedElementType.value == 'curve-line') {
              stopSelectionOfCurveLine()
            } else if (selectedElementType.value == 'broken-line') {
              stopSelectionOfBrokenLine()
            } else if (selectedElementType.value == 'sinusoid-line') {
              stopSelectionOfSinusoidLine()
            } else if (selectedElementType.value == 'text') {
              stopSelectionOfText()
            } else if (selectedElementType.value == 'section') {
              stopSelectionOfSection()
            }

            selectedElement.value = null
            selectedElementLastColor.value = null
            selectedElementType.value = ''
            selectedElementFlag.value = false
          }

          console.log('stop selecting ----- ', schemaContainer.getElementsByClassName('collider'))
          schemaContainer.getElementsByClassName('collider').forEach(el => {
            el.setAttribute('stroke', 'transparent')
          })

          selectedComponentSettingsFlag.value = false
        });

        drawingLinesListeners()
        addTextField()
        addSections()
        addSelectionOfElements()

        // selectedComponentForAddName
        schemaContainer.querySelectorAll('g')[0].addEventListener('click', function (e) {
          if (selectedComponentForAddName.value) {
            const mouseCoords = {
              x: e.clientX - schemaContainer.getBoundingClientRect().left,
              y: e.clientY - schemaContainer.getBoundingClientRect().top
            }
            selectNewComponent(selectedComponentForAddName.value, mouseCoords)

            if (selectedComponentForAddName.value == 'player1') {
              countPlayers.value.firstPlayer++
            } else if (selectedComponentForAddName.value == 'small-player') {
              countPlayers.value.secondPlayer++
            }
          }
        })

        // selectedComponentForAddName
        
      }
  
      const downloadImage = () => {
        const schemaContainer = document.getElementById('schema-container').querySelector('svg')
        const svgData = schemaContainer.outerHTML;
        const preface = '<?xml version="1.0" standalone="no"?>\r\n';
        const svgBlob = new Blob([preface, svgData], {type:"image/svg+xml;charset=utf-8"});
        const svgUrl = URL.createObjectURL(svgBlob);
        const downloadLink = document.createElement("a");
        downloadLink.href = svgUrl;
        downloadLink.download = "schema1.svg";
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
      }
  
      const downloadImageAsPNG = () => {
        const schemaContainer = document.getElementById('schema-container').querySelector('svg')
        const svgData = schemaContainer.outerHTML;
        const canvas = document.createElement("canvas");
        const svgSize = schemaContainer.getBoundingClientRect();
        canvas.width = svgSize.width;
        canvas.height = svgSize.height;
        const ctx = canvas.getContext("2d");
        const img = document.createElement("img");
        img.setAttribute("src", "data:image/svg+xml;base64," + btoa(unescape(encodeURIComponent(svgData))));
        img.onload = function() {
          ctx.drawImage(img, 0, 0);
          const imgsrc = canvas.toDataURL("image/png");
          const a = document.createElement("a");
          a.download = "schema1.png";
          a.href = imgsrc;
          document.body.appendChild(a);
          a.click();
        };
      }

      const createEmptySchema = () => {
        savedSchemaFlag.value = false
        name.value = ''
        isSchemeSaved.value = false
        clearAllField()
        router.push('/training-schemes')
      }

      const saveNewSchema = async () => {
        const svgCode = document.getElementById('schema-container').querySelector('svg').outerHTML
        const svgFile = new Blob([svgCode], {type:"image/svg+xml;charset=utf-8"});
        
        const res = await createNewTrainingSchemaRequest(
          {
            name: name.value,
            info: '' 
          },
          svgFile
        )

        if (res.error) {
          nameErrorFlag.value = true
        }
        else {
          saveSchemeFlag.value = true
          return res
        }
      }

      const updateSchema = async () => {
        const svgCode = document.getElementById('schema-container').querySelector('svg').outerHTML
        const svgFile = new Blob([svgCode], {type:"image/svg+xml;charset=utf-8"});
        
        const res = await updateSchemaByIdRequest(
          openSchema.value.id,
          {
            name: name.value,
            info: '' 
          },
          svgFile
        )
        updateSchemeFlag.value = true
      }

      const deleteSchema = async () => {
        const res = await deleteSchemaByIdRequest(openSchema.value.id)
        savedSchemaFlag.value = false
        name.value = ''
        clearAllField()
      }

      const selectSchema = async (schema) => {
        selectShowFlag.value = false
        name.value = schema.name

        const res = await getSvgCodeOfSchema(schema.filename)
        document.getElementById('schema-container').innerHTML = res
        savedSchemaFlag.value = true
        openSchema.value = schema
        //set for all <g> element event listener
        const schemaContainer = document.getElementById('schema-container').querySelector('svg')
        const allGElements = schemaContainer.querySelectorAll('g')

        allGElements.forEach((element, i) => {
          if (i == 0) return
          element.addEventListener('mousedown', function (e) {
            console.log('element -------- ', element)
            selectedComponent.value = element
          })
          element.addEventListener('touchstart', function (e) {
            selectedComponent.value = element
          })
        });

        document.addEventListener('mousemove', function (e) {
          if (selectedComponent.value) {
            const currentElement = selectedComponent.value
              
            var mouseX = e.clientX - schemaContainer.getBoundingClientRect().left;
            var mouseY = e.clientY - schemaContainer.getBoundingClientRect().top;
            
            var rectWidth = currentElement.getBoundingClientRect().width;
            var rectHeight = currentElement.getBoundingClientRect().height;

            console.log('move element logic')
  
            currentElement.setAttribute('transform', 'translate(' + (mouseX - (rectWidth / 2)) + ',' + (mouseY - (rectHeight / 2)) + ')')
          }
        })
  
        document.addEventListener('mouseup', function () {
          selectedComponent.value = null;
        });
        
      }

      const addToElement = async () => {
        const schemaId = route.query.schema_id
        const elementId = route.query.element_id
        router.push({ path: '/training-element/' + elementId, query: { schema_id: schemaId } })
      }

      const updateFieldListeners = async () => {
        window.addEventListener('resize', () => {
          screenWidth.value = window.innerWidth
        })
        elementId.value = route.params.element_id
        console.log('elementId.value -------- ', elementId.value)
        elementIdRouteParam.value = route.query.element_id
        console.log('elementIdRouteParam.value -------- ', elementIdRouteParam.value)

        if (elementIdRouteParam.value) {
          addedToElementSchema.value = true
        }

        // if (route.query.schema_id) {
        //   const schema = await getSchemaById(route.query.schema_id)
        //   name.value = schema.name
        //   console.log('schema -------- ', schema)
        //   const res = await getSvgCodeOfSchema(schema.filename)
        //   console.log('res -------- ', res)
        //   document.getElementById('schema-container').innerHTML = res
        //   savedSchemaFlag.value = true
        //   openSchema.value = schema
        //   //set for all <g> element event listener
        //   const schemaContainer = document.getElementById('schema-container').querySelector('svg')
        //   const allGElements = schemaContainer.querySelectorAll('g')

        //   allGElements.forEach((element, i) => {
        //     if (i == 0) return
        //     element.addEventListener('mousedown', function (e) {
        //       console.log('element -------- ', element)
        //       selectedComponent.value = element
        //     })
        //     element.addEventListener('touchstart', function (e) {
        //       selectedComponent.value = element
        //     })
        //   });

        //   document.addEventListener('mousemove', function (e) {
        //     if (selectedComponent.value) {
        //       const currentElement = selectedComponent.value
                
        //       var mouseX = e.clientX - schemaContainer.getBoundingClientRect().left;
        //       var mouseY = e.clientY - schemaContainer.getBoundingClientRect().top;
              
        //       var rectWidth = currentElement.getBoundingClientRect().width;
        //       var rectHeight = currentElement.getBoundingClientRect().height;
    
        //       currentElement.setAttribute('transform', 'translate(' + (mouseX - (rectWidth / 2)) + ',' + (mouseY - (rectHeight / 2)) + ')')
        //     }
        //   })
    
        //   document.addEventListener('mouseup', function () {
        //     selectedComponent.value = null;
        //   });

        // }

        const schemaContainer = document.getElementById('schema-container').querySelector('svg')
        schemaContainer.querySelectorAll('g')[0].addEventListener('mousedown', function () {
          if (selectedElement.value) {
            if (selectedElementType.value == 'line') {
              stopSelectionOfLine()
            } else if (selectedElementType.value == 'curve-line') {
              stopSelectionOfCurveLine()
            } else if (selectedElementType.value == 'broken-line') {
              stopSelectionOfBrokenLine()
            } else if (selectedElementType.value == 'sinusoid-line') {
              stopSelectionOfSinusoidLine()
            } else if (selectedElementType.value == 'text') {
              stopSelectionOfText()
            } else if (selectedElementType.value == 'section') {
              stopSelectionOfSection()
            }

            selectedElement.value = null
            selectedElementLastColor.value = null
            selectedElementType.value = ''
            selectedElementFlag.value = false
          }

          console.log('stop selecting ----- ', schemaContainer.getElementsByClassName('collider'))
          schemaContainer.getElementsByClassName('collider').forEach(el => {
            el.setAttribute('stroke', 'transparent')
          })

          selectedComponentSettingsFlag.value = false
        });

        drawingLinesListeners()
        addTextField()
        addSections()
        addSelectionOfElements()

        // selectedComponentForAddName
        schemaContainer.querySelectorAll('g')[0].addEventListener('click', function (e) {
          if (selectedComponentForAddName.value) {
            const mouseCoords = {
              x: e.clientX - schemaContainer.getBoundingClientRect().left,
              y: e.clientY - schemaContainer.getBoundingClientRect().top
            }
            selectNewComponent(selectedComponentForAddName.value, mouseCoords)

            if (selectedComponentForAddName.value == 'player1') {
              countPlayers.value.firstPlayer++
            } else if (selectedComponentForAddName.value == 'small-player') {
              countPlayers.value.secondPlayer++
            }
          }
        })
      }

      watch(fieldMode, (newValue, oldValue) => {
        setTimeout(() => {
          updateFieldListeners()

        }, 1000)
      })
  
      return {
        lineSectionFlag,
        shapesSectionFlag,
        fieldSectionFlag,
        componentsSectionFlag,
        selectNewComponent,
        startDrawing,
        startsDrawingFlag,
        deleteLastElement,
        clearAllField,
        startsLineDrawingFlag,
        downloadImage,
        screenWidth,
        downloadImageAsPNG,
        name,
        saveNewSchema,
        selectShowFlag,
        selectSchema,
        savedSchemaFlag,
        updateSchema,
        deleteSchema,
        updateSchemeFlag,
        createEmptySchema,
        saveSchemeFlag,
        elementId,
        addToElement,
        elementIdRouteParam,
        addedToElementSchema,
        nameErrorFlag,
        countPlayers,
        processLineDrawingFlag,
        newPathId,
        selectedComponent,
        nowDrawingLine,
        nowDrawingCurveLine,
        nowDrawingBrokenLine,
        nowDrawingSinusoidLine,
        addTextField,
        startAddingTextFieldFlag,
        newTextFieldId,
        newTextFieldValue,
        newTextFieldCoords,
        startInputTextFieldFlag,
        addTextFieldToCanvas,
        nowDrawingSquareSection,
        nowDrawingCircleSection,
        nowDrawingTriangleSection,
        selectedElement,
        selectedElementFlag,
        lineSelectedFlag,
        changeLineRotate,
        currentLineSettings,
        changeLineStrokeStyle,
        changeLineStartStyle,
        changeLineEndStyle,
        changeLineStrokeWeight,
        deleteLine,
        changeCurveLineStrokeStyle,
        currentCurveLineSettings,
        curveLineSelectedFlag,
        changeCurveLineStartStyle,
        changeCurveLineEndStyle,
        changeCurveLineStrokeWeight,
        deleteCurveLine,
        brokenLineSelectedFlag,
        currentBrokenLineSettings,
        changeBrokenLineStrokeStyle,
        changeBrokenLineStartStyle,
        changeBrokenLineEndStyle,
        changeBrokenLineStrokeWeight,
        deleteBrokenLine,
        sinusoidLineSelectedFlag,
        currentSinusoidLineSettings,
        changeSinusoidLineStrokeStyle,
        changeSinusoidLineStartStyle,
        changeSinusoidLineEndStyle,
        changeSinusoidLineStrokeWeight,
        deleteSinusoidLine,
        currentTextSettings,
        textSelectedFlag,
        selectionOfText,
        changeTextFontSize,
        changeTextColor,
        changeTextFontWeight,
        changeTextTextDecoration,
        stopSelectionOfText,
        changeTextFontStyle,
        changeTextValue,
        changeComponentColor,
        selectedComponentSettingsFlag,
        selectedComponentSettings,
        changeComponentRotate,
        changeComponentScale,
        deletePresetComponent,
        fieldMode,
        selectedComponentForAddName,
        changeComponentPlayerNumber,

        sectionSelectedFlag,
        currentSectionSettings,
        changeSectionFill,
        changeSectionStroke,
        changeSectionStrokeWidth,
        deleteSection,

        changeLineColor,
        changeCurveLineColor,
        changeBrokenLineColor,
        changeSinusoidLineColor,

        isSchemeSaved
      }
    },
  
    components: {
      Field,
      PlayerOne,
      PlayerTwo,
      SmallPlayer,
      Ball,
      Flag,
      Dot,
      SchemasSelect,
      LinearDot,
      BaloonPlayer,
      MetalPlayer,
      Stairs,
      Stick,
      Barrier,
      ConeBarrier,
      RightGates,
      LeftGates,
      FrontGates,
      Cone,
      Coach,
      TopFrontGates,
      TopBackGates,
      TopLeftGates,
      TopRightGates,
      CleanField,
      HalfField,
      FullLinesField,
      FullLinesField2,
      FullLinesField3,
      FullLinesField4,
      FullLinesField5,
      FullLinesField6,
      FullLinesField7,
      AnotherHalfField,
      PenaltyZone,
      PenaltyZoneLines,
      Court,
      Circle
    }
  }
  </script>