<script>
import { ref, reactive, onMounted } from 'vue';
import FullCalendar from "@fullcalendar/vue3";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from "@fullcalendar/timegrid";
import esLocale from "@fullcalendar/core/locales/uk";
// import './main.css'

function getOptionName(value) {
    const options = {
        "null": "Не задано",
        "training": "Тренування",
        "gym": "Спортзал",
        "competition": "Змагання",
        "homework": "Домашнє завдання",
        "friendly-game": "Товариська гра",
        "championship": "Гра-чемпіонат",
        "trainer_meetup": "Нарада тренерів"
    };
    
    return options[value] || "Без типу";
}

function getOptionColor(value) {
    const options = {
        "null": "#CCC",
        "training": "#339989",
        "gym": "#DA4167",
        "competition": "#BD9135",
        "homework": "#339989",
        "friendly-game": "#BD9135",
        "championship": "#BD9135",
        "trainer_meetup": "#DA4167"
    };
    
    return options[value] || "Невідоме значення";
}

// Функция для получения пути к иконке на основе типа события
function getEventIcon(value) {
    const icons = {
        "null": require('@/desktopApp/assets/icons/green_ball.svg'), // Иконка для не заданного события
        "training": require('@/desktopApp/assets/icons/green_ball.svg'), // Иконка для тренировки
        "gym": require('@/desktopApp/assets/icons/red_dumbbell.svg'), // Иконка для спортзала
        "competition": require('@/desktopApp/assets/icons/gold_cup.svg'), // Иконка для соревнования
        "homework": require('@/desktopApp/assets/icons/homework_icon.svg'), // Иконка для домашнего задания
        "friendly-game": require('@/desktopApp/assets/icons/friendly_game_icon.svg'), // Иконка для товарской игры
        "championship": require('@/desktopApp/assets/icons/championship_icon.svg'), // Иконка для чемпионата
        "trainer_meetup": require('@/desktopApp/assets/icons/green_ball.svg'), // Иконка для встречи тренеров
    };
    
    return icons[value] || require('@/desktopApp/assets/icons/green_ball.svg'); // Стандартная иконка
}

export default {
  props: {
    allTrainings: {
      type: Array,
      default: () => [],
    },
  },

  setup(props, { emit }) {
    const fullCalendarRef = ref('null');
    const calendarEvents = reactive([]);
    const calendarOptions = {
      plugins: [dayGridPlugin, interactionPlugin, timeGridPlugin],
      locale: esLocale,
      headerToolbar: {
        left: "prev,next today",
        center: "title",
        right: "dayGridMonth,timeGridWeek,timeGridDay",
      },
      initialView: "dayGridMonth",
      initialEvents: props.allTrainings.map((training) => {
        return {
          title: `${training.group_label} (${getOptionName(training.type)})`, // Оставляем название
          start: training.date.split('T')[0] + 'T' + training.start_time,
          end: training.date.split('T')[0] + 'T' + training.end_time,
          className: 'success',
          color: getOptionColor(training.type),
          id: training.training_id,
          extendedProps: {
            icon: getEventIcon(training.type), // Устанавливаем путь к иконке в extendedProps
          },
        };
      }),
      editable: false,
      selectable: false,
      selectMirror: true,
      dayMaxEvents: true,
      weekends: true,
      droppable: false,
      eventReceive: handleEventReceive,
      select: function (arg) {
        console.log('select', arg);
      },
      eventClick: function (arg) {
        emit('setTrainingToView', props.allTrainings.find(training => training.training_id == arg.event.id));
      },
      eventContent: function (arg) {
        // Возвращаем HTML контент для события с иконкой
        const icon = `<img src="${arg.event.extendedProps.icon}" alt="event icon" class="event-icon" />`; // Вставляем SVG изображение
        const title = arg.event.title;
        return {
          html: `${icon} ${title}`,
        };
      },
    };

    function handleEventReceive(info) {
      if (info.draggedEl.classList.contains('fc-event')) {
        const title = info.draggedEl.querySelector('.fc-event-main').innerText;
        const className = info.draggedEl.className;
        info.draggedEl.remove();
      }
    }

    onMounted(() => {
      console.log('allTrainings', props.allTrainings);
    });

    return {
      fullCalendarRef,
      calendarOptions,
      handleEventReceive
    };
  },
  components: {
    FullCalendar,
  },
  data() {
    return {
      dataToPass: {
        current: "Full Calendar",
        list: [
          'Apps', 'Full Calendar'
        ]
      },
    };
  },
  methods: {
    setupDraggable() {
      new Draggable(document.getElementById("external-events"), {
        itemSelector: ".fc-event",
        eventData: (eventEl) => {
          let event = {
            title: eventEl.innerText,
            duration: "01:00"
          };
          return event;
        }
      });
    }
  },
  mounted() {
    // this.setupDraggable();
  },
};
</script>

<template>
  <div class="w-[90%] mx-auto">
    <FullCalendar ref="fullCalendarRef" id="calendar2" :editable="true" :droppable="true" :options="calendarOptions"
    @eventReceive="handleEventReceive" />
  </div>
</template>

<style lang="scss">
.fc-event-title {
  display: flex;
  align-items: center;
}

.fc-event-title i {
  margin-right: 8px; /* Добавляем небольшой отступ между иконкой и текстом */
}

$primary: rgb(70, 70, 70);
$primary-01: rgb(230, 204, 204, 0.1);
$primary-03: rgb(230, 204, 204, 0.3);
$primary-09: rgb(230, 204, 204, 0.9);
$light: #b46262;
$custom-white: #ffffff;
$default-border: #969696;


[dir="rtl"] {
  .fullcalendar-events-activity {
    li {
      padding-block-start: 0.25rem;
      // padding-inline-end: 2rem;
      padding-block-end: 0.25rem;
      padding-inline-start: 2rem;
    }
  }
}

.fc-today-button {
  margin-left: 20px !important;
}

.fc .fc-toolbar {
    height: 50px;
    color: #fff;
    background: transparent;
    border-radius: 0;
    margin: 0;
}

.fc .fc-toolbar h2 {
    font-size: 24px;
    line-height: 50px;
    margin: 0;
    text-transform: uppercase;
    font-weight: 100;
    color: #fff;
}

.fullcalendar-events-activity {
  li {
    margin-block-end: 0.75rem;
    font-size: 0.8125rem;
    padding-block-start: 0.25rem;
    padding-inline-end: 1rem;
    padding-block-end: 0.25rem;
    padding-inline-start: 2rem;
    position: relative;

    &::before {
      position: absolute;
      content: "";
      width: 0.75rem;
      height: 0.75rem;
      border: 0.125rem solid $primary-03;
      color: black;
      border-radius: 3.125rem;
      background-color: $custom-white;
      inset-inline-start: 0.25rem;
      inset-block-start: 0.563rem;
    }

    &::after {
      position: absolute;
      content: "";
      height: 100%;
      background-color: transparent;
      border-inline-end: 2px dashed $primary-01;
      color: black;
      inset-inline-start: 0.563rem;
      inset-block-start: 1.25rem;
    }

    &:last-child {
      margin-block-end: 0;

      &::after {
        border-inline-end: 0px dashed $default-border;
        color: black;
      }
    }
  }
}

#full-calendar-activity {
  max-height: 30rem;
  overflow-y: auto;
}

.fc-theme-standard .fc-scrollgrid.fc-scrollgrid-liquid {
  border: 0px !important;
  border-top: 1px solid $default-border !important;
  color: black;
}

.fc-daygrid-block-event .fc-event-time,
.fc-daygrid-block-event .fc-event-title {
  padding: 0 0.25rem !important;
}

.fc .fc-button-primary {
  background-color: $primary !important;
  border-color: $primary !important;
  color: black !important;
  &.fc-button-active {
    background-color: $primary-09 !important;
  }
}

.fc .fc-non-business {
  background: $custom-white !important;
}

.fc .fc-button-primary:focus,
.fc .fc-button-primary:not(:disabled).fc-button-active:focus,
.fc .fc-button-primary:not(:disabled):active:focus {
  box-shadow: none !important;
}

.fc-theme-standard td,
.fc-theme-standard th {
  border: 1px solid $default-border !important;
  border-top: 0px !important;
}

.fc-list-table { 
  td,
  th {
    border-left: 0 !important;
    border-right: 0 !important;
  }
}

.fc .fc-daygrid-day.fc-day-today {
  background-color: $primary-01 !important;
}

.fc-theme-standard .fc-list {
  border: 1px solid $default-border !important;
}

.fc .fc-list-event:hover td {
  background-color: $light !important;
}

.fc-timegrid-event-harness-inset .fc-timegrid-event,
.fc-timegrid-event.fc-event-mirror,
.fc-timegrid-more-link {
  box-shadow: none !important;
}

.fc-theme-standard .fc-list-day-cushion {
  background-color: $light !important;
}

.fc-theme-standard .fc-scrollgrid {
  border: 1px solid $default-border !important;
}

.fc-theme-bootstrap5 .fc-list,
.fc-theme-bootstrap5 .fc-scrollgrid,
.fc-theme-bootstrap5 td,
.fc-theme-bootstrap5 th {
  border: 1px solid $default-border !important;
}

@media (max-width: 420px) {
  .fc-scroller.fc-scroller-liquid {
    overflow: scroll !important;
  }
}
@media (max-width: 380px) {
  .fc .fc-daygrid-day-bottom {
    font-size: .75em !important;
    padding: 0px 3px 0 !important;
  }
  .fc .fc-daygrid-more-link {
    z-index: 99 !important;
  }
}

@media (max-width: 767.98px) {
  .fc .fc-toolbar {
    display: block !important;
  }

  .fc-toolbar-chunk {
    margin-block-start: 0.5rem;
  }
}

/* Start:: Full Calendar */
[dir=rtl] .fullcalendar-events-activity li {
  padding-block-start: 0.25rem;
  padding-block-end: 0.25rem;
  padding-inline-start: 2rem;
}

.fullcalendar-events-activity li {
  margin-block-end: 0.75rem;
  font-size: 0.8125rem;
  padding-block-start: 0.25rem;
  padding-inline-end: 1rem;
  padding-block-end: 0.25rem;
  padding-inline-start: 2rem;
  position: relative;
}
.fullcalendar-events-activity li::before {
  position: absolute;
  content: "";
  width: 0.75rem;
  height: 0.75rem;
  border: 0.125rem solid var(--primary03);
  border-radius: 3.125rem;
  background-color: var(--custom-white);
  inset-inline-start: 0.25rem;
  inset-block-start: 0.563rem;
}
.fullcalendar-events-activity li::after {
  position: absolute;
  content: "";
  height: 100%;
  background-color: transparent;
  border-inline-end: 2px dashed var(--primary01);
  inset-inline-start: 0.563rem;
  inset-block-start: 1.25rem;
}
.fullcalendar-events-activity li:last-child {
  margin-block-end: 0;
}
.fullcalendar-events-activity li:last-child::after {
  border-inline-end: 0px dashed var(--default-border);
}

#full-calendar-activity {
  max-height: 30rem;
  overflow-y: auto;
}

.fc .fc-button-primary.fc-button-active {
  background-color: #BD9135 !important;
  color: #000 !important;
}

.fc .fc-button-primary {
  color: #FFF !important;
}

.fc-theme-standard td, .fc-theme-standard th {
    border: 1px solid #313131 !important;
    border-top: 0px !important;
}

.fc-theme-standard .fc-scrollgrid.fc-scrollgrid-liquid {
    border: 0px !important;
    border-top: 1px solid #313131 !important;
    color: black;
    background-color: #474849;
}

/* End:: Full Calendar */

</style>