<!-- <template>
    <div >
        <div v-if="currentComponent != 'LoginView' && currentComponent != 'Loading'">
            <div class="flex">
                <Sidebar v-if="windowWidth > 1500" class="w-[258px] fixed" />
                <MinSidebar v-else class="w-[100px] fixed z-[250]"/>
                <div v-if="windowWidth > 1500" class="w-[278px]"></div>
                <div v-if="windowWidth <= 1500" class="w-[100px]"></div>
                <div class="w-full">
                    <Header />
                    <component :is="currentComponent"></component>
                </div>
            </div>
        </div>
        <div v-else>
            <component :is="currentComponent"></component>
        </div>
    </div>
</template> -->

<template>
    <div v-if="currentComponent != 'LoginView' && currentComponent != 'Loading'">
        <AsideMenu v-if="componentName != 'Login' && componentName != 'Loading'" :menuItems="menuItems" :onAsideArrow="onAsideArrow" @exit="confirmExit" />
        <div class="wrapper">
          <header v-if="componentName != 'Login' && componentName != 'Loading'" class="header">
            <div class="header__container">
              <h1 class="header__title">{{ pageName }}</h1>
              <div class="header__right">
                <button class="header__alerm alerm">
                  <img src="@/desktopApp/assets/refactored_assets/img/header/alerm.svg" alt="alerm" />
                  <img
                    class="alerm__new"
                    src="@/desktopApp/assets/refactored_assets/img/header/new.svg"
                    alt="new"
                  />
                </button>
                <hr class="header__divider" />
                <div class="header__user">
                  <div class="header__user-name">
                    <div>{{ store.trainer.name }}</div>
                    <div>{{ store.trainer.surname }}</div>
                  </div>
                  <AvatarContainer :label="store.trainer.name[0] + store.trainer.surname[0]" alt="user" small />
                </div>
              </div>
            </div>
          </header>
          <component class="bg-[#1E1E1E]" :is="currentComponent"></component>
          <footer class="footer"></footer>
        </div>
    </div>
    <div v-else>
        <component :is="currentComponent"></component>
    </div>
</template>

<script>
    import MainView from '../views/MainView.vue'
    import LoginView from '../views/LoginView.vue'
    import Sidebar from '../components/Sidebar.vue'
    import MinSidebar from '../components/MinSidebar.vue'
    import Header from '../components/Header.vue'
    import AsideMenu from '../components/AsideMenu.vue'

    import StudentsView from '../views/Students/Students.vue'
    import GroupsView from '../views/Groups/Groups.vue'
    import FinanceView from '../views/FinanceView.vue'
    import TrainingsView from '../views/TrainingsView.vue'
    import SettingsView from '../views/SettingsView.vue'
    import TrainingConstructor from '../views/TrainingSummary/TrainingConstructor/TrainingConstructor.vue'
    import TrainingPrograms from '../views/TrainingSummary/TrainingPrograms/TrainingPrograms.vue'
    import TrainingSchemes from '../views/TrainingSummary/TrainingSchemes/TrainingSchemes.vue'
    import TrainingElements from '../views/TrainingSummary/TrainingConstructor/TrainingElements/TrainingElements.vue'
    import Trainings from '../views/TrainingSummary/TrainingConstructor/Trainings/Trainings.vue'
    import TrainingProgramView from '../views/TrainingSummary/TrainingPrograms/TrainingProgramView.vue'
    import ElementForm from '../views/TrainingSummary/TrainingElementForm/ElementForm.vue'
    import ElementUpdateForm from '../views/TrainingSummary/TrainingElementForm/ElementUpdateForm.vue'
    import TrainingCreationForm from '../views/TrainingSummary/TrainingCreationForm/TrainingCreationForm.vue'
    import TrainingUpdateForm from '../views/TrainingSummary/TrainingCreationForm/TrainingUpdateForm.vue'
    import TrainingProgramCreationForm from '../views/TrainingSummary/TrainingPrograms/TrainingProgramCreationForm.vue'
    import TrainingProgramUpdateForm from '../views/TrainingSummary/TrainingPrograms/TrainingProgramUpdateForm.vue'
    import TrainingSummaryAddons from '../views/TrainingSummary/Addons/TrainingSummaryAddons.vue'
    import Matches from '../views/MatchCenter/Matches/Matches.vue'
    import Teams from '../views/MatchCenter/Teams/Teams.vue'
    import MatchReportForm from '../views/MatchCenter/Matches/components/MatchReportForm.vue'
    import PlayerMatchReportForm from '../views/MatchCenter/Matches/components/PlayerMatchReportForm.vue'
    import PlayerMatchMarkView from '../views/Students/components/NewFullStudentCard/components/PlayerMatchMarkView.vue'
    import AddGpsReportForMatch from '../views/GpsReports/AddGpsReportForMatch.vue'
    import SchemasTable from '../views/TrainingSummary/TrainingSchemes/SchemasTable.vue'

    import Calendar from '../views/Calendar/Calendar.vue'

    import { uuid } from 'vue3-uuid'
    import { ref } from 'vue'
    import { useRouter, useRoute } from 'vue-router'
    import { onMounted, onUpdated } from 'vue'

    import { getScoreSettings } from '@/desktopApp/services/apiRequests' 
    import { useGlobalStore } from '@/desktopApp/storage/globalStorage'
    import { useMainStore } from '../stores/mainStore'

    export default {
        name: 'MainLayout',
        
        setup() {
            const currentComponent = ref('LoginView')
            const router = useRouter()
            const route = useRoute()
            const windowWidth = ref(window.innerWidth)
            const headerKey = ref(uuid.v4())

            const globalStorage = useGlobalStore()

            const store = useMainStore()

            const menuItems = ref([
                {
                    link: "/",
                    name: "Дашбоард",
                    icon: "players.svg",
                    isActive: false,
                },
                {
                    link: "/groups",
                    name: "Групи",
                    icon: "players.svg",
                    isActive: false,
                },
                {
                    link: "",
                    name: "Тренув. процес",
                    icon: "training_proccess_icon.svg",
                    isActive: false,
                    sublinks: [
                        {
                            link: "/training-programs",
                            name: "Програми",
                            isActive: false,
                        },
                        {
                            link: "/training-elements",
                            name: "Елементи",
                            isActive: false,
                        },
                        {
                            link: "/trainings-constructor",
                            name: "Тренування",
                            isActive: false,
                        },
                        {
                            link: "/training-schemes",
                            name: "Редактор схем",
                            isActive: false,
                        }
                    ],
                    sublinksView: false
                },
                {
                    link: "/match-center/matches",
                    name: "Матч-центр",
                    icon: "training_programs_icon.svg",
                    isActive: false,
                },
                {
                    link: "/students",
                    name: "Учні",
                    icon: "players.svg",
                    isActive: false,
                },
                {
                    link: "/trainings",
                    name: "Календар",
                    icon: "calendar.svg",
                    isActive: false,
                },

            ])

            onMounted(async () => {
                if (localStorage.access_token && localStorage.refresh_token) {                    
                    currentComponent.value = route.meta.component

                    const scoreSettingsResponse = await getScoreSettings()
                    globalStorage.$state.scoringRadarChartSettings = scoreSettingsResponse
                } else {
                    currentComponent.value = 'LoginView'
                }

                window.addEventListener('resize', () => {
                    windowWidth.value = window.innerWidth
                })
            })

            onUpdated(() => {
                if (localStorage.access_token && localStorage.refresh_token) {
                    currentComponent.value = route.meta.component
                    headerKey.value = uuid.v4()
                }
            })

            let isMenuOpen = ref(false);
            
            const onAsideArrow = () => {
                isMenuOpen.value = !isMenuOpen.value;
                document.body.classList.toggle("menu-open");
            };

            return {
                currentComponent,
                windowWidth,
                headerKey,
                onAsideArrow,
                isMenuOpen,
                menuItems,
                store
            }
            
        },

        components: {
            MainView,
            LoginView,
            MinSidebar,
            Sidebar,
            StudentsView,
            GroupsView,
            FinanceView,
            TrainingsView,
            SettingsView,
            Header,
            Calendar,
            TrainingConstructor,
            TrainingPrograms,
            TrainingSchemes,
            TrainingElements,
            Trainings,
            TrainingProgramView,
            ElementForm,
            ElementUpdateForm,
            TrainingCreationForm,
            TrainingUpdateForm,
            TrainingProgramCreationForm,
            TrainingProgramUpdateForm,
            TrainingSummaryAddons,
            Matches,
            Teams,
            MatchReportForm,
            PlayerMatchReportForm,
            PlayerMatchMarkView,
            AsideMenu,
            AddGpsReportForMatch,
            SchemasTable
        }
    }
</script>