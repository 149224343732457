<template>
    <DeleteElementForm :element="elementForDelete" @close="showDeletePopup = false" @acceptDeleteElement="deleteElement(elementForDelete)" v-if="showDeletePopup" />
    <ElementFilter v-if="filterFlag" :filter="filters" @dropFilters="dropFilters" @setFilters="e => setFilters(e)" @close="filterFlag = false" />
    <div class="overflow-y-auto mt-[10px]">

        <div class="flex justify-between px-[84px]">
            <!-- <div class="search-bar flex items-center border-[#FFF] border-[1px] bg-[#FFF] bg-opacity-30 px-[10px] rounded-[6px]">
                <img src="@/desktopApp/assets/icons/magnify_icon.svg" class="w-3 h-3">
                <input v-model="searchQuery" class="text-[#FFF] text-[14px] bg-transparent rounded-md px-2 py-[3.5px] placeholder:text-[#FFF] outline-none w-[250px] " placeholder="Пошук..." type="text">
            </div> -->

            <InputComponent :value="searchQuery" @update:modelValue="e => searchQuery = e" placeholder="Пошук..." class="w-[250px]"/>

            <div class="w-full ml-[30px] flex items-center ">
                <div class="text-[#8C8C8C] w-[250px] text-start font-medium relative text-[14px]">
                    Відображення елементів
                    <span v-if="elementsViewTable" class="text-[#8FC408]">таблиця</span>
                    <span v-else class="text-[#FA1367]">групи</span>
                </div>
                <div class="ml-[20px] relative bottom-[4px]">
                    <IphoneSwitch :isOnValue="elementsViewTable" @change="elementsViewTable = !elementsViewTable"/>
                </div>
            </div>
            <div class="w-max flex justify-end">
                <div @click="filterFlag = true" class="flex items-center cursor-pointer">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M8.85746 12.5061C6.36901 10.6456 4.59564 8.59915 3.62734 7.44867C3.3276 7.09253 3.22938 6.8319 3.17033 6.3728C2.96811 4.8008 2.86701 4.0148 3.32795 3.5074C3.7889 3 4.60404 3 6.23433 3H17.7657C19.396 3 20.2111 3 20.672 3.5074C21.133 4.0148 21.0319 4.8008 20.8297 6.37281C20.7706 6.83191 20.6724 7.09254 20.3726 7.44867C19.403 8.60062 17.6261 10.6507 15.1326 12.5135C14.907 12.6821 14.7583 12.9567 14.7307 13.2614C14.4837 15.992 14.2559 17.4876 14.1141 18.2442C13.8853 19.4657 12.1532 20.2006 11.226 20.8563C10.6741 21.2466 10.0043 20.782 9.93278 20.1778C9.79643 19.0261 9.53961 16.6864 9.25927 13.2614C9.23409 12.9539 9.08486 12.6761 8.85746 12.5061Z" stroke="#FFF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </div>
                <div class="ml-[20px] flex items-center w-[110px] justify-between mr-[140px] text-[#FFF]">
                    <img @click="prevPage" src="@/desktopApp/assets/icons/left_arrow.svg" class="cursor-pointer">
                    <div class="flex mx-[20px]">Сторінка: <input v-model="page" type="number" class="bg-transparent mx-2 w-[5ch] outline-none px-1"> / <span class="ml-[10px]">{{ maxPage }}</span></div>
                    <img @click="nextPage" src="@/desktopApp/assets/icons/right_arrow.svg" class="cursor-pointer">
                </div>
                <!-- <div @click="$router.push('/new-training-element')" class="bg-[#BD9135] flex justify-center items-center w-[228px] h-[38px] cursor-pointer text-white font-semibold py-[5px] rounded-lg">
                    <span>Додати елемент</span>
                </div> -->
                <div class="w-[228px]">
                    <ButtonComponent @click="() => $router.push('/new-training-element')">Додати</ButtonComponent>
                </div>
            </div>
        </div>

        <div class="border-b-[1px] border-white w-[90%] mx-auto flex justify-end mt-[16px] mb-[16px]">
            <div class="text-white ml-[20px]">
                <span class="text-[28px] font-semibold">{{ statistics.task }}</span> завдань
            </div>
            <div class="text-white ml-[20px]">
                <span class="text-[28px] font-semibold">{{ statistics.standard }}</span> тестування
            </div>
            <div class="text-white ml-[20px]">
                <span class="text-[28px] font-semibold">{{ statistics.exercise }}</span> вправи
            </div>
        </div>

        <keep-alive>
        <div v-if="elementsViewTable" class=" w-[90%] mx-auto mt-3 mb-5 rounded-[10px] overflow-y-auto">
            <table class="w-full">
                <thead class="text-white select-none">
                    <tr class="border-b-[1px] border-[#FFF] border-opacity-10 text-white">
                        <th class="px-3 py-2 w-[5%] text-center font-bold text-sm"></th>
                        <th class="px-3 py-2 w-[20%] text-start font-bold text-sm">Назва елементу</th>
                        <th class="px-3 py-2 w-[10%] text-center font-bold text-sm">Елемент</th>
                        <th class="px-3 py-2 w-[10%] text-end font-bold text-sm">Вік. кат.</th>
                        <th class="w-[15%] py-2 cursor-pointer select-none text-end font-bold text-sm">
                            Тривалість
                        </th>
                        <th class="w-[5%]" ></th>
                        <th class="px-3 py-2 text-end w-[204px] relative font-bold text-sm">
                            Направленість
                        </th>
                        <th class="px-3 w-[25%] py-2 text-end font-bold text-sm"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="element in trainingElements" v-if="trainingElements.length" class="border-b-[1px] border-[#FFF] border-opacity-10 text-white">
                        <td class="px-3 py-2 w-[5%] text-center font-medium text-sm">
                            <div class="rounded-full border-[1px] bg-[#BD9135] border-white w-[50px] h-[50px] flex justify-center items-center text-center align-middle">
                                <span class="text-[21px]">{{ element.name ? element.name[0].toUpperCase() : 'N' }}</span>
                            </div>
                        </td>
                        <td class="px-3 py-2 w-[20%] text-start font-medium text-sm">{{ element.name ? element.name : 'Немає назви' }}</td>
                        <td class="px-3 py-2 w-[10%] text-center font-medium text-sm text-[#BD9135]">{{ parseElementType(element.type) }}</td>
                        <td class="px-3 py-2 w-[10%] text-end font-medium text-sm">{{ parseAgeGroup(element.training_program_years) }}</td>
                        <td class="w-[15%] py-2 select-none text-end font-medium text-sm">
                            {{ element.time ?? 'Налаштовувана' }}
                        </td>
                        <td class="w-[5%]" ></td>
                        <td class="px-3 py-2 text-end w-[204px] relative font-medium text-sm">
                            {{ element.element_direction ? parseElementDirection(element.element_direction) : '-' }}
                        </td>
                        <td class="px-3 w-[25%] py-2 text-end font-medium text-sm">
                            <button 
                                class="py-1 rounded-[10px] text-sm font-medium mr-[20px]"
                                @click="$router.push(`/training-element/${element.element_id}`)"
                            >
                                <img 
                                    src="@/desktopApp/assets/icons/pen_icon.svg" 
                                    class="w-6 h-6"
                                >
                            </button>
                            <button 
                                class="py-1 rounded-[10px] text-sm font-medium text-red-600"
                                @click="elementForDelete = element; showDeletePopup = true"
                            >
                                <img 
                                    src="@/desktopApp/assets/icons/trash_icon.svg" 
                                    class="w-6 h-6"   
                                >
                            </button>
                        </td>
                    </tr>
                    <tr v-else>
                        <td class="px-3 py-2 w-[20%] text-center font-bold text-white" colspan="8">Немає елементів</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div v-else class="my-[20px]">
            <div v-if="!selectedGroupAgeGroup">
                <div v-for="el in 18" @click="selectedGroupAgeGroup = `${el + 5}_years`" class="bg-[#BD9135] flex justify-center items-center w-[60%] mt-[20px] mx-auto h-[38px] cursor-pointer text-white font-semibold py-[5px] rounded-lg">
                    <span>Вікова категорія: <span class="text-black">{{el + 5}} років</span></span>
                </div>
                <div @click="selectedGroupAgeGroup = 'main_team'" class="bg-[#BD9135] flex justify-center items-center w-[60%] mt-[20px] mx-auto h-[38px] cursor-pointer text-white font-semibold py-[5px] rounded-lg">
                    <span><span class="text-black">Основна команда</span></span>
                </div>
            </div>
            <div v-else-if="selectedGroupAgeGroup && !selectedGroupElementType && !selectedElementDirection" class="w-[90%] mx-auto">
                <div @click="selectedGroupAgeGroup = null" class="bg-[#BD9135] flex justify-center items-center w-[228px] h-[38px] cursor-pointer text-white font-semibold py-[5px] rounded-lg">
                    <span>Назад</span>
                </div>
                <div @click="selectedGroupElementType = 'standard'" class="bg-[#BD9135] flex justify-center items-center w-[60%] mt-[20px] mx-auto h-[38px] cursor-pointer text-white font-semibold py-[5px] rounded-lg">
                    <span><span class="text-black">Норматив</span></span>
                </div>
                <div @click="selectedGroupElementType = 'exercise'" class="bg-[#BD9135] flex justify-center items-center w-[60%] mt-[20px] mx-auto h-[38px] cursor-pointer text-white font-semibold py-[5px] rounded-lg">
                    <span><span class="text-black">Вправа</span></span>
                </div>
                <div @click="selectedGroupElementType = 'task'" class="bg-[#BD9135] flex justify-center items-center w-[60%] mt-[20px] mx-auto h-[38px] cursor-pointer text-white font-semibold py-[5px] rounded-lg">
                    <span><span class="text-black">Завдання</span></span>
                </div>
                <div @click="selectedGroupElementType = 'warmup_ofp'" class="bg-[#BD9135] flex justify-center items-center w-[60%] mt-[20px] mx-auto h-[38px] cursor-pointer text-white font-semibold py-[5px] rounded-lg">
                    <span><span class="text-black">Розминка (ОФП)</span></span>
                </div>
                <div @click="selectedGroupElementType = 'warmup_sfp'" class="bg-[#BD9135] flex justify-center items-center w-[60%] mt-[20px] mx-auto h-[38px] cursor-pointer text-white font-semibold py-[5px] rounded-lg">
                    <span><span class="text-black">Розминка (СФП)</span></span>
                </div>
                <div @click="selectedGroupElementType = 'hitch_ofp'" class="bg-[#BD9135] flex justify-center items-center w-[60%] mt-[20px] mx-auto h-[38px] cursor-pointer text-white font-semibold py-[5px] rounded-lg">
                    <span><span class="text-black">Заминка (ОФП)</span></span>
                </div>
            </div>
            <div v-else-if="selectedGroupElementType == 'task' && !selectedElementDirection" class=" w-[90%] mx-auto mt-3 mb-5 rounded-[10px] overflow-y-auto">
                <div @click="selectedGroupElementType = null" class="bg-[#BD9135] flex justify-center mb-[20px] items-center w-[228px] h-[38px] cursor-pointer text-white font-semibold py-[5px] rounded-lg">
                    <span>Назад</span>
                </div>

                <div @click="selectedElementDirection = 'physical'" class="bg-[#BD9135] flex justify-center items-center w-[60%] mt-[20px] mx-auto h-[38px] cursor-pointer text-white font-semibold py-[5px] rounded-lg">
                    <span><span class="text-black">Фізичне</span></span>
                </div>
                <div @click="selectedElementDirection = 'tactical'" class="bg-[#BD9135] flex justify-center items-center w-[60%] mt-[20px] mx-auto h-[38px] cursor-pointer text-white font-semibold py-[5px] rounded-lg">
                    <span><span class="text-black">Тактичне</span></span>
                </div>
                <div @click="selectedElementDirection = 'technical'" class="bg-[#BD9135] flex justify-center items-center w-[60%] mt-[20px] mx-auto h-[38px] cursor-pointer text-white font-semibold py-[5px] rounded-lg">
                    <span><span class="text-black">Технічне</span></span>
                </div>
                <div @click="selectedElementDirection = 'tech_tactical'" class="bg-[#BD9135] flex justify-center items-center w-[60%] mt-[20px] mx-auto h-[38px] cursor-pointer text-white font-semibold py-[5px] rounded-lg">
                    <span><span class="text-black">Техніко/тактичне</span></span>
                </div>
                <div @click="selectedElementDirection = 'all'" class="bg-[#BD9135] flex justify-center items-center w-[60%] mt-[20px] mx-auto h-[38px] cursor-pointer text-white font-semibold py-[5px] rounded-lg">
                    <span><span class="text-black">Всі</span></span>
                </div>
            </div>
            <div v-else class=" w-[90%] mx-auto mt-3 mb-5 rounded-[10px] overflow-y-auto">
                <div @click="() => {selectedGroupElementType = null; selectedElementDirection = null}" class="bg-[#BD9135] flex justify-center mb-[20px] items-center w-[228px] h-[38px] cursor-pointer text-white font-semibold py-[5px] rounded-lg">
                    <span>Назад</span>
                </div>
                <table class="w-full">
                    <thead class="text-white select-none">
                        <tr class="border-b-[1px] border-[#FFF] border-opacity-10 text-white">
                            <th class="px-3 py-2 w-[5%] text-center font-bold text-sm"></th>
                            <th class="px-3 py-2 w-[20%] text-start font-bold text-sm">Назва елементу</th>
                            <th class="px-3 py-2 w-[10%] text-center font-bold text-sm">Елемент</th>
                            <th class="px-3 py-2 w-[10%] text-end font-bold text-sm">Вік. кат.</th>
                            <th class="w-[15%] py-2 cursor-pointer select-none text-end font-bold text-sm">
                                Тривалість
                            </th>
                            <th class="w-[5%]" ></th>
                            <th class="px-3 py-2 text-end w-[204px] relative font-bold text-sm">
                                Направленість
                            </th>
                            <th class="px-3 w-[25%] py-2 text-end font-bold text-sm"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="element in trainingElements" v-if="trainingElements.length" class="border-b-[1px] border-[#FFF] border-opacity-10 text-white">
                            <td class="px-3 py-2 w-[5%] text-center font-medium text-sm">
                                <div class="rounded-full border-[1px] bg-[#BD9135] border-white w-[50px] h-[50px] flex justify-center items-center text-center align-middle">
                                    <span class="text-[21px]">{{ element.name ? element.name[0].toUpperCase() : 'N' }}</span>
                                </div>
                            </td>
                            <td class="px-3 py-2 w-[20%] text-start font-medium text-sm">{{ element.name ? element.name : 'Немає назви' }}</td>
                            <td class="px-3 py-2 w-[10%] text-center font-medium text-sm text-[#BD9135]">{{ parseElementType(element.type) }}</td>
                            <td class="px-3 py-2 w-[10%] text-end font-medium text-sm">{{ parseAgeGroup(element.training_program_years) }}</td>
                            <td class="w-[15%] py-2 select-none text-end font-medium text-sm">
                                {{ element.time ?? 'Налаштовувана' }}
                            </td>
                            <td class="w-[5%]" ></td>
                            <td class="px-3 py-2 text-end w-[204px] relative font-medium text-sm">
                                {{ element.element_direction ? parseElementDirection(element.element_direction) : '-' }}
                            </td>
                            <td class="px-3 w-[25%] py-2 text-end font-medium text-sm">
                                <button 
                                    class="py-1 rounded-[10px] text-sm font-medium mr-[20px]"
                                    @click="$router.push(`/training-element/${element.element_id}`)"
                                >
                                    <img 
                                        src="@/desktopApp/assets/icons/pen_icon.svg" 
                                        class="w-6 h-6"
                                    >
                                </button>
                                <button 
                                    class="py-1 rounded-[10px] text-sm font-medium text-red-600"
                                    @click="elementForDelete = element; showDeletePopup = true"
                                >
                                    <img 
                                        src="@/desktopApp/assets/icons/trash_icon.svg" 
                                        class="w-6 h-6"   
                                    >
                                </button>
                            </td>
                        </tr>
                        <tr v-else>
                            <td class="px-3 py-2 w-[20%] text-center font-bold text-white" colspan="8">Немає елементів</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        </keep-alive>
    </div>
</template>

<script>
    import { ref, onMounted, watch } from 'vue'
    import { getAllTrainingElementsRequest, deleteElementByIdRequest, getStatisticsByElements } from '@/desktopApp/services/apiRequests.js'
    import DeleteElementForm from './components/DeleteElementForm.vue'
    import ElementFilter from './components/ElementFilter.vue'
    import IphoneSwitch from './components/IphoneSwitch.vue'
import ButtonComponent from '@/desktopApp/components/uikit/Button/ButtonComponent.vue'
import InputComponent from '@/desktopApp/components/uikit/Input/InputComponent.vue'

    export default {
        name: 'TrainingElements',

        setup() {
            const trainingElements = ref([])
            const elementsCount = ref(20)
            const elementSkip = ref(0)
            const elementForDelete = ref(null)
            const showDeletePopup = ref(false)
            const statistics = ref({
                standard: 0,
                exercise: 0,
                task: 0
            })

            const searchQuery = ref('')

            const page = ref(1)
            const maxPage = ref(1)

            const elementsViewTable = ref(true)
            const selectedGroupAgeGroup = ref(null)
            const selectedGroupElementType = ref(null)
            const selectedElementDirection = ref(null)

            watch(elementsViewTable, async (value) => {
                filters.value = {
                    elementType: 'all',
                    ageCategory: 'all',
                    assessmentType: 'all',
                    direction: 'all',
                    timeSort: 'none',
                }

                selectedGroupAgeGroup.value = null
                selectedGroupElementType.value = null
                selectedElementDirection.value = null

                await updateElements()
            })

            const parseElementDirection = (direction) => {
                switch (direction) {
                    case 'physical':
                        return 'Фізичне'
                    case 'tactical':
                        return 'Тактичне'
                    case 'technical':
                        return 'Технічне'
                    case 'tech_tactical':
                        return 'Техніко/тактичне'
                    default:
                        return 'Невідома направленість'
                }
            }

            watch(selectedGroupElementType, async (value) => {
                if (value) {
                    const response = await getAllTrainingElementsRequest(20 * page.value, 20 * (page.value - 1), {
                        elementType: selectedGroupElementType.value ?? 'all',
                        ageCategory: selectedGroupAgeGroup.value ?? 'all',
                        assessmentType: 'all',
                        direction: selectedElementDirection.value ?? 'all',
                        timeSort: 'none',
                    }, searchQuery.value)
                    filters.value = {
                        elementType: selectedGroupElementType.value ?? 'all',
                        ageCategory: selectedGroupAgeGroup.value ?? 'all',
                        assessmentType: 'all',
                        direction: selectedElementDirection.value ?? 'all',
                        timeSort: 'none',
                    }
                    console.log('filters.value ------- ', filters.value)
                    trainingElements.value = response
                }
            })
            watch(selectedElementDirection, async (value) => {
                if (value) {
                    const response = await getAllTrainingElementsRequest(20 * page.value, 20 * (page.value - 1), {
                        elementType: selectedGroupElementType.value ?? 'all',
                        ageCategory: selectedGroupAgeGroup.value ?? 'all',
                        assessmentType: 'all',
                        direction: selectedElementDirection.value ?? 'all',
                        timeSort: 'none',
                    }, searchQuery.value)
                    filters.value = {
                        elementType: selectedGroupElementType.value ?? 'all',
                        ageCategory: selectedGroupAgeGroup.value ?? 'all',
                        assessmentType: 'all',
                        direction: selectedElementDirection.value ?? 'all',
                        timeSort: 'none',
                    }
                    console.log('filters.value ------- ', filters.value)
                    trainingElements.value = response
                }
            })

            const filters = ref({
                elementType: 'all',
                ageCategory: 'all',
                assessmentType: 'all',
                direction: 'all',
                timeSort: 'none',
            })

            const filterFlag = ref(false)

            const prevPage = () => {
                page.value > 1 ? page.value-- : page.value = 1
            }

            const nextPage = () => {
                maxPage.value >= page.value + 1 ? page.value++ : page.value = maxPage.value
            }

            watch(page, async (value) => {
                await updateElements()
            })

            watch(searchQuery, async (value) => {
                await updateElements()
            })

            const setFilters = async (newFilters) => {
                console.log('newFilters --- ', newFilters)
                filters.value = newFilters
                filterFlag.value = false

                console.log(filters.value)

                await updateElements()
            }

            const dropFilters = async () => {
                filters.value = {
                    elementType: 'all',
                    ageCategory: 'all',
                    assessmentType: 'all',
                    direction: 'all',
                    timeSort: 'none',
                }
                filterFlag.value = false

                await updateElements()
            }

            const parseElementType = (type) => {
                switch (type) {
                    case 'standard':
                        return 'Норматив'
                    case 'exercise':
                        return 'Вправа'
                    case 'task':
                        return 'Завдання'
                    case 'warmup_ofp':
                        return 'Розминка (ОФП)'
                    case 'warmup_sfp':
                        return 'Розминка (СФП)'
                    case 'hitch_ofp':
                        return 'Заминка (ОФП)'
                    default:
                        return 'Невідомий тип елементу'
                }
            }

            const parseAgeGroup = (ageGroup) => {
                switch (ageGroup) {
                    case '6_years':
                        return '6 років'
                    case '7_years':
                        return '7 років'
                    case '8_years':
                        return '8 років'
                    case '9_years':
                        return '9 років'
                    case '10_years':
                        return '10 років'
                    case '11_years':
                        return '11 років'
                    case '12_years':
                        return '12 років'
                    case '13_years':
                        return '13 років'
                    case '14_years':
                        return '14 років'
                    case '15_years':
                        return '15 років'
                    case '16_years':
                        return '16 років'
                    case '17_years':
                        return '17 років'
                    case '18_years':
                        return '18 років'
                    case '19_years':
                        return '19 років'
                    case '20_years':
                        return '20 років'
                    case '21_years':
                        return '21 рік'
                    case '22_years':
                        return '22 роки'
                    case '23_years':
                        return '23 роки'
                    case 'main_team':
                        return 'Основний склад'
                    default:
                        return 'Невідома вікова категорія'
                }
            }

            const parseScoring = (scoring) => {
                switch (scoring) {
                    case 'time':
                        return 'Час'
                    case 'point':
                        return 'Оцінка'
                    case 'length':
                        return 'Відстань'
                    case 'count':
                        return 'Кількість'
                    case 'coefficient':
                        return 'Коефіцієнт'
                    default:
                        return '-'
                }
            }

            const deleteElement = async (element) => {
                await deleteElementByIdRequest(element.element_id)
                await updateElements()
                showDeletePopup.value = false
            }

            const updateElements = async () => {
                const response = await getAllTrainingElementsRequest(20 * page.value, 20 * (page.value - 1), filters.value, searchQuery.value)
                console.log('response --- ', response)
                console.log('filters.value --- ', filters.value)
                trainingElements.value = response
            }

            const getStatistics = async () => {
                const response = await getStatisticsByElements()
                statistics.value = response
            }

            onMounted(async () => {
                await updateElements()
                await getStatistics()

                maxPage.value = Math.ceil((statistics.value.standard + statistics.value.exercise + statistics.value.task) / 20)
            })

            return {
                trainingElements,
                parseElementType,
                parseAgeGroup,
                parseScoring,
                elementForDelete,
                showDeletePopup,
                deleteElement,
                statistics,
                page,
                prevPage,
                nextPage,
                maxPage,
                filterFlag,
                filters,
                setFilters,
                searchQuery,
                dropFilters,
                elementsViewTable,
                selectedGroupAgeGroup,
                selectedGroupElementType,
                selectedElementDirection,
                parseElementDirection
            }
        },

        components: {
            DeleteElementForm,
            ElementFilter,
            IphoneSwitch
        },
    }
</script>

<style lang="scss" scoped>

</style>