<template>
    <div class="relative w-full">
        <AddTrainingForm v-if="openTrainingFormFlag" @addTrainingsSummaries="addSummariesToMicrocycle" @close="openTrainingFormFlag = false" />
        <div class="flex justify-end px-[30px] mb-[15px]">
            <div @click="$router.push('/training-programs')" class="bg-[#BD9135] mr-[15px] flex justify-center items-center w-[228px] h-[38px] cursor-pointer text-white font-semibold py-[5px] rounded-lg">
                <span>Назад</span>
            </div>
            <div @click="saveProgram" class="bg-[#BD9135] flex justify-center items-center w-[228px] h-[38px] cursor-pointer text-white font-semibold py-[5px] rounded-lg">
                <span>Зберегти програму</span>
            </div>
        </div>
        <div class="flex flex-col items-center">
            <div class="bg-[#DA4167] relative text-white flex justify-center items-center w-[70%] py-[10px] rounded-xl" :class="{'border-[1px] border-[#BD9135]' : updateProgramNameFlag}">
                <div class="font-black w-full flex justify-center">
                    <div v-if="updateProgramNameFlag">
                        <input v-model="programStructure.name" class="w-[100%] outline-none bg-transparent text-white text-center" type="text" placeholder="Назва програми">
                    </div>
                    <div class="w-full flex justify-center" v-else>{{ programStructure.name ? programStructure.name : 'Введіть назву програми' }}</div>
                </div>
                <div class="absolute right-[10px]">
                    <svg v-if="updateProgramNameFlag" @click="updateProgramNameFlag = false" class="cursor-pointer" width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="13" cy="13" r="13" fill="#339989"/>
                        <line x1="13" y1="5" x2="13" y2="21" stroke="white" stroke-width="2"/>
                        <line x1="5" y1="13" x2="21" y2="13" stroke="white" stroke-width="2"/>
                    </svg>
                    <svg v-else @click="updateProgramNameFlag = true" class="cursor-pointer" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12 20H21" stroke="#FFF" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M16.5 3.49998C16.8978 3.10216 17.4374 2.87866 18 2.87866C18.2786 2.87866 18.5544 2.93353 18.8118 3.04014C19.0692 3.14674 19.303 3.303 19.5 3.49998C19.697 3.69697 19.8532 3.93082 19.9598 4.18819C20.0665 4.44556 20.1213 4.72141 20.1213 4.99998C20.1213 5.27856 20.0665 5.55441 19.9598 5.81178C19.8532 6.06915 19.697 6.303 19.5 6.49998L7 19L3 20L4 16L16.5 3.49998Z" stroke="#FFF" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </div>
            </div>
            <div class="mt-[10px] cursor-pointer select-none">
                <svg @click="addSeason()" width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="13" cy="13" r="13" fill="#BD9135"/>
                    <line x1="13" y1="5" x2="13" y2="21" stroke="white" stroke-width="2"/>
                    <line x1="5" y1="13" x2="21" y2="13" stroke="white" stroke-width="2"/>
                </svg>
            </div>
        </div>
        <div class="flex w-full justify-center select-none overflow-x-auto">
            <div v-for="season in programStructure.seasons" class="w-[25%] min-w-[300px] px-[10px]">
                <div class="flex flex-col items-center mt-[10px]">
                    <div 
                        @click="selectedSeason = season.id" 
                        class="bg-[#BD9135] text-white w-[100%] py-[20px] rounded-xl flex flex-col items-center relative"
                        :class="{
                            'border-[5px] border-[#7C5F22]' : selectedSeason === season.id,
                            'm-[5px]' : selectedSeason != season.id
                        }"    
                    >
                        <!-- <div class="font-black">{{ meso.name }}</div> -->

                        <div class="font-black w-full flex justify-center">
                            <div v-if="season.update">
                                <input v-model="season.name" class="w-[100%] outline-none bg-transparent text-white text-center" type="text" placeholder="Назва програми">
                            </div>
                            <div class="w-full flex justify-center" v-else>{{ season.name ? season.name : 'Введіть назву програми' }}</div>
                        </div>
                        <div class="absolute right-[10px]">
                            <svg v-if="season.update" @click="season.update = false" class="cursor-pointer mr-4" width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="13" cy="13" r="13" fill="#339989"/>
                                <line x1="13" y1="5" x2="13" y2="21" stroke="white" stroke-width="2"/>
                                <line x1="5" y1="13" x2="21" y2="13" stroke="white" stroke-width="2"/>
                            </svg>
                            <svg v-else @click="season.update = true" class="cursor-pointer mr-4" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12 20H21" stroke="#FFF" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M16.5 3.49998C16.8978 3.10216 17.4374 2.87866 18 2.87866C18.2786 2.87866 18.5544 2.93353 18.8118 3.04014C19.0692 3.14674 19.303 3.303 19.5 3.49998C19.697 3.69697 19.8532 3.93082 19.9598 4.18819C20.0665 4.44556 20.1213 4.72141 20.1213 4.99998C20.1213 5.27856 20.0665 5.55441 19.9598 5.81178C19.8532 6.06915 19.697 6.303 19.5 6.49998L7 19L3 20L4 16L16.5 3.49998Z" stroke="#FFF" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="programStructure.seasons?.length" class="mt-[10px] w-full flex justify-center cursor-pointer select-none">
            <svg @click="addMesocycle()" width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="13" cy="13" r="13" fill="#339989"/>
                <line x1="13" y1="5" x2="13" y2="21" stroke="white" stroke-width="2"/>
                <line x1="5" y1="13" x2="21" y2="13" stroke="white" stroke-width="2"/>
            </svg>
        </div>
        <div class="flex w-full justify-start select-none overflow-x-auto">
            <div v-for="meso in selectedMesocycles" class="w-[25%] min-w-[300px] px-[10px]">
                <div class="flex flex-col items-center mt-[10px]">
                    <div class="bg-[#339989] text-white w-[100%] py-[20px] rounded-xl flex flex-col items-center relative">
                        <!-- <div class="font-black">{{ meso.name }}</div> -->

                        <div class="font-black w-full flex justify-center">
                            <div v-if="meso.update">
                                <input v-model="meso.name" class="w-[100%] outline-none bg-transparent text-white text-center" type="text" placeholder="Назва програми">
                            </div>
                            <div class="w-full flex justify-center" v-else>{{ meso.name ? meso.name : 'Введіть назву програми' }}</div>
                        </div>
                        <div class="absolute right-[10px]">
                            <svg v-if="meso.update" @click="meso.update = false" class="cursor-pointer mr-4" width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="13" cy="13" r="13" fill="#339989"/>
                                <line x1="13" y1="5" x2="13" y2="21" stroke="white" stroke-width="2"/>
                                <line x1="5" y1="13" x2="21" y2="13" stroke="white" stroke-width="2"/>
                            </svg>
                            <svg v-else @click="meso.update = true" class="cursor-pointer mr-4" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12 20H21" stroke="#FFF" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M16.5 3.49998C16.8978 3.10216 17.4374 2.87866 18 2.87866C18.2786 2.87866 18.5544 2.93353 18.8118 3.04014C19.0692 3.14674 19.303 3.303 19.5 3.49998C19.697 3.69697 19.8532 3.93082 19.9598 4.18819C20.0665 4.44556 20.1213 4.72141 20.1213 4.99998C20.1213 5.27856 20.0665 5.55441 19.9598 5.81178C19.8532 6.06915 19.697 6.303 19.5 6.49998L7 19L3 20L4 16L16.5 3.49998Z" stroke="#FFF" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </div>
                    </div>
                    <div class="mt-[10px] cursor-pointer">
                        <svg @click="addMicrocycle(meso.id)" width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="13" cy="13" r="13" fill="#1B4079"/>
                            <line x1="13" y1="5" x2="13" y2="21" stroke="white" stroke-width="2"/>
                            <line x1="5" y1="13" x2="21" y2="13" stroke="white" stroke-width="2"/>
                        </svg>
                    </div>
                </div>
                <div class="flex flex-col items-center mt-[10px]">
                    <div v-for="micro in getMicroCyclesByMesocycle(meso.id)" :class="{'border-[1px]' : openMicrocycleObject.id == micro.id}" @click="openMicrocycle(micro)" class="bg-[#1B4079] relative mb-[20px] text-white w-[100%] py-[10px] rounded-xl flex flex-col items-center cursor-pointer">
                        <!-- <div class="font-black">{{ micro.name }}</div> -->

                        <div class="font-black w-full flex justify-center">
                            <div v-if="micro.update">
                                <input v-model="micro.name" class="w-[100%] outline-none bg-transparent text-white text-center" type="text" placeholder="Назва програми">
                            </div>
                            <div class="w-full flex justify-center" v-else>{{ micro.name ? micro.name : 'Введіть назву програми' }}</div>
                        </div>
                        <div class="absolute right-[10px]">
                            <svg v-if="micro.update" @click="micro.update = false" class="cursor-pointer mr-4" width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="13" cy="13" r="13" fill="#339989"/>
                                <line x1="13" y1="5" x2="13" y2="21" stroke="white" stroke-width="2"/>
                                <line x1="5" y1="13" x2="21" y2="13" stroke="white" stroke-width="2"/>
                            </svg>
                            <svg v-else @click="micro.update = true" class="cursor-pointer mr-4" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12 20H21" stroke="#FFF" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M16.5 3.49998C16.8978 3.10216 17.4374 2.87866 18 2.87866C18.2786 2.87866 18.5544 2.93353 18.8118 3.04014C19.0692 3.14674 19.303 3.303 19.5 3.49998C19.697 3.69697 19.8532 3.93082 19.9598 4.18819C20.0665 4.44556 20.1213 4.72141 20.1213 4.99998C20.1213 5.27856 20.0665 5.55441 19.9598 5.81178C19.8532 6.06915 19.697 6.303 19.5 6.49998L7 19L3 20L4 16L16.5 3.49998Z" stroke="#FFF" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <div v-if="openMicrocycleModalFlag && programId" class=" w-[90%] mx-auto mt-3 mb-5 rounded-[10px] overflow-x-auto">
            <div class="mb-[20px] flex justify-end">
                <div @click="openTrainingFormFlag = true" class="bg-[#BD9135] flex justify-center items-center w-[228px] h-[38px] cursor-pointer text-white font-semibold py-[5px] rounded-lg">
                    <span>Додати тренування</span>
                </div>
            </div>
            <table class="w-full">
                <thead class="text-white select-none">
                    <tr class="border-b-[1px] border-[#FFF] border-opacity-10 text-white">
                        <th class="px-3 py-2 w-[5%] text-center font-bold text-sm"></th>
                        <th class="px-3 py-2 w-[20%] text-start font-bold text-sm">Назва тренування</th>
                        <th class="px-3 py-2 w-[25%] text-start font-bold text-sm">МЕЗО</th>
                        <th class="px-3 py-2 w-[25%] text-start font-bold text-sm">МІКРО</th>
                        <th class="px-3 py-2 w-[25%] text-start font-bold text-sm"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-if="currentMicrocycleSummaries.length > 0" v-for="summary in currentMicrocycleSummaries" class="border-b-[1px] border-[#FFF] border-opacity-10 text-white">
                        <td class="px-3 py-2 w-[5%] text-center font-medium text-sm">
                            <div class="rounded-full border-[1px] bg-[#BD9135] border-white w-[50px] h-[50px] flex justify-center items-center text-center align-middle">
                                <span class="text-[18px]">{{ summary.name[0] }}</span>
                            </div>
                        </td>
                        <td class="px-3 py-2 w-[20%] text-start font-bold text-sm">{{ summary.name }}</td>
                        <td class="px-3 py-2 w-[25%] text-start font-bold text-sm">{{ summary.mesocycle_name }}</td>
                        <td class="px-3 py-2 w-[25%] text-start font-bold text-sm">{{ summary.microcycle_name }}</td>
                        <td class="px-3 w-[25%] py-2 text-start font-bold text-sm">
                            <img @click="removeSummary(summary)" class="cursor-pointer" src="@/desktopApp/assets/icons/circle_minus_icon.svg"/>
                        </td>
                    </tr>

                    <tr v-else>
                        <td colspan="5" class="text-center text-white pt-[15px]">Конспекти відсутні</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
    import { ref, onMounted, watch } from 'vue'
    import { useRoute } from 'vue-router'
    import { uuid } from 'vue3-uuid'
    import { getSummariesByMicrocycleId, addSummaryToProgram } from '@/desktopApp/services/apiRequests'
    import AddTrainingForm from './components/AddTrainingForm.vue'

    export default {
        name: 'TrainingProgramView',

        props: {
            program: {
                type: Object,
                default: () => ({})
            }
        },

        setup(props, { emit }) {
            const programStructure = ref({
                name: props.program?.name ? props.program?.name : '',
                description: props.program?.description ? props.program?.description : '',
                age_group: props.program?.age_group ? props.program?.age_group : '',
                mesocycles: props.program?.mesocycles ? props.program?.mesocycles.map(e => ({
                    ...e,
                    update: false
                })) : [],
                microcycles: props.program?.microcycles ? props.program?.microcycles.map(e => ({
                    ...e,
                    update: false
                })) : [],
                seasons: props.program?.seasons ? props.program?.seasons : [],
                groups: props.program?.groups ? props.program?.groups : []
            })

            const updateProgramNameFlag = ref(false) 

            const selectedSeason = ref('')

            const openMicrocycleModalFlag = ref(false)
            const openMicrocycleObject = ref({})

            const currentMicrocycleSummaries = ref([])
            const openTrainingFormFlag = ref(false)

            const route = useRoute()
            const programId = route.params.id

            const getMicroCyclesByMesocycle = (mesocycleId) => {
                return programStructure.value.microcycles.filter(microcycle => microcycle.mesocycle_id === mesocycleId)
            }

            const addMicrocycle = (mesocycleId) => {
                const countMicrocycles = programStructure.value.microcycles.filter(microcycle => microcycle.mesocycle_id === mesocycleId).length
                // if (countMicrocycles < 4) {
                const newMicrocycle = {
                    id: uuid.v4(),
                    name: `Мікроцикл ${countMicrocycles + 1}`,
                    mesocycle_id: mesocycleId
                }
                programStructure.value.microcycles.push(newMicrocycle)
                // }
            }

            const addMesocycle = () => {
                // if (programStructure.value.mesocycles.length < 4) {
                const newMesocycle = {
                    id: uuid.v4(),
                    name: `Мезоцикл ${programStructure.value.mesocycles.length + 1}`,
                    season_id: selectedSeason.value
                }
                programStructure.value.mesocycles.push(newMesocycle)
                updateMesocycles()
                // } 
            }

            const addSeason = () => {
                const newSeason = {
                    id: uuid.v4(),
                    name: `Сезон ${programStructure.value.seasons.length + 1}`
                }
                programStructure.value.seasons.push(newSeason)
                selectedSeason.value = newSeason.id
            }

            const saveProgram = () => {
                emit('saveProgram', programStructure.value)
            }

            const openMicrocycle = async (microcycle) => {
                openMicrocycleObject.value = microcycle
                openMicrocycleModalFlag.value = true

                const result = await getSummariesByMicrocycleId(microcycle.id)
                currentMicrocycleSummaries.value = result
            }

            const selectedMesocycles = ref([])

            const updateMesocycles = () => {
                selectedMesocycles.value = programStructure.value.mesocycles.filter(mesocycle => mesocycle.season_id === selectedSeason.value)
            }

            watch(selectedSeason, async (newSeason) => {
                updateMesocycles()
            })

            const addSummariesToMicrocycle = async (summaries) => {
                for (let i = 0; i < summaries.length; i++) {
                    const summary = summaries[i]

                    const mesocycleObject = programStructure.value.mesocycles.find(mesocycle => mesocycle.id === openMicrocycleObject.value.mesocycle_id)
                    const microcycleObject = programStructure.value.microcycles.find(microcycle => microcycle.id === openMicrocycleObject.value.id)
                    const programObject = {
                        id: props.program.id,
                        name: props.program.name,
                        description: props.program.description,
                        age_group: props.program.age_group,
                        mesocycles: programStructure.value.mesocycles,
                        microcycles: programStructure.value.microcycles,
                        groups: programStructure.value.groups
                    }

                    await addSummaryToProgram(
                        programObject.id, 
                        programObject.name,
                        microcycleObject.id,
                        microcycleObject.name,
                        mesocycleObject.id,
                        mesocycleObject.name, 
                        summary.summary_id)
                }

                const result = await getSummariesByMicrocycleId(openMicrocycleObject.value.id)
                currentMicrocycleSummaries.value = result
                openTrainingFormFlag.value = false
            }

            const removeSummary = async (summary) => {
                await addSummaryToProgram(
                    '', 
                    '',
                    '',
                    '',
                    '',
                    '', 
                    summary.summary_id)
                openTrainingFormFlag.value = false

                const result = await getSummariesByMicrocycleId(openMicrocycleObject.value.id)
                currentMicrocycleSummaries.value = result
            }

            onMounted(() => {
                console.log('TrainingProgramView mounted')
                console.log('programStructure ----> ', programStructure.value)
            })

            return {
                programStructure,
                getMicroCyclesByMesocycle,
                addMicrocycle,
                addMesocycle,
                updateProgramNameFlag,
                openMicrocycleModalFlag,
                openMicrocycleObject,
                openMicrocycle,
                saveProgram,
                currentMicrocycleSummaries,
                openTrainingFormFlag,
                addSummariesToMicrocycle,
                removeSummary,
                programId,
                addSeason,
                selectedSeason,
                selectedMesocycles
            }
        },

        components: {
            AddTrainingForm
        }
    }
</script>

<style lang="scss" scoped>

</style>


