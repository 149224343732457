<template>

<svg width="125" height="125" viewBox="0 0 125 125" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g>
        <rect x="2" y="2" width="125" height="125" stroke="transparent" stroke-width="4" fill="transparent" class="collider"/>

        <path class="color_changed_fill" d="M50.3307 113.02H51.03L50.0977 107.892L49.6315 102.998L49.3984 95.3062L50.3307 64.54L51.9623 42.0481L53.3607 31.5596L55.3419 21.4207L57.09 16.1765L59.4207 11.1654L62.1011 10.1165L65.1311 10L68.2777 10.1165L70.8415 11.1654L73.2888 16.1765L75.0369 21.4207L77.0181 31.5596L78.4165 42.0481L80.0481 64.54L80.9804 95.3062L80.7473 102.998L80.2811 107.892L79.3488 113.02H80.0481V119.779H50.3307V113.02Z" fill="white" stroke="black" stroke-width="0.910275" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M58.6055 22.1203L59.5378 20.8384L59.7709 21.3045V24.5676L59.3047 24.9172L58.8386 24.6841V22.936L58.6055 22.1203Z" stroke="black" stroke-width="0.233077" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M59.4219 22.4687V23.7506L59.0723 23.9837" stroke="black" stroke-width="0.233077"/>
        <path d="M72.0072 22.1203L71.0749 20.8384L70.8418 21.3045V24.5676L71.308 24.9172L71.7741 24.6841V22.936L72.0072 22.1203Z" stroke="black" stroke-width="0.233077" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M71.1914 22.4707V23.7526L71.541 23.9857" stroke="black" stroke-width="0.233077" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M61.9844 29.9282L63.1498 32.492L64.4317 33.4244" stroke="black" stroke-width="0.233077" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M64.5488 31.3261L65.3645 31.7923L66.2968 31.3261" stroke="black" stroke-width="0.233077" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M69.5586 28.7617L67.8105 30.9759" stroke="black" stroke-width="0.233077" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M69.9096 30.2788L67.6953 32.6096" stroke="black" stroke-width="0.233077" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M51.6113 89.3633L53.1263 68.5029L58.3705 70.0179L65.4794 69.5518H73.404L75.9679 68.9691L77.4829 68.5029L79.3475 89.3633L69.0921 93.5587L67.5771 84.4687L65.4794 79.8071L63.3817 84.4687L61.8667 93.5587L51.6113 89.3633Z" fill="black" stroke="black" stroke-width="0.233077" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M59.3041 92.5083L58.1387 94.606L56.3906 95.3052" stroke="black" stroke-width="0.233077" stroke-linecap="round"/>
        <path d="M57.5547 94.8408L57.0886 96.1227L55.457 98.1039" stroke="black" stroke-width="0.233077" stroke-linecap="round"/>
        <path d="M56.3906 97.0547L56.8568 105.212L55.9245 108.825L54.9922 113.021" stroke="black" stroke-width="0.233077" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M51.9629 89.5967L51.6133 93.2093L50.681 97.2882L50.2148 102.066L51.2637 109.991L51.8464 113.021" stroke="black" stroke-width="0.233077" stroke-linecap="round"/>
        <path d="M50.6667 98.6302C50.6364 98.5734 50.5658 98.5519 50.509 98.5822C50.4522 98.6125 50.4307 98.6831 50.461 98.7399L50.6667 98.6302ZM52.7781 101.599L52.6997 101.685L52.7116 101.696L52.7259 101.703L52.7781 101.599ZM54.6427 102.531L54.5906 102.635L54.6121 102.646L54.6362 102.647L54.6427 102.531ZM51.4961 100.433L51.3933 100.488L51.4028 100.506L51.4177 100.519L51.4961 100.433ZM52.7259 101.703L54.5906 102.635L54.6948 102.427L52.8302 101.494L52.7259 101.703ZM54.6362 102.647L56.7339 102.764L56.7468 102.531L54.6491 102.414L54.6362 102.647ZM50.461 98.7399L51.3933 100.488L51.599 100.378L50.6667 98.6302L50.461 98.7399ZM51.4177 100.519L52.6997 101.685L52.8565 101.512L51.5745 100.347L51.4177 100.519Z" fill="black"/>
        <path d="M50.4338 101.078C50.4031 101.021 50.3324 101 50.2758 101.031C50.2193 101.062 50.1983 101.132 50.229 101.189L50.4338 101.078ZM52.9419 104.455L52.865 104.542L52.878 104.554L52.8937 104.561L52.9419 104.455ZM54.8404 105.316L54.7922 105.422L54.8085 105.429L54.8263 105.431L54.8404 105.316ZM51.3993 103.102L51.2969 103.157L51.3068 103.175L51.3225 103.189L51.3993 103.102ZM52.8937 104.561L54.7922 105.422L54.8885 105.21L52.99 104.349L52.8937 104.561ZM54.8263 105.431L56.8434 105.677L56.8717 105.446L54.8545 105.2L54.8263 105.431ZM50.229 101.189L51.2969 103.157L51.5017 103.046L50.4338 101.078L50.229 101.189ZM51.3225 103.189L52.865 104.542L53.0187 104.367L51.4762 103.014L51.3225 103.189Z" fill="black"/>
        <path d="M51.9616 93.2075L52.0781 95.3052L51.7285 96.4706V97.9856" stroke="black" stroke-width="0.233077" stroke-linecap="round"/>
        <path d="M52.5449 96.2378C52.7314 96.331 52.9334 96.5097 53.0111 96.5874" stroke="black" stroke-width="0.233077" stroke-linecap="round"/>
        <path d="M55.459 96.1225L54.4102 97.0548" stroke="black" stroke-width="0.233077" stroke-linecap="round"/>
        <path d="M52.1953 96.5874L53.2442 97.1701" stroke="black" stroke-width="0.233077" stroke-linecap="round"/>
        <path d="M71.4238 92.6255L72.5892 94.6067L74.3373 95.3058" stroke="black" stroke-width="0.233077" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M73.1719 94.8408L73.6381 96.1227L75.2696 97.9873" stroke="black" stroke-width="0.233077" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M74.3373 96.9365L73.8711 105.211L74.8034 108.823L75.7357 113.019" stroke="black" stroke-width="0.233077" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M78.8828 89.5967L79.1159 93.2093L80.0482 97.2882L80.5143 102.066L79.4655 109.991L78.8828 113.021" stroke="black" stroke-width="0.233077" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M80.1656 99.269L79.2332 100.434L77.9513 101.6L76.0867 102.532L74.1055 102.649" stroke="black" stroke-width="0.233077" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M80.3959 101.367L79.347 103.348L77.832 104.63L75.9674 105.446L73.9863 105.679" stroke="black" stroke-width="0.233077" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M78.7669 93.208L78.6504 95.3057L79 96.4711V97.9861" stroke="black" stroke-width="0.233077" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M78.183 96.2387C77.9965 96.332 77.7945 96.5107 77.7168 96.5884" stroke="black" stroke-width="0.233077" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M75.2695 96.1225L76.3184 97.0548" stroke="black" stroke-width="0.233077" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M78.5332 96.5874L77.4844 97.1701" stroke="black" stroke-width="0.233077" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M54.5254 68.9682L52.7773 89.8286" stroke="black" stroke-width="0.233077" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M75.9688 68.9682L77.7168 89.9452" stroke="black" stroke-width="0.233077" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M53.127 68.5019L53.7097 65.705L54.8751 49.5061L54.2924 42.6303V49.1565L54.1759 50.6715L53.4766 51.8369L52.8939 54.1677L51.7285 56.615V59.878L50.3301 65.2388" stroke="black" stroke-width="0.233077" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M51.9629 42.6305C52.5844 40.5328 53.9441 36.3141 53.9441 36.2209C53.9441 36.1277 56.3525 34.5505 57.5567 33.7736L60.8198 31.7925" stroke="black" stroke-width="0.233077" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M77.4844 68.5019L76.9017 65.705L75.7363 49.5061L76.319 42.6303V49.1565L76.4356 50.6715L77.1348 51.8369L77.7175 54.1677L78.8829 56.615V59.878L80.0483 65.2388" stroke="black" stroke-width="0.233077" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M78.4168 42.3975C77.7953 40.2998 76.6687 36.3141 76.6687 36.2209C76.6687 36.1277 74.2603 34.5505 73.056 33.7736L69.793 31.7925" stroke="black" stroke-width="0.233077" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M65.4678 49.1427L54.8262 48.2324V49.5978L54.3711 57.3351L65.2944 58.7006L76.2177 57.3351L75.7625 49.1427V48.2324L65.4678 49.1427Z" fill="black" stroke="black" stroke-width="0.233077" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M75.6193 50.999L65.4805 51.6982" stroke="black" stroke-width="0.233077" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M65.3654 35.7551L70.7261 32.3755L71.8915 33.0747L65.3654 37.3866L58.7227 33.0747L59.8881 32.3755L65.3654 35.7551Z" fill="black" stroke="black" stroke-width="0.233077" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M59.7715 20.2549V26.8976L63.5007 29.9276H67.3465L70.8427 26.8976V20.2549" stroke="black" stroke-width="0.233077" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M60.4701 18.8562L60.0039 21.5366L59.0716 19.4389L58.6055 17.9239L60.0039 15.7097L60.4701 16.9916L61.6355 17.9239L60.4701 18.8562Z" fill="black"/>
        <path d="M65.9473 17.2247C65.6676 17.2247 63.4223 17.4578 62.3346 17.5743L61.0527 16.5255L60.7031 15.127L61.2858 13.9616L63.6165 13.0293L63.0338 13.7285L65.5977 12.6797L65.2481 13.3789L66.8796 13.0293L67.5788 13.7285L68.3946 13.0293L69.0938 13.7285L70.7254 15.127L72.1238 17.2247C72.2015 17.9628 72.3802 19.5088 72.4735 19.7885C72.5667 20.0682 72.2792 21.0704 72.1238 21.5366L71.5412 21.0704L70.9585 21.5366L70.7254 19.2058L70.1427 18.2735C68.8608 17.9239 66.227 17.2247 65.9473 17.2247Z" fill="black"/>
        <path d="M60.4701 18.8562L60.0039 21.5366L59.0716 19.4389L58.6055 17.9239L60.0039 15.7097L60.4701 16.9916L61.6355 17.9239L60.4701 18.8562Z" stroke="black" stroke-width="0.233077" stroke-linejoin="round"/>
        <path d="M65.9473 17.2247C65.6676 17.2247 63.4223 17.4578 62.3346 17.5743L61.0527 16.5255L60.7031 15.127L61.2858 13.9616L63.6165 13.0293L63.0338 13.7285L65.5977 12.6797L65.2481 13.3789L66.8796 13.0293L67.5788 13.7285L68.3946 13.0293L69.0938 13.7285L70.7254 15.127L72.1238 17.2247C72.2015 17.9628 72.3802 19.5088 72.4735 19.7885C72.5667 20.0682 72.2792 21.0704 72.1238 21.5366L71.5412 21.0704L70.9585 21.5366L70.7254 19.2058L70.1427 18.2735C68.8608 17.9239 66.227 17.2247 65.9473 17.2247Z" stroke="black" stroke-width="0.233077" stroke-linejoin="round"/>
        <path d="M64.0808 20.838C63.9875 20.838 63.1096 20.6826 62.6823 20.605L61.6335 20.7215L60.7012 21.3042" stroke="black" stroke-width="0.233077" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M62.3334 21.3042C61.8672 21.3042 61.3622 21.7703 61.168 22.0034L61.7507 22.2365L62.4499 22.353L63.1491 22.0034L63.4988 21.5373C63.3045 21.4596 62.7995 21.3042 62.3334 21.3042Z" stroke="black" stroke-width="0.233077" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M61.9844 21.6539L62.2175 21.4209H62.6836V21.6539V21.887L62.4506 22.0036H62.2175L61.9844 21.887V21.6539Z" stroke="black" stroke-width="0.233077"/>
        <path d="M66.4121 20.838C66.5053 20.838 67.3832 20.6826 67.8105 20.605L68.8594 20.7215L69.7917 21.3042" stroke="black" stroke-width="0.233077" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M68.1615 21.3042C68.6276 21.3042 69.1326 21.7703 69.3269 22.0034L68.7442 22.2365L68.0449 22.353L67.3457 22.0034L66.9961 21.5373C67.1903 21.4596 67.6953 21.3042 68.1615 21.3042Z" stroke="black" stroke-width="0.233077" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M68.5098 21.6539L68.2767 21.4209H67.8105V21.6539V21.887L68.0436 22.0036H68.2767L68.5098 21.887V21.6539Z" stroke="black" stroke-width="0.233077"/>
        <path d="M67.3438 27.0148H63.1484L64.8965 26.7817L65.2461 26.8983L65.7123 26.7817L67.3438 27.0148Z" fill="black" stroke="black" stroke-width="0.233077" stroke-linejoin="round"/>
        <path d="M66.8783 27.4804H63.6152L65.2468 27.7135L66.8783 27.4804Z" stroke="black" stroke-width="0.233077" stroke-linejoin="round"/>
        <path d="M65.9473 21.8877V25.1507" stroke="black" stroke-width="0.233077" stroke-linecap="round"/>
        <path d="M64.4316 22.8193V25.1501" stroke="black" stroke-width="0.233077" stroke-linecap="round"/>
        <path d="M63.849 24.334L63.3828 24.9167C63.4216 25.0332 63.5226 25.2663 63.6159 25.2663C63.7091 25.2663 64.1986 25.2663 64.4316 25.2663L64.8978 25.3828H65.2474H65.4805L65.9466 25.2663H66.879L66.9955 24.9167L66.6459 24.567" stroke="black" stroke-width="0.233077" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M49.6315 89.4785L48 90.7604V95.655L49.3985 96.8204" stroke="black" stroke-width="0.233077" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M80.8633 89.4785L82.4948 90.7604V95.655L80.9798 97.0535" stroke="black" stroke-width="0.233077" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M60.8183 31.7924L60.4688 27.4804" stroke="black" stroke-width="0.233077" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M69.793 31.7924L70.1426 27.4804" stroke="black" stroke-width="0.233077" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M51.0293 113.021H79.3482" stroke="black" stroke-width="0.910275" stroke-linecap="round"/>
        <path fill-rule="evenodd" clip-rule="evenodd" class="color_changed_fill" d="M64.1009 50.0565H64.9327V51.4102L65.9771 50.0522H66.8732L64.9327 52.5278V53.5146L67.7077 50.0544H68.5866L66.2833 53.0708L68.9355 56.9872L68.0386 56.9853L65.7396 53.6263L65.323 54.1351L67.1963 56.9872L66.2201 56.9853L64.9327 55.0231V56.9843H64.1009V53.7663L64.0283 53.6456L63.4141 54.5442V56.9843H62.5566V50.0522H63.3927V53.4096L64.1009 52.5033V50.0565Z" fill="white"/>
        <path fill-rule="evenodd" clip-rule="evenodd" class="color_changed_fill" d="M61.6719 50.122H62.3725V50.2495H61.8877V50.4133H62.2727V50.5408H61.8877V50.7867H61.6719V50.122Z" fill="white"/>
        <path fill-rule="evenodd" clip-rule="evenodd" class="color_changed_fill" d="M61.6621 52.3974H61.8715V52.7001L62.174 52.3974H62.3792L62.0725 52.6903L62.3834 53.0578H62.1654L61.9474 52.7837L61.8715 52.8517V53.0578H61.6621V52.3974Z" fill="white"/>
        <path fill-rule="evenodd" clip-rule="evenodd" class="color_changed_fill" d="M61.8487 53.3183H62.1962C62.3164 53.3183 62.3906 53.3728 62.3906 53.4943V53.8325C62.3906 53.916 62.3364 53.998 62.1962 53.998H61.8487C61.7608 53.998 61.6543 53.959 61.6543 53.8277V53.496C61.6543 53.39 61.7076 53.3183 61.8487 53.3183ZM61.8509 53.5014V53.8166C61.8509 53.8532 61.8727 53.8748 61.9175 53.8748H62.1351C62.1779 53.8748 62.1962 53.8502 62.1962 53.8112V53.5038C62.1962 53.4637 62.17 53.4415 62.1346 53.4415H61.9124C61.875 53.4415 61.8509 53.4663 61.8509 53.5014Z" fill="white"/>
        <path fill-rule="evenodd" clip-rule="evenodd" class="color_changed_fill" d="M61.8487 55.2461H62.1962C62.3164 55.2461 62.3906 55.3006 62.3906 55.4221V55.7602C62.3906 55.8437 62.3364 55.9257 62.1962 55.9257H61.8487C61.7608 55.9257 61.6543 55.8867 61.6543 55.7555V55.4238C61.6543 55.3177 61.7076 55.2461 61.8487 55.2461ZM61.8509 55.4292V55.7443C61.8509 55.781 61.8727 55.8025 61.9175 55.8025H62.1351C62.1779 55.8025 62.1962 55.7779 62.1962 55.7389V55.4315C62.1962 55.3915 62.17 55.3692 62.1346 55.3692H61.9124C61.875 55.3692 61.8509 55.394 61.8509 55.4292Z" fill="white"/>
        <path fill-rule="evenodd" clip-rule="evenodd" class="color_changed_fill" d="M61.7109 54.9555H62.3258V54.8324H61.9096V54.2866H61.7109V54.9555Z" fill="white"/>
        <path fill-rule="evenodd" clip-rule="evenodd" class="color_changed_fill" d="M61.8776 50.9658H62.373V51.089H61.9732C61.9064 51.089 61.8883 51.1103 61.8883 51.1646V51.443C61.8883 51.5131 61.9246 51.5168 61.9713 51.5158C62.1059 51.513 62.2405 51.508 62.3752 51.5009V51.6208C62.2255 51.6264 62.0751 51.6343 61.9261 51.6376C61.6993 51.6425 61.666 51.5542 61.666 51.458V51.1431C61.666 51.0598 61.7175 50.9658 61.8776 50.9658Z" fill="white"/>
        <path fill-rule="evenodd" clip-rule="evenodd" class="color_changed_fill" d="M61.8755 56.2158H62.3024V56.3411H61.9582C61.8917 56.3411 61.8734 56.3624 61.8733 56.4165C61.8733 56.4759 61.903 56.4879 61.9582 56.4879H62.2019C62.2681 56.4879 62.3666 56.5205 62.3666 56.6545V56.693C62.3666 56.7892 62.3591 56.8946 62.1322 56.8897C61.9832 56.8865 61.8415 56.8785 61.6918 56.8729V56.753C61.8264 56.7601 61.9525 56.7652 62.0871 56.768C62.1337 56.769 62.1729 56.7652 62.1722 56.6951L62.1714 56.6196H61.8755C61.7304 56.6196 61.6851 56.5389 61.6779 56.4618C61.675 56.4317 61.6749 56.3958 61.6787 56.3656C61.6883 56.291 61.7355 56.2158 61.8755 56.2158Z" fill="white"/>
    </g>

</svg>



</template>

<script>
    export default {
        name: 'BaloonPlayer',
        props: {
        }
    }
</script>

<style lang="scss" scoped>
    @font-face {
        font-family: 'Avenir';
        src: url('@/desktopApp/assets/fonts/AvenirLTStd-Black.otf');
    }
</style>