<template>
    <div id="select__schemas" class="flex absolute top-[50%] translate-y-[-50%] left-[50%] translate-x-[-50%] bg-[#1E1E1E] flex-col w-[340px] mr-[20px] py-[10px] border-[1px] px-[20px] border-[#363636] rounded-md">
        <div class="text-center text-[21px] text-white border-white border-b-[1px] pb-[5px]">
            Каталог схем
        </div>

        <div class="border-[1px] mt-[15px] border-[#FFF] text-[#BD9135] truncate px-[5px] py-[5px] flex items-center rounded-md">
            <input class="bg-transparent text-white outline-none" placeholder="Введіть назву схеми" />
        </div>
        <div class="flex text-[#BD9135] justify-center mt-[10px] font-bold">
            <div @click="prevPage" class="cursor-pointer">&lt;</div>
            <div class="w-[100px] text-center">{{ page }} / 10</div>
            <div @click="nextPage" class="cursor-pointer">></div>
        </div>

        <div class="mt-[30px] h-[45vh] overflow-y-auto">
            <div v-for="schema in schemas" @click="$emit('selectSchema', schema)" class="border-[1px] border-[#BD9135] text-[#BD9135] mb-[10px] truncate px-[5px] py-[2px] rounded-md hover:bg-[#BD9135] hover:text-black cursor-pointer">
                {{ schema.name ? schema.name : 'Не задано' }}
            </div>
            
        </div>

        <div @click="$emit('return')" class="border-[1px] font-bold text-center cursor-pointer hover:opacity-95 border-[#BD9135] bg-[#BD9135] text-black mb-[10px] truncate px-[5px] py-[2px] rounded-md">
            Назад
        </div>
    </div>
</template>

<script>
    import { ref, onMounted, watch } from 'vue'
    import { getAllTrainingSchemasRequest } from '@/desktopApp/services/apiRequests'

    export default {
        name: 'SchemasSelect',

        setup(props, { emit }) {
            const schemas = ref([])
            const page = ref(1)

            const nextPage = () => {
                page.value++
            }

            const prevPage = () => {
                if (page.value > 1) {
                    page.value--
                }
            }

            watch(page, async () => {
                schemas.value = await getAllTrainingSchemasRequest(20, (page.value - 1) * 20)
            })

            onMounted(async () => {
                schemas.value = await getAllTrainingSchemasRequest(20, (page.value - 1) * 20)
                console.log('schemas.value --------- ', schemas.value)
            })

            return {
                schemas,
                page,
                nextPage,
                prevPage
            }
        }
    }
</script>

<style lang="scss" scoped>

    ::-webkit-scrollbar {
        width: 5px;               /* ширина всей полосы прокрутки */
    }

    ::-webkit-scrollbar-track {
        background: transparent;        /* цвет зоны отслеживания */
    }

    ::-webkit-scrollbar-thumb {
        background-color: #696969;    /* цвет бегунка */
        border-radius: 20px;       /* округлось бегунка */ /* отступ вокруг бегунка */
    }
</style>

